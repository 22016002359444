
const { Api, JsonRpc } = require('eosjs');
const config = require('../constants/config');
// const { JsSignatureProvider } = require('eosjs/dist/eosjs-jssig');
// const fetch = require(`node-fetch`) // node only; not needed in browsers
// const { TextEncoder, TextDecoder } = require(`util`) // node only; native TextEncoder/Decoder

// TODO: .env
// const signatureProvider = new JsSignatureProvider([
//     `5JWra7pyWF7sbpgDTwkCUW5XDAvJSerRPsMbXP1yNUfGc2kCHJC`,
//     `5JWra7pyWF7sbpgDTwkCUW5XDAvJSerRPsMbXP1yNUfGc2kCHJC`,
// ])
// TODO: .env
// const rpc = new JsonRpc(`http://127.0.0.1:8888`, { fetch })
const rpc = new JsonRpc(config.rpc.host);
const api = new Api({
    rpc,
    // signatureProvider,
    // textDecoder: new TextDecoder(),
    // textEncoder: new TextEncoder(),
})

const accounts = process.env.ACCOUNTS ? process.env.ACCOUNTS.split('|'): []
const authors = process.env.AUTHORS ? process.env.AUTHORS.split('|') : []
const settings = {
    asset_c: process.env.ASSETS_CONTRACT || 'simpleassets',
    vault_c: process.env.VAULT_CONTRACT || 'waxinventory',
}


module.exports = {
    api,
    rpc,
    accounts,
    authors,
    settings,
}