import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import * as wallet from '../../../util/wallet';
import { history } from '../../../store';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollUpButton from 'react-scroll-up-button';

import {
    loadUserTransactions,
} from '../../../actions/Inventory.js';

// TODO: GET THIS DONE !
class Transactions extends React.Component
{
    constructor(props) {
        super(props);

        this.loadMore = this.loadMore.bind(this);
        this.getHeaders = this.getHeaders.bind(this);
    }
    componentDidMount() {
        if (this.props.auth_status || this.props.autologin) {
            this.props.loadUserTransactions();
        }
    }

    redirectToBloks(transaction) {
        //TODO: make redirect to bloks with transaction id !
    }

    loadMore(page) {
        this.props.loadUserTransactions({more: true});
    }

    redirectToItemPage(asset) {
        if (asset && asset.type == 'NFT') {
            history.push(`/nft/${asset.assetid}`);
        } else if (asset && asset.type == 'FT') {
            history.push(`/ft/${asset.assetid}`);
        }
    }

    getHeaders = () => {
        return (
            <Helmet>
                <title>My Transactions</title>
                <meta name="description" content="Check your trade history on WAX inventory - WAX Marketplace."/>
            </Helmet>
        );
    }

    render() {
        const { transactions, transactions_more } = this.props;
        if (!this.props.auth_status && !this.props.autologin || !this.props.auth_result) {
            return (
                <div className="page-placeholder">
                    {this.getHeaders()}
                    Please <Link to="/login">Log In</Link>
                </div>
            );
        }
        if (!this.props.auth_status && this.props.loader) {
            return (
                <div key={1} className="loader-view loader-margin" style={{clear: 'both'}}>
                    <CircularProgress/>
                </div>
            );
        }

        return (
            <div className="transactions">
                {this.getHeaders()}
                { transactions && transactions.length > 0 &&
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadMore}
                        hasMore={this.props.transactions_more}
                        loader={
                            <div key={1} className="loader-view" style={{clear: 'both'}}>
                                <CircularProgress/>
                            </div>
                        }
                        useWindow={true}
                    >
                        {transactions.map((ele, idx) => {
                            const { asset, ...transaction } = ele;
                            if (!asset.mdata) {
                                asset.mdata = {};
                            }
                            if (!asset.idata) {
                                asset.idata = {};
                            }
                            asset.mdata = typeof asset.mdata != 'object' ? JSON.parse(asset.mdata) : asset.mdata
                            asset.idata = typeof asset.idata != 'object' ? JSON.parse(asset.idata) : asset.idata
                            let image = asset.img || asset.idata.img || asset.mdata.img;
                            if (image.length === 46 && image.substr(0,1) === 'Q') {
                                image = 'https://ipfs.io/ipfs/' + image;
                            } else if (image && -1 === image.indexOf('http') && image.substr(0,1) === 'Q') {
                                image = 'https://ipfs.io/ipfs/' + image;
                            }
                            return (
                                <div key={idx} className="item_transfer">
                                    <div className="item_left pointer">
                                        <div
                                            className="item_background"
                                            onClick={() => this.redirectToItemPage(asset)}
                                        >
                                            <div className="item_image" style={{backgroundImage: `url(${image})`}} />
                                        </div>
                                        <div className="item_desc">
                                            <h2>
                                                {asset.name || asset.idata.name || asset.mdata.name || 'missing name'}
                                            </h2>
                                            <h4><span>Author:</span>&nbsp;<a>{asset.author}</a></h4>
                                            <h4>
                                                {moment(transaction.created_at).utc().format('DD-MM-YYYY h:mm a')}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="item_right">
                                        <div className="item_price">
                                            <h3
                                                className={transaction.from == this.props.account_name ? 'red_price'  : 'green_price'}
                                            >
                                                {wallet.formatPrice(transaction.quantity)}&nbsp;WAX&nbsp;($&nbsp;{wallet.formatUsdPrice(wallet.formatPrice(transaction.quantity), this.props.usdticker)})
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </InfiniteScroll>
                }
                <ScrollUpButton />
                { (!transactions || transactions.length == 0) &&
                    <div className="page-placeholder">No transaction history.</div>
                }
            </div>
        )
    }
}


const mapStateToProps = ({ auth, inventory, market }) => {
    const { transactions, transactions_more } = inventory;
    const { usdticker } = market;
    const { user, auth_status, autologin, loader, auth_result } = auth;
    return {
        transactions,
        transactions_more,
        account_name: user ? user.account_name : 'bob',
        usdticker,
        auth_status,
        autologin,
        loader,
        auth_result,
    }
};

export default connect(mapStateToProps, {
    loadUserTransactions,
})(Transactions);
