import {all, call, select, fork, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {
    LOAD_USER,
    SIGNOUT_USER,
    SIGNIN_TRANSIT,
    INIT_ACCESS_CONTEXT,
    SIGNIN_WAX,
    LOAD_USER_TICKER,
} from 'constants/ActionTypes';
import {
    showAuthMessage,
    initAccessContext,
    saveAuthData,
    userSignOutSuccess,
    saveEosio,
    enableAuthStatus,
    loadUserTransfers,
    changeLoaderStatus,
    setLoginResult,
    setUserTickerRunning,
} from 'actions';
import { history } from '../store';
import * as config from '../constants/config';
import { toast } from 'react-toastify';
import * as wallet from '../util/wallet';

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function* authorize({payload}) {
    yield put(changeLoaderStatus(true));
    try {
        if (payload && payload.user && payload.eosio && (payload.eosio.api || payload.eosio.eosApi)) {
            return payload;
        }
        let auth = yield select( state => state.auth );
        if (auth && auth.user && auth.eosio && (auth.eosio.api || auth.eosio.eosApi)) {
            return auth;
        }
        auth = yield call(wallet.getAuth, auth ? auth.user : null , auth ? auth.eosio : null, payload);

        if (auth && auth.user){
            localStorage.setItem('user', JSON.stringify(auth.user));
            yield put(saveAuthData(auth.user));
            yield put(saveEosio(auth.eosio));
            yield put(loadUserTransfers(auth));
            yield put(enableAuthStatus())
            yield put(setLoginResult(true));
        }
        return auth;
    } catch(e) {
        if (config.debug){
            console.error('authorize', e);
        }
        yield call(toast.error, e.message || String(e));
        yield put(setLoginResult(false));
    }
    yield put(changeLoaderStatus(false));
}

function* sinInUserWithWax({payload}) {
    // yield put(changeLoaderStatus(true));
    try {
        const auth = yield call(authorize, {payload: 'wax'});
    } catch (e) {
        if (config.debug){
            console.error('sign in with wax', e);
        }
        yield call(toast.error, e.message);
    }
    // yield put(changeLoaderStatus(false));
}

function* sinInUserWithTransit({payload}) {
    // yield put(changeLoaderStatus(true));
    try {
        const providerIndex  = payload;
        const auth = yield call(authorize, {payload: providerIndex});
        if (auth) {
            yield call(toast.success, `Welcome ${auth.user.account_name}`);
        }
    } catch (e) {
        if (config.debug){
            console.error('sign in with transit', e);
        }
        yield call(toast.error, e.message);
    }
    // yield put(changeLoaderStatus(false));
}

function* signOut() {
    try {
        const {user, eosio} = yield select(state => state.auth)
        yield localStorage.removeItem('user_id');
        yield localStorage.removeItem('user');
        yield localStorage.removeItem('autologin');
        yield localStorage.removeItem('method');

        yield put(userSignOutSuccess());
        yield call(history.push, '/');
        yield call(toast.success, 'Logged out!');

        if (user && user.method == 'transit' && eosio.terminate) {
            yield call(eosio.terminate);
        } else {
            // console.log('WHAT THE ACTUAL FUCK', user, eosio);
        }
    } catch (e) {
        if (config.debug){
            console.error('sign out', e);
        }
        yield call(toast.error, e.message);
    }
}

function* getUser() {
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            return;
        }
        if (auth && auth.user){
            const user = yield call(wallet.getAccount, auth);
            // console.log('USER AFTER REFRESH', user);
            // localStorage.setItem('user', JSON.stringify(auth.user));
            yield put(saveAuthData(user));
            yield put(saveEosio(auth.eosio));
        }
    } catch (e) {
        if (config.debug){
            console.error('get user', e);
        }
        yield call(toast.error, e.message);
    }
}

function* refreshUser() {
    try {
        let auth = yield select( state => state.auth );
        if (!auth) {
            return;
        }
        if (auth && auth.user) {
            const user = yield call(wallet.getAccount, auth);
            localStorage.setItem('user', JSON.stringify(user));
            yield put(saveAuthData(user));
        }
    } catch (e) {
        if (config.debug){
            console.error('get user', e);
        }
        yield call(toast.error, e.message);
    }
}

function* loadUserTicker() {
    const user_ticker_running = yield select(state => state.auth.user_ticker_running);
    if (user_ticker_running) {
        return;
    }
    yield put(setUserTickerRunning());
    while(true) {
        yield refreshUser();
        yield call(sleep, 20000);
    }
}

export function* signInWithTransit() {
    yield takeLatest(SIGNIN_TRANSIT, sinInUserWithTransit)
}

export function* signInWaxUser() {
    yield takeLatest(SIGNIN_WAX, sinInUserWithWax);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* loadUser() {
    yield takeEvery(LOAD_USER, getUser);
}

export function* loadUserTickerSaga() {
    yield takeEvery(LOAD_USER_TICKER, loadUserTicker);
}


export default function* rootSaga() {
    yield all([
        fork(signInWithTransit),
        fork(signInWaxUser),
        fork(signOutUser),
        fork(loadUser),
        fork(loadUserTickerSaga),
    ]);
}