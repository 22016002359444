import {
    SHOW_MODAL,
    HIDE_MODAL,
    SET_ITEM_MODAL,
    SET_ITEM_ACTION,
    SET_ITEM_DATA,

    SHOW_FILTER_MODAL,
    HIDE_FILTER_MODAL,
} from 'constants/ActionTypes';

const INIT_STATE = {
    isModalOpen: false,
    data: {},
    isFilterModalOpen: false,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // ITEM MODAL
        case SHOW_MODAL: {
            return {
                ...state,
                isModalOpen: true,
                item: action.payload.item,
                action: action.payload.action,
            }
        }
        case HIDE_MODAL: {
            return {
                ...state,
                isModalOpen: false,
            }
        }
        case SET_ITEM_MODAL: {
            return {
                ...state,
                item: action.payload,
            }
        }
        case SET_ITEM_ACTION: {
            return {
                ...state,
                action: action.payload,
            }
        }
        case SET_ITEM_DATA: {
            return {
                ...state,
                data: action.payload,
            }
        }

        // FILTERS
        case SHOW_FILTER_MODAL: {
            return {
                ...state,
                isFilterModalOpen: true,
            }
        }
        case HIDE_FILTER_MODAL: {
            return {
                ...state,
                isFilterModalOpen: false,
            }
        }

        default:
            return state;
    }
}
