
const local = {
    inv_c: process.env.REACT_APP_INVENTORY_CONTRACT,
    asset_c: process.env.REACT_APP_SIMPLEASSETS_CONTRACT,
    offer_t: 'soffer',
    transfer_t: 'offers',
    asset_t: 'sassets',
    auction_t: 'sauction',
    rpc: {
        host: process.env.REACT_APP_RPC_HOST || 'http://127.0.0.1:8888',
        wax: process.env.REACT_APP_WAX_HOST || 'https://wax.greymass.com',
    },
    api: {
        host: process.env.REACT_APP_API_HOST || 'http://localhost:3045'
        // host: process.env.REACT_APP_API_HOST || 'https://dev.waxinventory.com'
    },
    bloks: {
        host: process.env.REACT_APP_BLOKS_HOST || 'https://wax-test.bloks.io'
    },
    cloudWalletEnabled: true,
    scatter: {
        appName: 'waxinventory',
        protocol: 'http',
        host: 'localhost',
        port: 8888,
        chainId: 'cf057bbfb72640471fd910bcb67639c22df9f92470936cddc1ade0e2f2e7dc4f',
    },
    debug: true,
};

const tmp = {
    inv_c: process.env.REACT_APP_INVENTORY_CONTRACT,
    asset_c: process.env.REACT_APP_SIMPLEASSETS_CONTRACT,
    offer_t: 'soffer',
    transfer_t: 'offers',
    asset_t: 'sassets',
    auction_t: 'sauction',
    rpc: {
        host: process.env.REACT_APP_RPC_HOST || 'https://testnet.waxsweden.org',
        wax: process.env.REACT_APP_WAX_HOST || 'https://wax.greymass.com',
    },
    api: {
        host: process.env.REACT_APP_API_HOST || 'http://localhost:3045'
        // host: process.env.REACT_APP_API_HOST || 'https://dev.waxinventory.com'
    },
    bloks: {
        host: process.env.REACT_APP_BLOKS_HOST || 'https://wax-test.bloks.io'
    },
    cloudWalletEnabled: false,
    scatter: {
        appName: 'waxinventory',
        protocol: 'https',
        host: 'testnet.waxsweden.org',
        port: 443,
        chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
    },
    debug: true,
};

const tmp_prod = {
    inv_c: process.env.REACT_APP_INVENTORY_CONTRACT,
    asset_c: process.env.REACT_APP_SIMPLEASSETS_CONTRACT,
    offer_t: 'soffer',
    transfer_t: 'offers',
    asset_t: 'sassets',
    auction_t: 'sauction',
    rpc: {
        host: process.env.REACT_APP_RPC_HOST || 'https://chain.wax.io',
        wax: process.env.REACT_APP_WAX_HOST || 'https://wax.greymass.com',
    },
    bloks: {
        host: process.env.REACT_APP_BLOKS_HOST || 'https://wax.bloks.io'
    },
    api: {
        host: process.env.REACT_APP_API_HOST || 'http://localhost:3045'
    },
    cloudWalletEnabled: true,
    scatter: {
        appName: 'waxinventory',
        protocol: 'https',
        host: 'chain.wax.io',
        port: 443,
        chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    },
    debug: true,
};

const dev = {
    inv_c: process.env.REACT_APP_INVENTORY_CONTRACT,
    asset_c: process.env.REACT_APP_SIMPLEASSETS_CONTRACT,
    offer_t: 'soffer',
    transfer_t: 'offers',
    asset_t: 'sassets',
    auction_t: 'sauction',
    rpc: {
        host: process.env.REACT_APP_RPC_HOST || 'https://testnet.waxsweden.org',
        wax: process.env.REACT_APP_WAX_HOST || 'https://wax.greymass.com',
    },
    bloks: {
        host: process.env.REACT_APP_BLOKS_HOST || 'https://wax-test.bloks.io'
    },
    api: {
        host: process.env.REACT_APP_API_HOST || 'https://dev.waxinventory.com'
    },
    cloudWalletEnabled: false,
    scatter: {
        appName: 'waxinventory',
        protocol: 'https',
        host: 'testnet.waxsweden.org',
        port: 443,
        chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
    },
    // debug: false,
    debug: true,
};

const production = {
    inv_c: process.env.REACT_APP_INVENTORY_CONTRACT,
    asset_c: process.env.REACT_APP_SIMPLEASSETS_CONTRACT,
    offer_t: 'soffer',
    transfer_t: 'offers',
    asset_t: 'sassets',
    auction_t: 'sauction',
    rpc: {
        host: process.env.REACT_APP_RPC_HOST || 'https://chain.wax.io',
        wax: process.env.REACT_APP_WAX_HOST || 'https://api.waxsweden.org',
        // wax: process.env.REACT_APP_WAX_HOST || 'https://wax.greymass.com',
    },
    bloks: {
        host: process.env.REACT_APP_BLOKS_HOST || 'https://wax.bloks.io'
    },
    api: {
        host: process.env.REACT_APP_API_HOST || 'https://waxinventory.com'
    },
    cloudWalletEnabled: true,
    scatter: {
        appName: 'waxinventory',
        protocol: 'https',
        host: 'chain.wax.io',
        port: 443,
        chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    },
    debug: false,
};

let config = local;
if (process.env.REACT_APP_STAGE === 'production') {
    config = production
}
if (process.env.REACT_APP_STAGE === 'dev') {
    config = dev
}
if (process.env.REACT_APP_STAGE === 'tmp') {
    config = tmp
}
// config = tmp_prod
// let config = production;
// config = production;
// config = local

module.exports = config;