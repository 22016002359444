
import { initAccessContext } from 'eos-transit';
import scatter from 'eos-transit-scatter-provider';
import ledger from 'eos-transit-ledger-provider';
import simpleos from 'eos-transit-simpleos-provider';
import * as config from '../constants/config';

const accessContext = initAccessContext({
  appName: config.scatter.appName,
  network: {
    host: config.scatter.host,
    port: config.scatter.port,
    protocol: config.scatter.protocol,
    chainId: config.scatter.chainId,
  },
  walletProviders: [
    scatter(),
    // ledger(),
    simpleos(),
  ]
});

Object.freeze(accessContext);
export default accessContext;

