import React from 'react'
import { connect } from 'react-redux'
import * as wallet from '../../util/wallet';
// import ReactSpeedometer from 'react-d3-speedometer';
// import {
//   // showModal,
//   // hideModal,
// } from '../../actions/Modal';
import ItemButtons from './buttons'; //TODo: ofc
import {history} from '../../store';
import * as moment from 'moment';
import {Decimal} from 'decimal.js';
import * as config from '../../constants/config';
import ItemVisualProps from 'components/ItemVisualProps';
import {
  selectInventoryItem,
} from 'actions';

const bloks = config.bloks.host;

class Item extends React.Component {

  constructor(props) {
    super(props);

    this.redirectToAsset = this.redirectToAsset.bind(this);
    this.itemSelectEvent = this.itemSelectEvent.bind(this);
  }

  redirectToAsset(asset) {
    history.push(`/nft/${asset.assetid || asset.id}`);
  }

  itemSelectEvent(event) {
    let target = event.target;

    this.props.selectInventoryItem({id: target.name, value: target.checked});
  }

  correctName(name) {
    name = name.replace('(Minimal Wear)', '');
    name = name.replace('(Battle-Scarred)', '');
    name = name.replace('(Well-Worn)', '');
    name = name.replace('(Field-Tested)', '');
    name = name.replace('(Factory New)', '');
    name = name.trim();
    if (name.length > 35) {
      name = name.substr(0, 32) + '...';
    }
    return name;
  }

  render() {
    let { item, items_checked } = this.props;
    let {  asset, ...offer } = item;
    // let {asset, ...offer} = item;

    // if (!asset) {
    //   asset = item;
    // }
    // if (!offer) {
    //   offer = {};
    // }

    if (!asset) {
        asset = offer;
        offer = {};
    }
    if (!asset.mdata) {
      asset.mdata = {};
    }
    if (!asset.idata) {
      asset.idata = {};
    }
// console.log('ASSET', asset);
    asset.mdata = typeof asset.mdata != 'object' ? JSON.parse(asset.mdata) : asset.mdata
    asset.idata = typeof asset.idata != 'object' ? JSON.parse(asset.idata) : asset.idata

    let high_bid_only = false;
    if (offer.buy_now) {
      const buy_now = new Decimal(offer.buy_now.split(' ')[0]);
      const high_bid = new Decimal(offer.high_bid.split(' ')[0]);
      high_bid_only = buy_now.lte(high_bid)
    } else if (offer.high_bid) {
      high_bid_only = true;
    }

    let name = this.correctName(asset.name || asset.mdata.name || asset.idata.name);

    let image = asset.img || asset.mdata.img || asset.idata.img;
    if (image.length === 46 && image.substr(0,1) === 'Q') {
        image = 'https://ipfs.io/ipfs/' + image;
    } else if (image && -1 === image.indexOf('http') && image.substr(0,1) === 'Q') {
      image = 'https://ipfs.io/ipfs/' + image;
    }

    return (
      <li key={this.props.key} className={`${offer.expires_at > 0 ? 'item_extra_card' : 'items_card'}`}>
        <div className="items_background">
          <div
            className="items_image"
            style={{backgroundImage: `url(${image})`}}
            onClick={() => this.redirectToAsset(asset)}
          />
          {/* {asset.unique && asset.unique.wear &&
            <ReactSpeedometer
              minValue={0}
              maxValue={1}
              value={0.5}
              width={200}
              height={200}
            />
          } */}
          <ItemVisualProps asset={asset} />
          {/* <input className="item_select" checked={false} name={asset.assetid} onClick={this.itemSelectEvent} type="checkbox"/> */}
          <div className="item_select">
            <label htmlFor={asset.assetid} className="checkbox_label">
              <input
                  id={asset.assetid}
                  type="checkbox"
                  name={asset.assetid}
                  // defaultChecked={items_checked.indexOf(String(asset.assetid)) !== -1}
                  checked={items_checked.indexOf(String(asset.assetid)) !== -1}
                  onChange={this.itemSelectEvent}
                  // onClick={this.itemSelectEvent}
                  className="checkbox"
              />
              {/* Select */}
              <span className="slider"></span>
            </label>
          </div>
        </div>
        <div
          className="items_info"
          // onClick={() => this.redirectToAsset(asset)}
        >
        <div className="name_box">
            <h3>
                {name}
            </h3>
          </div>

          {offer.price &&
            <div className="price auction_price">
                <div className="left_price">
                </div>
                <div className="right_price">
                  {wallet.formatPrice(offer.price)}&nbsp;<span>WAX</span>
                </div>
            </div>
          }
          {offer.buy_now && !high_bid_only && (
            <div className="price auction_price">
              <div className="left_price">
                {wallet.formatPrice(offer.high_bid)}&nbsp;<span>WAX</span>
              </div>
              <div className="right_price">
                {wallet.formatPrice(offer.buy_now)}&nbsp;<span>WAX</span>
              </div>
            </div>
          )}
          {offer.high_bid && high_bid_only &&
            <div className='price auction_price'>
              <div className="left_price">
                {wallet.formatPrice(offer.high_bid)}&nbsp;<span>WAX</span>
              </div>
              <div className="right_price">
                N / A
              </div>
            </div>
          }
          {/* <h3>
              {name}
          </h3>
          <h4><span>Author:</span>&nbsp;<a href={`${bloks}/account/${asset.author}`} target="_blank" rel="noopener noreferrer">{asset.author}</a></h4>
          <h4><span>Owner:</span>&nbsp;<a href={`${bloks}/account/${offer.owner ? offer.owner : asset.owner}`} target="_blank" rel="noopener noreferrer">{offer.owner ? offer.owner : asset.owner}</a></h4>
          {offer.price && (
            <div className="price market_price">
                <h4>Buy now:&nbsp;</h4>{wallet.formatPrice(offer.price)}&nbsp;<span>WAX</span>
            </div>
          )}
          {offer.high_bid && (
            <div className={`${offer.buy_now ? 'price auction_price': 'price offer_price'}`}>
                <h4>Best offer:&nbsp;</h4>{wallet.formatPrice(offer.high_bid)}&nbsp;<span>WAX</span>
            </div>
          )}
          {offer.buy_now && !high_bid_only && (
            <div className="price offer_price">
                <h4>Buy now:&nbsp;</h4>{wallet.formatPrice(offer.buy_now)}&nbsp;<span>WAX</span>
            </div>
          )} */}
        </div>
        <ItemButtons item={item} high_bid_only={high_bid_only} />
        { offer.expires_at &&
          <div className="item_extra_info_mobile">
            <div className="text">
              Auction end:&nbsp;
              {moment(offer.expires_at).utc().format('DD-MM-YYYY h:mm a')}&nbsp;
              ({moment(offer.expires_at).utc().fromNow()})
              {/* {moment.unix(offer.expires_at).utc().format('DD-MM-YYYY h:mm a')}&nbsp;
              ({moment.unix(offer.expires_at).utc().fromNow()}) */}
              </div>
          </div>
        }
      </li>

    )
  }
}

const mapStateToProps = ({ inventory }) => {
  const { items_checked } = inventory;
  return {
    items_checked,
  }
};

export default connect(mapStateToProps, {
  // showModal,
  // hideModal,
  selectInventoryItem,
})(Item);


