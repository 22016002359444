import {
    SET_SEARCH_PHRASE,
    FETCH_SEARCH_SUGGESTIONS,
    CLEAR_SEARCH_SUGGESTIONS,
    SUCCESS_SEARCH_SUGGESTIONS,
    MORE_SEARCH_SUGGESTIONS,
} from 'constants/ActionTypes';



export const setSearchPhrase = (string) => {
    return {
        type: SET_SEARCH_PHRASE,
        payload: string,
    }
}

export const onSuggestionsFetchRequested = (payload) => {
    // console.log('FETCH SUGESTIONS ACTION', payload);
    return {
        type: FETCH_SEARCH_SUGGESTIONS,
        payload: payload,
    }
}

export const suggestionsFetchSuccess = (suggestions) => {
    // console.log('SUCCESS', suggestions);
    return {
        type: SUCCESS_SEARCH_SUGGESTIONS,
        payload: suggestions,
    }
}
export const suggestionsFetchMore = (more) => {
    return {
        type: MORE_SEARCH_SUGGESTIONS,
        payload: more,
    }
}

export const onSuggestionsClearRequested = () => {
    return {
        type: CLEAR_SEARCH_SUGGESTIONS,
    }
}