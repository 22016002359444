
import {all, call, select, fork, put, takeEvery, takeLatest, delay} from 'redux-saga/effects';
import {
    loadAuctionsMore,
    loadMarketMore,
    loadMarketAllMore,
    loadMarketAll,
    loadMarketOffers,
    loadMarketAllSuccess,
    loadMarketOffersSuccess,
    loadMarketAuctionsSuccess,
    loadMarketAuctions,
    removeMarketItem,
    clearCart,
    getUsdTicker,
    getUsdTickerSuccess,
    loadHistoryLogSuccess,
    loadHistoryLogMore,
    setUsdTickerRunning,
} from '../actions/Market';
import {
    loadUser,
} from '../actions/Auth.js';
import {
    loadUserTransfers,
    loadUserAssets,
    removeInventoryItem,
    resetSelectedInventoryItems,
} from '../actions/Inventory.js';
import {
    BUY_ITEM,
    LOAD_MARKET_ALL,
    LOAD_MARKET_OFFERS,
    LOAD_MARKET_AUCTIONS,
    BUY_NOW_AUCTION,
    BID_AUCTION,
    CANCEL_MARKET_OFFER,
    SELL_ITEM,
    SET_AUCTION,
    CLOSE_AUCTION,
    CANCEL_MARKET_AUCTION,
    LOAD_MARKET_FILTER_DATA,
    SELL_MULTIPLE_ITEMS,
    PURCHASE_CART_ITEMS,
    LOAD_USD_TICKER,
    LOAD_HISTORY_LOG,
} from 'constants/ActionTypes';

import * as config from '../constants/config';
import * as wallet from '../util/wallet';
import * as api from '../util/api';
import { toast } from 'react-toastify';
import { authorize } from './Auth';



// =======================
// =======================
// ======================= LOAD
// =======================
// =======================

function* getMarketAll({ payload }) {
    try {
        let all, more;
        const filters = yield select(state => state.market.filters);
        if (payload && payload.more) {
            const stateAll = yield select(state => state.market.all);
            const skip = stateAll.length ? stateAll.length : 0;
            const options = {
                ...filters,
                skip,
            };

            all = yield call(api.getMarketTable, options, null);
            more = all && all.length == filters.limit;
            all = stateAll.concat(all);
        } else {
            const options = {
                ...filters,
                skip: 0,
            };
            all = yield call(api.getMarketTable, options, null);
            more = all && all.length == filters.limit;
        }
        if (all && all.length) {
            yield put(loadMarketAllMore(more));
            yield put(loadMarketAllSuccess(all));
        }
    } catch (e) {
        if (config.debug){
            console.error('get market all', e);
        }
        yield call(toast.error, e.message);
    }
}

function* getMarketOffers({ payload}) {
    try {
        let offers, more;
        const filters = yield select(state => state.market.filters);
        if (payload && payload.more) {
            const stateOffers = yield select(state => state.market.offers);
            const skip = stateOffers.length ? stateOffers.length : 0;
            const options = {
                ...filters,
                skip,
            };
            offers = yield call(api.getMarketTable, options, 'market');
            more = offers && offers.length == filters.limit;
            offers = stateOffers.concat(offers);
        } else {
            const options = {
                ...filters,
                skip: 0,
            };
            offers = yield call(api.getMarketTable, options, 'market');
            more = offers && offers.length == filters.limit;
        }
        if (offers && offers.length) {
            yield put(loadMarketMore(more));
            yield put(loadMarketOffersSuccess(offers));
        }
    } catch (e) {
        if (config.debug){
            console.error('get market offers', e);
        }
        yield call(toast.error, e.message);
    }
}

function* getMarketAuctions({payload}) {
    try {
        let auctions, more;
        const filters = yield select(state => state.market.filters);
        if (payload && payload.more) {
            const stateAuctions = yield select( state => state.market.auctions);
            const skip = stateAuctions.length ? stateAuctions.length : 0;
            const options = {
                ...filters,
                skip,
            }

            auctions = yield call(api.getMarketTable, options, 'auction');
            more = auctions && auctions.length == filters.limit;
            auctions = stateAuctions.concat(auctions);
        } else {
            const options = {
                ...filters,
                skip: 0,
            }
            auctions = yield call(api.getMarketTable, options, 'auction');
            more = auctions && auctions.length == filters.limit;
        }
        if (auctions && auctions.length) {
            yield put(loadAuctionsMore(more));
            yield put(loadMarketAuctionsSuccess(auctions));
        }
    } catch (e) {
        if (config.debug){
            console.error('get market auctions', e);
        }
        yield call(toast.error, e.message);
    }
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function* loadUsdTicker() {
    const ticker_running = yield select(state => state.market.ticker_running);
    if (ticker_running) {
        return;
    }
    yield put(setUsdTickerRunning());
    while(true) {
        try {
            let usdticker = yield call(api.getWaxUsdTicker);
            if (!usdticker) {
                continue;
            }
            yield put(getUsdTickerSuccess(usdticker));
        } catch (e) {
            yield call(toast.error, e.message);
        }
        // yield delay(20000);
        yield call(sleep, 20000);
    }
}

function* loadHistoryLogs({payload}) {
    let limit = 20;
    try {
        let history, more;
        if (payload && payload.more) {
            const stateHistory = yield select(state => state.market.history);
            const skip = stateHistory && stateHistory.length ? stateHistory.length : 0;
            const options = {
                skip,
                limit,
            };
            history = yield call(api.getHistoryLog, options);
            more = history && history.length === limit;
            history = stateHistory.concat(history);
        } else {
            const options = {
                skip: 0,
                limit,
            };
            history = yield call(api.getHistoryLog, options);
            more = history && history.length === limit;
        }

        if (history && history.length > 0) {
            yield put(loadHistoryLogSuccess(history));
            yield put(loadHistoryLogMore(more));
        } else {
            yield put(loadHistoryLogMore(false));
        }
    } catch (e) {
        yield call(toast.error, e.message);
    }
}

export function* loadMarketAllSaga() {
    yield takeLatest(LOAD_MARKET_ALL, getMarketAll);
}
export function* loadMarketOffersSaga() {
    yield takeLatest(LOAD_MARKET_OFFERS, getMarketOffers);
}
export function* loadMarketAuctionsSaga() {
    yield takeLatest(LOAD_MARKET_AUCTIONS, getMarketAuctions);
}
// export function* loadFiltersDataSaga() {
//     yield takeLatest(LOAD_MARKET_FILTER_DATA, loadFiltersData);
// }

export function* loadUsdTickerSaga() {
    yield takeLatest(LOAD_USD_TICKER, loadUsdTicker);
}

export function* loadHistoryLogsSaga() {
    yield takeLatest(LOAD_HISTORY_LOG, loadHistoryLogs);
}


// =======================
// =======================
// ======================= ACTION
// =======================
// =======================

function* buyAuction({ payload }) {
    const { asset, ...offer } = payload;
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            yield call(toast.error, 'Please log in');
            return;
        }
        const data = {
            price: offer.buy_now,
            id: asset.assetid || asset.id || asset.data.id,
            type: 'buy_now',
        };
        // console.log(data, asset, offer);
        const result = yield call(wallet.buyMarket, auth, data);

        yield call(toast.success, `Item ${asset.name || asset.assetid || asset.id} bought successfully!`);

        // yield put(loadMarketAuctions());
        // yield put(loadUser());
        // yield put(loadUserTransfers());

        yield put(removeMarketItem(asset));
        yield put(loadUser());
        yield put(loadUserTransfers());

        // REMOVE THIS ITEM FROM OFFERS ARRAY ?
        // LOAD USER TRANSFERS (ITEM TO CLAIM)
        // ADD COUNTER TO TRANSFERS ! USER ITEMS TO CLAIM SHOULD LOAD AT PAGE START (if authed)
    } catch (e) {
        if (config.debug){
            console.error('buy auction', e);
        }
        yield call(toast.error, e.message);
    }
}

function* bidAuction({ payload }) {
    const { bid, asset } = payload;
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            yield call(toast.error, 'Please log in');
            return;
        }
        const data = {
            price: Number(bid).toFixed(8) + ' WAX',
            id: asset.assetid || asset.id || asset.data.id,
            type: 'bid',
        };
        const result = yield call(wallet.buyMarket, auth, data);

        yield call(toast.success, `Item ${asset.name || asset.assetid || asset.id} bid successfully!`);

        yield put(loadUser());
        // yield put(loadMarketAuctions());
        // TODO: REFRESH ONLY ONE MARKET OFFER


    } catch (e) {
        if (config.debug){
            console.error('bid auction', e);
        }
        yield call(toast.error, e.message);
    }
}

function* buyItem({ payload }) {
    const { asset, ...offer } = payload;

    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            yield call(toast.error, 'Please log in');
            return;
        }
        const data = {
            price: offer.price,
            id: asset.assetid || asset.id || asset.data.id,
            type: 'market',
        };
        const result = yield call(wallet.buyMarket, auth, data);

        yield call(toast.success, `Item ${asset.name || asset.assetid || asset.id} bought successfully!`);

        // yield put(loadUser());
        // yield put(loadUserTransfers());
        // yield put(loadMarketOffers());

        yield put(removeMarketItem(asset));
        yield put(loadUser());
        yield put(loadUserTransfers());

        // REMOVE THIS ITEM FROM OFFERS ARRAY ?
        // LOAD USER TRANSFERS (ITEM TO CLAIM)
        // ADD COUNTER TO TRANSFERS ! USER ITEMS TO CLAIM SHOULD LOAD AT PAGE START (if authed)

    } catch (e) {
        if (config.debug){
            console.error('buy market', e);
        }
        yield call(toast.error, e.message);
    }
}

function* buyCart({ payload }) {
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            yield call(toast.error, 'Please log in');
            return;
        }
        let assets = yield select( state => state.market.cart_items );
        assets = assets.map(asset => {
            return {
                id: asset.assetid,
                price: asset.price,
                type: asset.type,
            }
        });
        const data = {
            assets,
            to: 'waxinventory',
        }
        const result = yield call(wallet.buyMarketBulk, auth, data);

        yield call(toast.success, `${assets.length} Items bought successfully!`);
        yield put(clearCart());
    } catch (e) {
        if (config.debug){
            console.error('buy market', e);
        }
        yield call(toast.error, e.message);
    }
}

function* cancelMarketOffer({payload}) {
    let {asset} = payload;
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            yield call(toast.error, 'Please log in');
            return;
        }
        const data = {
            assetids: [asset.assetid],
        }
        yield call(wallet.cancelMarketOffer, auth, data);

        yield call(toast.success, `Item ${asset.name || asset.assetid || asset.id} canceled successfully!`);

        yield put(removeMarketItem(asset));

        // yield put(loadUserAssets());
        // yield put(loadMarketOffers());
    } catch (e) {
        if (config.debug){
            console.error('cancel market', e);
        }
        yield call(toast.error, e.message);
    }
}

function* cancelMarketAuction({payload}) {
    const {asset} = payload;
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            yield call(toast.error, 'Please log in');
            return;
        }
        const data = {
            assetids: [asset.assetid],
        }
        yield call(wallet.cancelMarketAuction, auth, data);

        yield call(toast.success, `Item ${asset.name || asset.assetid || asset.id} canceled successfully!`);

        yield put(removeMarketItem(asset));

        // yield put(loadUserAssets({}));
        // yield put(loadMarketAuctions());
    } catch (e) {
        if (config.debug){
            console.error('cancel market', e);
        }
        yield call(toast.error, e.message);
    }
}

function* sellItem({payload}) {
    const {asset, price} = payload; //todo: rename to asset?
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            yield call(toast.error, 'Please log in');
            return;
        }
        const data = {
            price: `${Number(price).toFixed(8)} WAX`,
            assetids: [asset.assetid],
        }
        const result = yield call(wallet.sellMarket, auth, data);
        yield call(toast.success, 'Item transfer success!'); //todo: message correct

        yield put(removeInventoryItem(asset));

        // yield put(loadMarketOffers());
        // yield put(loadUserAssets());
    } catch (e) {
        if (config.debug) {
            console.error('sell item', e);
        }
        yield call(toast.error, e.message);
    }
}

function* sellMultipleItems({payload}) {
    const {assetids, price} = payload; //todo: rename to asset?
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            yield call(toast.error, 'Please log in');
            return;
        }
        const data = {
            price: `${Number(price).toFixed(8)} WAX`,
            assetids: assetids,
        }
        const result = yield call(wallet.sellMarket, auth, data);
        yield call(toast.success, 'Item transfer success!'); //todo: message correct

        yield put(removeInventoryItem(assetids));
        yield put(resetSelectedInventoryItems());
    } catch (e) {
        if (config.debug) {
            console.error('sell item', e);
        }
        yield call(toast.error, e.message);
    }
}

function* setAuction({payload}) {
    const {high_bid, buy_now, asset, expires_at} = payload; //todo: change min_price and item !
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            yield call(toast.error, 'Please log in');
            return;
        }
        const data = {
            assetids: [asset.assetid],
            high_bid,
            buy_now,
            expires_at: expires_at, // TODO: OFC SET THE DATE OMG !
        }
        // console.log('set auction', data)
        const result = yield call(wallet.setAuction, auth, data);
        yield call(toast.success, 'Item transfer success!');

        yield put(loadMarketAuctions());
        yield put(loadUserAssets({}));
    } catch (e) {
        if (config.debug){
            console.error('set auction', e);
        }
        yield call(toast.error, e.message);
    }
}

function* closeAuction({payload}) {
    const {asset, ...offer} = payload;
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            yield call(toast.error, 'Please log in');
            return;
        }
        const data = {
            assetids: [asset.assetid],
        }
        const result = yield call(wallet.closeAuction, auth, data);
        yield call(toast.success, 'Auction closed success!');

        // yield put(loadUserAssets({}));
        // yield put(loadUserTransfers());
        // yield put(loadMarketAuctions());
        yield put(removeMarketItem(asset));
        yield put(loadUser());
        yield put(loadUserTransfers());

    } catch (e) {
        if (config.debug){
            console.error('close auction', e);
        }
        yield call(toast.error, e.message);
    }
}

// ==================
// ==================
// ================== SAGAS
// ==================
// ==================

export function* buyItemSaga() {
    yield takeLatest(BUY_ITEM, buyItem);
}

export function* buyCartSaga() {
    yield takeLatest(PURCHASE_CART_ITEMS, buyCart);
}

export function* buyAuctionSaga() {
    yield takeLatest(BUY_NOW_AUCTION, buyAuction);
}

export function* bidAuctionSaga() {
    yield takeLatest(BID_AUCTION, bidAuction);
}

export function* cancelMarketOfferSaga() {
    yield takeLatest(CANCEL_MARKET_OFFER, cancelMarketOffer);
}

export function* sellItemSaga() {
    yield takeLatest(SELL_ITEM, sellItem);
}
export function* sellMultipleItemsSaga() {
    yield takeLatest(SELL_MULTIPLE_ITEMS, sellMultipleItems);
}

export function* setAuctionSaga() {
    yield takeLatest(SET_AUCTION, setAuction);
}

export function* closeAuctionSaga() {
    yield takeLatest(CLOSE_AUCTION, closeAuction);
}

export function* cancelMarketAuctionSaga() {
    yield takeLatest(CANCEL_MARKET_AUCTION, cancelMarketAuction);
}

export default function* rootSaga() {
    yield all([
        fork(loadMarketAllSaga),
        fork(loadMarketOffersSaga),
        fork(loadMarketAuctionsSaga),
        fork(loadUsdTickerSaga),
        fork(loadHistoryLogsSaga),
        fork(buyCartSaga),
        fork(buyItemSaga),
        fork(buyAuctionSaga),
        fork(bidAuctionSaga),
        fork(sellItemSaga),
        fork(sellMultipleItemsSaga),
        fork(setAuctionSaga),
        fork(cancelMarketOfferSaga),
        fork(closeAuctionSaga),
        fork(cancelMarketAuctionSaga),
    ]);
}