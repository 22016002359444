import Modal from 'react-modal';
import React from 'react';
import { connect } from 'react-redux';
import { Input } from '@material-ui/core';

// import {
//     setItemData,
// } from '../../actions/Modal';


class Sell extends React.Component {
  constructor (props) {
    super(props);

  }

  onChange(event) {
    // TODO: validation: check if its proper number !

    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    this.props.onChange(fieldName, fieldValue);
  }

  render () {
    return (
      <div className="modal_fields">
        <label htmlFor="price">
            Price:
        </label>

        <div className="price_input">
          <input
            placeholder="0.00"
            // value={price}
            name="price"
            onChange={(e) => this.onChange(e)}
            autoComplete="off"
          />
          <div className="wax_input_label">
            <span>WAX</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ modal }) => {

    const { isModalOpen, item, action, data} = modal;
    return {
        isModalOpen,
        item,
        action,
        data,
    }
};

export default connect(mapStateToProps, {
    // setItemData,
})(Sell);
