import React from 'react'
import {NavLink, withRouter} from 'react-router-dom';
import waxinventory_logo from '../../assets/images/inv_logo.png';

class Logo extends React.Component {
  render() {
    return (
      <div className="logo">
          <NavLink to="/marketplace">
          <img className="waxinventory_logo" src={waxinventory_logo} alt="waxinventory logo"/>
              {/* wax
            <span>INVENTORY</span> */}
        </NavLink>
      </div>
    )
  }
}

export default Logo

