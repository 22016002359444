import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import * as moment from 'moment';
import {history} from '../../../store';
import {
    loadUserTransfers,
    claimTransfer,
    removeFromCart,
    buyCartItems,
} from '../../../actions';
import trash_ico from '../../../assets/images/item/trash@3x.png';
import {Decimal} from 'decimal.js';

class Cart extends React.Component
{
    componentDidMount() {
        // this.props.loadUserTransfers();
    }

    goToMarket() {
        history.push('/marketplace');
    }

    render() {
        const { cart_items } = this.props;
        const isNotEmpty =  cart_items && Array.isArray(cart_items) && cart_items.length > 0;
        let summary = new Decimal(0);
        if (isNotEmpty) {
            cart_items.forEach(offer => {
                let price = new Decimal(offer.price.split(' ')[0]);
                summary = summary.add(price);
            });
        }

        return (
            <div className="app-wrapper cart">
                <Helmet>
                    <title>Shopping Cart</title>
                    <meta name="description" content="Buy NFT's in bulk with shopping cart on WAX inventory - WAX Marketplace."/>
                </Helmet>
                <div className="container">
                    <h1>My Cart</h1>
                    { isNotEmpty &&
                        cart_items.map((offer, idx) => {
                            const {asset} = offer;
                            let image = asset.img || asset.idata.img || asset.mdata.img;
                            if (image && image.length === 46 && image.substr(0,1) === 'Q') {
                                image = 'https://ipfs.io/ipfs/' + image;
                            } else if (image && -1 === image.indexOf('http') && image.substr(0,1) === 'Q') {
                                image = 'https://ipfs.io/ipfs/' + image;
                            }
                            {/* console.log('ASSET', asset); */}
                            return (
                                <div key={idx} className="item_cart">
                                    <div className="item_background">
                                        <div className="item_image" style={{backgroundImage: `url(${image})`}} />
                                    </div>
                                    <div className="item_content">
                                        <div className="item_desc">
                                            <h2>
                                                {asset.idata.name || asset.mdata.name || 'missing name'}
                                            </h2>
                                            <h4><span>Author:</span>&nbsp;<a>{asset.author}</a></h4>
                                            <h4>
                                                {asset.price}
                                                {/* {moment.unix(transfer.cdate).utc().format('DD-MM-YYYY h:mm a')} */}
                                            </h4>
                                        </div>
                                        <div className="item_price">
                                            {offer.price.split(' ')[0]}&nbsp;<span>WAX</span>
                                        </div>
                                    </div>
                                    <div className="item_action">
                                        <button
                                            className="btn btn_gray btn_delete"
                                            onClick={() => this.props.removeFromCart(offer)}
                                            // onClick={() => this.props.claimTransfer(ele)}
                                        >
                                            <img className="icon" src={trash_ico} alt="Remove item"/>
                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    }
                    { isNotEmpty &&
                        <div className="cart_summary">
                            <div className="item_price">
                                <i>TOTAL</i>&nbsp;{summary.toFixed(8)}&nbsp;<span>WAX</span>
                            </div>
                        </div>
                    }
                    { !isNotEmpty &&
                        <div className="page-placeholder-extra">No items in cart.</div>
                    }
                    <div className="cart_action">
                        <Link to="/marketplace" className="btn btn_standalone_secondary">
                            CONTINUE SHOPPING
                        </Link>
                        <button
                            className={`btn btn_standalone_primary ${!isNotEmpty ? 'not-allowed' : ''}`}
                            onClick={e => this.props.buyCartItems()}
                            disabled={!isNotEmpty}
                        >
                            GO TO PAYMENT
                        </button>
                    </div>

                </div>
            </div>
        )
    }
}


const mapStateToProps = ({ market }) => {
    // const { isModalOpen } = modal;
    // const { transfers, transfers_more } = inventory;
    const { cart_items } = market;
    return {
        cart_items,
    }
};

export default connect(mapStateToProps, {
    // hideModal,
    // loadUserTransfers,
    // claimTransfer,
    buyCartItems,
    removeFromCart,
})(Cart);
