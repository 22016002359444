import {all, call, select, fork, put, takeEvery, takeLatest, debounce, throttle} from 'redux-saga/effects';
import {
    suggestionsFetchSuccess,
    suggestionsFetchMore,
} from '../actions/Search';
import {
    FETCH_SEARCH_SUGGESTIONS,
} from 'constants/ActionTypes';


import * as config from '../constants/config';
import * as wallet from '../util/wallet';
import * as api from '../util/api';
import { toast } from 'react-toastify';
import { authorize } from './Auth';



// =======================
// =======================
// ======================= LOAD
// =======================
// =======================

function* fetchAutocomplete({payload}) {
    let limit = 50;

    try {
        let suggestions;
        const search = yield select(state => state.search.suggestions);
        if (payload && payload.more) {
            const stateSuggestions = search.suggestions;
            const filters = {
                skip: stateSuggestions && stateSuggestions.length ? stateSuggestions.length : 0,
                limit,
                search: payload.value,
            };
            suggestions = yield call(api.search, filters);
            suggestions = stateSuggestions.concat(suggestions);
        } else {
            const filters = {
                skip: 0,
                limit,
                search: payload.value,
            };
            suggestions = yield call(api.search, filters);
        }

        if (suggestions && suggestions.length) {
            yield put(suggestionsFetchSuccess(suggestions));
            yield put(suggestionsFetchMore(suggestions.length == limit));
        }
    } catch(e) {
        if (config.debug){
            console.error('get user transfers', e);
        }
        yield call(toast.error, e.message);
    }
}


// =======================
// =======================
// ======================= ACTION
// =======================
// =======================



// =======================
// =======================
// ======================= SAGA
// =======================
// =======================

// export function* fetchAutocompleteSaga() {
//     // yield throttle(600, FETCH_SEARCH_SUGGESTIONS, fetchAutocomplete)
//     yield debounce(600, FETCH_SEARCH_SUGGESTIONS, fetchAutocomplete)
// }





export default function* rootSaga() {
    yield all([
        yield debounce(600, FETCH_SEARCH_SUGGESTIONS, fetchAutocomplete),
    ]);
}