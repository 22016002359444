import React, {Component} from 'react';
import MomentUtils from '@date-io/moment';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl';
import 'assets/vendors/style';
import defaultTheme from './themes/darkTheme';
import AppLocale from '../lngProvider';

import MainApp from 'app/index';
import Login from './Login';
// import SignIn from './SignIn';
// import SignUp from './SignUp';
import {
  setInitUrl,
  initAccessContext1,
} from '../actions/Auth';
import asyncComponent from 'util/asyncComponent';
// import {NotificationContainer, NotificationManager} from 'react-notifications';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const RestrictedRoute = ({component: Component, user, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      user
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

class App extends Component {

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    this.props.initAccessContext1()
  }

  render() {
    const {match, location, locale, user, initURL, isDirectionRTL} = this.props;
    if (location.pathname === '/') {
      return ( <Redirect to={'/marketplace'}/> );
    }
    if (location.pathname.indexOf('/app') !== -1) {
      return ( <Redirect to={location.pathname.replace('/app', '')}/> );
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
              <div className="app-main">
                <Switch>
                  <Route exact path='/login' component={Login}/>
                  <Route exact path='/login/' component={Login}/>
                  <Route path={`${match.url}`} component={MainApp}/>
                  {/* <Route path={`${match.url}app`} component={MainApp}/> */}
                  <Route component={asyncComponent(() => import('components/Error404'))}/>
                  <Route
                    component={asyncComponent(() => import('components/Error404'))}/>
                </Switch>
              </div>
          </IntlProvider>
    );
  }
}

const mapStateToProps = ({settings, auth, global}) => {
  const {sideNavColor, locale} = settings;
  const {user, initURL} = auth;
  // const {showErrorMessage, showSuccessMessage, message} = global;
  return {
    sideNavColor,
    locale,
    user,
    initURL,
    // showSuccessMessage,
    // showErrorMessage,
    // message,
  }
};

export default connect(mapStateToProps, {
  setInitUrl,
  initAccessContext1,
})(App);

