import React from 'react'
import {NavLink, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import searchico from '../../assets/images/item/search@3x.png';
import {
    setSearchPhrase,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
} from '../../actions/Search.js';
import Autosuggest from 'react-autosuggest';
import ItemSearch from 'components/ItemSearch';


const placeholder = 'Search for name, asset id, author, owner, cathegory, issuer';

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
    return <ItemSearch item={suggestion} />;
}

class Search extends React.Component
{
    constructor(props) {
        super(props);
        // this.search = this.search.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        this.props.setSearchPhrase('');
    }

    componentDidUpdate() {
    }

    // search() {
    //     this.props.search()
    // }

    onChange(ele) {
        this.props.setSearchPhrase(ele.target.value);
    }

    render() {
        const inputProps = {
            placeholder: placeholder,
            value: this.props.string ? this.props.string : '',
            onChange: this.onChange
        };
        return (
            <div className="search">
                <Autosuggest
                    suggestions={this.props.suggestions ? this.props.suggestions : []}
                    onSuggestionsFetchRequested={this.props.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.props.onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
                <div className="wax_input_label">
                    <a
                        className="search_icon"
                        // onClick={this.search}
                    >
                        <img src={searchico} alt="Search for items"/>
                    </a>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({ search }) => {
    const { string, suggestions, isLoading } = search;
    return {
        string,
        suggestions,
        isLoading,
    }
};

export default connect(mapStateToProps, {
    setSearchPhrase,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
})(Search);


