import {
    LOAD_NFT_MARKET_SUCCESS,
    LOAD_NFT_ASSET_SUCCESS,
    LOAD_NFT_LOGS_SUCCESS,
    SHOW_LOADING,
} from '../constants/ActionTypes.js';


const INIT_STATE = {
    nft: {},
    ft: {},
    nft_log: [],
    loading: false,
    loadingMarket: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_NFT_ASSET_SUCCESS: {
            return {
                ...state,
                nft: action.payload,
                loading: false,
            };
        }
        case LOAD_NFT_MARKET_SUCCESS: {
            return {
                ...state,
                offer: action.payload,
                loadingMarket: false,
            };
        }
        case LOAD_NFT_LOGS_SUCCESS: {
            return {
                ...state,
                nft_log: action.payload,
            }
        }
        case SHOW_LOADING: {
            return {
                ...state,
                nft: {},
                ft: {},
                loading: true,
                loadingMarket: true,
            };
        }

        default:
            return state;
    }
}
