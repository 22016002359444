import React from 'react'
import { connect } from 'react-redux'
import * as wallet from '../../util/wallet';

import {
  showModal,
  hideModal,
} from '../../actions/Modal';

// import ItemButtons from './buttons'; //TODo: ofc
import {history} from '../../store';
import * as moment from 'moment';
import {Decimal} from 'decimal.js';

class Item extends React.Component {

  constructor(props) {
    super(props);

    this.redirectToAsset = this.redirectToAsset.bind(this);
  }

  redirectToAsset(asset) {
    history.push(`/nft/${asset.assetid}`);
  }

  render() {
    let { item } = this.props;
    let asset = item;
    let offer = {};
    if (asset.market || asset.offer) {
      offer = asset.market;

    }

    let high_bid_only = false;
    if (offer.buy_now) {
      const buy_now = new Decimal(offer.buy_now.split(' ')[0]);
      const high_bid = new Decimal(offer.high_bid.split(' ')[0]);
      high_bid_only = buy_now.lte(high_bid)
    } else if (offer.high_bid) {
      high_bid_only = true;
    }

    let name = asset.name || asset.mdata.name || asset.idata.name
    if (name.length > 45) {
      name = name.substr(0, 45);
    }

    let image = asset.img || asset.mdata.img || asset.idata.img;
    if (image.length === 46 && image.substr(0,1) === 'Q') {
        image = 'https://ipfs.io/ipfs/' + image;
    } else if (image && -1 === image.indexOf('http') && image.substr(0,1) === 'Q') {
      image = 'https://ipfs.io/ipfs/' + image;
    }
// console.log('asset in item search', asset, offer);
    return (
      <div className="item_search">
      {/* <div className={`${offer.expires_at > 0 ? 'item_extra_card item_search' : 'items_card item_search'}`}> */}
        <div className="item_search_background">
          <div
            className="item_search_background_image"

            style={{backgroundImage: `url(${image})`}}
            onClick={() => this.redirectToAsset(asset)}
          />
        </div>
        <div
          className="item_search_info"
          onClick={() => this.redirectToAsset(asset)}
        >
          <h3>
              {name}
          </h3>
          <h4><span>Author:</span>&nbsp;<a>{asset.author}</a></h4>
          <h4><span>Owner:</span>&nbsp;<a>{offer.owner ? offer.owner : asset.owner}</a></h4>
          {offer.price && (
            <div className="price market_price">
                <h4>Buy now:&nbsp;</h4>{wallet.formatPrice(offer.price)}&nbsp;<span>WAX</span>
            </div>
          )}
          {offer.high_bid && (
            <div className={`${offer.buy_now ? 'price auction_price': 'price offer_price'}`}>
                <h4>Best offer:&nbsp;</h4>{wallet.formatPrice(offer.high_bid)}&nbsp;<span>WAX</span>
            </div>
          )}
          {offer.buy_now && !high_bid_only && (
            <div className="price offer_price">
                <h4>Buy now:&nbsp;</h4>{wallet.formatPrice(offer.buy_now)}&nbsp;<span>WAX</span>
            </div>
          )}
        </div>
        {/* <ItemButtons item={item} high_bid_only={high_bid_only} /> */}
        {/* { offer.expires_at &&
          <div className="item_extra_info_mobile">
            <div className="text">
              Auction end:&nbsp;
              {moment.unix(offer.expires_at).utc().format('DD-MM-YYYY h:mm a')}&nbsp;
              ({moment.unix(offer.expires_at).utc().fromNow()})
              </div>
          </div>
        } */}
      </div>

    )
  }
}

const mapStateToProps = ({ auth, vault }) => {
//   const { authUser } = auth;
  return {
    // authUser,
  }
};

export default connect(mapStateToProps, {
  showModal,
  hideModal,
})(Item);


