import {
    LOAD_USER_TRANSFERS,
    LOAD_USER_TRANSFERS_SUCCESS,
    CLAIM_TRANSFER,
    CLAIM_TRANSFERS,
    LOAD_ASSETS,
    LOAD_ASSETS_SUCCESS,
    LOAD_USER_OFFERS,
    LOAD_USER_OFFERS_SUCCESS,
    TRANSFER_ITEM,
    LOAD_USER_ASSETS_MORE,
    LOAD_USER_OFFERS_MORE,
    LOAD_USER_TRANSFERS_MORE,
    LOAD_USER_AUCTIONS,
    LOAD_USER_AUCTIONS_SUCCESS,
    LOAD_USER_AUCTIONS_MORE,
    LOAD_USER_TRANSACTIONS,
    LOAD_USER_TRANSACTIONS_SUCCESS,
    LOAD_USER_TRANSACTIONS_MORE,
    LOAD_USER_INACTION,
    LOAD_USER_INACTION_SUCCESS,
    LOAD_USER_INACTION_MORE,
    REMOVE_INVENTORY_ITEM,
    SET_INVENTORY_ACTION_FILTERS,
    SET_INVENTORY_ACTION_FILTERS_DATA,
    SET_INVENTORY_ACTION_FILTERS_SUCCESS,
    SET_INVENTORY_ITEM_FILTERS,
    SET_INVENTORY_ITEM_FILTERS_DATA,
    SET_INVENTORY_ITEM_FILTERS_SUCCESS,
    SELECT_INVENTORY_ITEM,
    SET_ITEMS_PRICE,
    RESET_SELECTED_INVENTORY_ITEMS,
} from '../constants/ActionTypes.js';


export const loadUserTransfers = (auth) => {
    return {
        type: LOAD_USER_TRANSFERS,
        payload: auth
    }
}

export const loadUserTransfersSuccess = (transfers) => {
    return {
        type: LOAD_USER_TRANSFERS_SUCCESS,
        payload: transfers,
    }
}
export const loadUserTransactions = (payload) => {
    return {
        type: LOAD_USER_TRANSACTIONS,
        payload: payload,
    }
}

export const loadUserTransactionsSuccess = (transactions) => {
    return {
        type: LOAD_USER_TRANSACTIONS_SUCCESS,
        payload: transactions,
    }
}

export const loadUserTransactionsMore = (more) => {
    return {
        type: LOAD_USER_TRANSACTIONS_MORE,
        payload: more,
    }
}

export const claimTransfer = (item) => {
    return {
        type: CLAIM_TRANSFER,
        payload: item,
    }
}
export const claimTransfers = (assetids) => {
    return {
        type: CLAIM_TRANSFERS,
        payload: assetids,
    }
}

export const loadUserAssets = (options) => {
    return {
        type: LOAD_ASSETS,
        payload: options,
    }
}

export const loadUserAssetsSuccess = (assets) => {
    return {
        type: LOAD_ASSETS_SUCCESS,
        payload: assets,
    }
}

export const loadUserOffers = (options) => {
    return {
        type: LOAD_USER_OFFERS,
        payload: options,
    }
}
export const loadUserOffersSuccess = (offers) => {
    return {
        type: LOAD_USER_OFFERS_SUCCESS,
        payload: offers,
    }
}

export const loadUserInAction = (options) => {
    return {
        type: LOAD_USER_INACTION,
        payload: options,
    }
}
export const loadUserInActionSuccess = (in_action) => {
    return {
        type: LOAD_USER_INACTION_SUCCESS,
        payload: in_action,
    }
}
export const loadUserInActionMore = (more) => {
    return {
        type: LOAD_USER_INACTION_MORE,
        payload: more,
    }
}

export const transferItem = (form) => {
    return {
        type: TRANSFER_ITEM,
        payload: form,
    }
}

export const loadUserAssetsMore = (more) => {
    return {
        type: LOAD_USER_ASSETS_MORE,
        payload: more,
    }
}

export const loadUserOffersMore = (more) => {
    return {
        type: LOAD_USER_OFFERS_MORE,
        payload: more,
    }
}

export const loadUserTransfersMore = (more) => {
    return {
        type: LOAD_USER_TRANSFERS_MORE,
        payload: more,
    }
}

export const loadUserAuctions = (options) => {
    return {
        type: LOAD_USER_AUCTIONS,
        payload: options,
    }
}

export const loadUserAuctionsSuccess = (auctions) => {
    return {
        type: LOAD_USER_AUCTIONS_SUCCESS,
        payload: auctions,
    }
}

export const loadUserAuctionsMore = (more) => {
    return {
        type: LOAD_USER_AUCTIONS_MORE,
        payload: more,
    }
}

export const removeInventoryItem = (asset) => {
    return {
        type: REMOVE_INVENTORY_ITEM,
        payload: asset,
    }
}

export const setInventoryActionFiltersData = (data) => {
    return {
        type: SET_INVENTORY_ACTION_FILTERS_DATA,
        payload: data,
    }
}
export const setInventoryActionFilters = (filters) => {
    return {
        type: SET_INVENTORY_ACTION_FILTERS,
        payload: filters,
    }
}
export const setInventoryActionFiltersSuccess = (filters) => {
    return {
        type: SET_INVENTORY_ACTION_FILTERS_SUCCESS,
        payload: filters,
    }
}

export const setInventoryItemFiltersData = (data) => {
    return {
        type: SET_INVENTORY_ITEM_FILTERS_DATA,
        payload: data,
    }
}

export const setInventoryItemFilters = (filters) => {
    return {
        type: SET_INVENTORY_ITEM_FILTERS,
        payload: filters,
    }
}
export const setInventoryItemFiltersSuccess = (filters) => {
    return {
        type: SET_INVENTORY_ITEM_FILTERS_SUCCESS,
        payload: filters,
    }
}

export const selectInventoryItem = (asset) => {
    return {
        type: SELECT_INVENTORY_ITEM,
        payload: asset,
    }
}

export const setItemsPrice = (price) => {
    return {
        type: SET_ITEMS_PRICE,
        payload: price,
    }
}

export const resetSelectedInventoryItems = () => {
    return {
        type: RESET_SELECTED_INVENTORY_ITEMS,
    }
}