import {
    LOAD_MARKET_OFFERS,
    LOAD_MARKET_OFFERS_SUCCESS,
    LOAD_MARKET_AUCTIONS,
    LOAD_MARKET_AUCTIONS_SUCCESS,
    LOAD_MARKET_ALL,
    LOAD_MARKET_ALL_SUCCESS,
    LOAD_AUCTIONS_MORE,
    BUY_NOW_AUCTION,
    BUY_ITEM,
    BID_AUCTION,
    CANCEL_MARKET_OFFER,
    SELL_ITEM,
    SET_AUCTION,
    CLOSE_AUCTION,
    LOAD_MARKET_MORE,
    LOAD_MARKET_ALL_MORE,
    CANCEL_MARKET_AUCTION,
    REMOVE_MARKET_ITEM,
    SET_MARKET_FILTERS,
    SET_MARKET_FILTERS_DATA,
    SET_MARKET_FILTERS_SUCCESS,
    SELL_MULTIPLE_ITEMS,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    PURCHASE_CART_ITEMS,
    CLEAR_CART,
    LOAD_USD_TICKER,
    LOAD_USD_TICKER_SUCCESS,
    LOAD_HISTORY_LOG,
    LOAD_HISTORY_LOG_SUCCESS,
    LOAD_HISTORY_LOG_MORE,
    LOAD_USD_TICKER_RUNNING,
} from 'constants/ActionTypes';


export const loadMarketAll = (options) => {
    return {
        type: LOAD_MARKET_ALL,
        payload: options,
    }
}
export const loadMarketAllSuccess = (options) => {
    return {
        type: LOAD_MARKET_ALL_SUCCESS,
        payload: options,
    }
}

export const loadMarketOffers = (options) => {
    return {
        type: LOAD_MARKET_OFFERS,
        payload: options,
    }
}

export const loadMarketOffersSuccess = (offers) => {
    return {
        type: LOAD_MARKET_OFFERS_SUCCESS,
        payload: offers,
    }
}

export const loadMarketAuctions = (options) => {
    return {
        type: LOAD_MARKET_AUCTIONS,
        payload: options,
    }
}

export const loadMarketAuctionsSuccess = (auctions) => {
    return {
        type: LOAD_MARKET_AUCTIONS_SUCCESS,
        payload: auctions,
    }
}
export const loadAuctionsMore = (more) => {
    return {
        type: LOAD_AUCTIONS_MORE,
        payload: more,
    }
}
export const loadMarketMore = (more) => {
    return {
        type: LOAD_MARKET_MORE,
        payload: more,
    }
}
export const loadMarketAllMore = (more) => {
    return {
        type: LOAD_MARKET_ALL_MORE,
        payload: more,
    }
}

export const buyItem = (item) => {
    return {
        type: BUY_ITEM,
        payload: item,
    }
}

export const buyAuction = (item) => {
    return {
        type: BUY_NOW_AUCTION,
        payload: item,
    }
}

export const bidAuction = (item) => {
    return {
        type: BID_AUCTION,
        payload: item,
    }
}

export const cancelMarketOffer = (item) => {
    return {
        type: CANCEL_MARKET_OFFER,
        payload: item,
    }
}

export const cancelMarketAuction = (item) => {
    return {
        type: CANCEL_MARKET_AUCTION,
        payload: item,
    }
}

export const sellItem = (form) => {
    return {
        type: SELL_ITEM,
        payload: form,
    };
}

export const sellMultipleItems = (form) => {
    return {
        type: SELL_MULTIPLE_ITEMS,
        payload: form,
    };
}

export const setAuction = (form) => {
    return {
        type: SET_AUCTION,
        payload: form,
    }
}

export const closeAuction = (item) => {
    return {
        type: CLOSE_AUCTION,
        payload: item,
    }
}

export const removeMarketItem = (asset) => {
    return {
        type: REMOVE_MARKET_ITEM,
        payload: asset,
    }
}

export const setMarketFilters = (filters) => {
    return {
        type: SET_MARKET_FILTERS,
        payload: filters,
    }
}
export const setMarketFiltersData = (data) => {
    return {
        type: SET_MARKET_FILTERS_DATA,
        payload: data,
    }
}
export const setMarketFiltersSuccess = (filters) => {
    return {
        type: SET_MARKET_FILTERS_SUCCESS,
        payload: filters,
    }
}

export const addToCart = (item) => {
    return {
        type: ADD_TO_CART,
        payload: item,
    }
}

export const removeFromCart = (item) => {
    return {
        type: REMOVE_FROM_CART,
        payload: item,
    }
}
export const clearCart = () => {
    return {
        type: CLEAR_CART,
    }
}

export const buyCartItems = () => {
    return {
        type: PURCHASE_CART_ITEMS,
    }
}

export const getUsdTicker = () => {
    return {
        type: LOAD_USD_TICKER,
    }
}
export const setUsdTickerRunning = () => {
    return {
        type: LOAD_USD_TICKER_RUNNING,
    }
}
export const getUsdTickerSuccess = (waxusd) => {
    return {
        type: LOAD_USD_TICKER_SUCCESS,
        payload: waxusd,
    }
}

export const loadHistoryLog = (options) => {
    return {
        type: LOAD_HISTORY_LOG,
        payload: options,
    }
}

export const loadHistoryLogSuccess = (history) => {
    return {
        type: LOAD_HISTORY_LOG_SUCCESS,
        payload: history,
    }
}
export const loadHistoryLogMore = (more) => {
    return {
        type: LOAD_HISTORY_LOG_MORE,
        payload: more,
    }
}