import {
    SHOW_MESSAGE,
    SIGNOUT_USER_SUCCESS,
    INIT_ACCESS_CONTEXT_PAYLOAD,
    SAVE_AUTH_USER,
    SAVE_WALLET,
    ENABLE_AUTH_STATUS,
    LOADER_STATUS,
    SET_AUTO_LOGIN,
    SET_LOGIN_RESULT,
    SET_USER_TICKER_RUNNING,
} from 'constants/ActionTypes';

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    name: localStorage.getItem('account_name'),
    user: JSON.parse(localStorage.getItem('user')),
    method: localStorage.getItem('method'),
    eosio: {},
    auth_status: false,
    autologin: Boolean(localStorage.getItem('autologin') || false),
    auth_result: true,
    user_ticker_running: false,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case INIT_ACCESS_CONTEXT_PAYLOAD: {
            return {
                ...state,
                accessContext: action.payload,
            };
        }
        case ENABLE_AUTH_STATUS: {
            return {
                ...state,
                auth_status: action.payload,
            };
        }
        case SAVE_AUTH_USER: {
            let extra = {}
            if (state.user && state.user.permission && state.user.method != 'cloudwallet') {
                extra = {
                    providerIndex: state.user.providerIndex,
                    method: state.user.method,
                    permission: state.user.permission,
                    publicKey: state.user.publicKey,
                }
            }
            // console.log('REDUCER AUTH SET IUSER', state.user, extra, action.payload);
            return {
                ...state,
                user: Object.assign(action.payload, extra),
                name: action.payload.account_name,
            };
        }
        case SAVE_WALLET: {
            return {
                ...state,
                eosio: action.payload
            };
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                name: null,
                user: null,
                initURL: '/marketplace',
                loader: false,
                eosio: {},
                method: null,
                auth_status: false,
            };
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            };
        }
        case LOADER_STATUS: {
            return {
                ...state,
                loader: action.payload,
            };
        }
        case SET_AUTO_LOGIN: {
            localStorage.setItem('autologin', action.payload);
            return {
                ...state,
                autologin: action.payload,
            };
        }
        case SET_LOGIN_RESULT: {
            return {
                ...state,
                auth_result: action.payload,
            };
        }

        case SET_USER_TICKER_RUNNING: {
            return {
                ...state,
                user_ticker_running: true,
            };
        }

        default:
            return state;
    }
}
