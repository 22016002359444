
import {
    LOAD_MARKET_ALL_SUCCESS,
    LOAD_MARKET_OFFERS_SUCCESS,
    LOAD_MARKET_AUCTIONS_SUCCESS,
    LOAD_MARKET_MORE,
    LOAD_AUCTIONS_MORE,
    LOAD_MARKET_ALL_MORE,
    REMOVE_MARKET_ITEM,
    SET_MARKET_FILTERS_SUCCESS,
    SET_MARKET_FILTERS_DATA,
    RESET_FILTERS_SUCCESS,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CLEAR_CART,
    LOAD_USD_TICKER,
    LOAD_USD_TICKER_SUCCESS,
    LOAD_HISTORY_LOG_SUCCESS,
    LOAD_HISTORY_LOG_MORE,
    LOAD_USD_TICKER_RUNNING,
}  from '../constants/ActionTypes.js';

const INIT_STATE = {
    all: [],
    offers: [],
    auctions: [],
    auctions_more: false,
    market_more: false,
    all_more: false,
    filters: {
        skip: 0,
        limit: 20,
    },
    filter_data: {
        authors: [],
        categories: [],
    },
    cart_items: [],
    usdticker: 0.02,
    ticker_running: false,
    history: [],
    history_more: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_MARKET_ALL_SUCCESS: {
            return {
                ...state,
                all: action.payload,
            };
        }
        case LOAD_MARKET_OFFERS_SUCCESS: {
            return {
                ...state,
                offers: action.payload,
            };
        }
        case LOAD_MARKET_AUCTIONS_SUCCESS: {
            return {
                ...state,
                auctions: action.payload,
            };
        }
        case LOAD_AUCTIONS_MORE: {
            return {
                ...state,
                auctions_more: action.payload,
            }
        }
        case LOAD_MARKET_MORE: {
            return {
                ...state,
                market_more: action.payload,
            }
        }
        case LOAD_MARKET_ALL_MORE: {
            return {
                ...state,
                all_more: action.payload,
            }
        }

        case REMOVE_MARKET_ITEM: {
            return {
                ...state,
                offers: state.offers.filter(item => item.assetid != action.payload.assetid),
                all: state.all.filter(item => item.assetid != action.payload.assetid),
                auctions: state.auctions.filter(item => item.assetid != action.payload.assetid),
            }
        }
        case SET_MARKET_FILTERS_SUCCESS: {
            return {
                ...state,
                filters: action.payload,
            }
        }
        case SET_MARKET_FILTERS_DATA: {
            return {
                ...state,
                filter_data: action.payload,
            }
        }
        case RESET_FILTERS_SUCCESS: {
            return {
                ...state,
                filters: INIT_STATE.filters,
            }
        }

        case ADD_TO_CART: {
            let cart_items = state.cart_items.filter(item => item.assetid != action.payload.assetid);
            cart_items.push(action.payload);
            return {
                ...state,
                cart_items: cart_items,
            }
        }
        case REMOVE_FROM_CART: {
            return {
                ...state,
                cart_items: state.cart_items.filter(item => item.assetid != action.payload.assetid),
            }
        }

        case CLEAR_CART: {
            return {
                ...state,
                cart_items: [],
            }
        }

        case LOAD_USD_TICKER_RUNNING: {
            return {
                ...state,
                ticker_running: true,
            }
        }
        case LOAD_USD_TICKER_SUCCESS: {
            return {
                ...state,
                usdticker: action.payload,
            }
        }
        case LOAD_HISTORY_LOG_SUCCESS: {
            return {
                ...state,
                history: action.payload,
            }
        }

        case LOAD_HISTORY_LOG_MORE: {
            return {
                ...state,
                history_more: action.payload,
            }
        }

        default:
            return state;
    }
}
