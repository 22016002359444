import React from 'react'
import { connect } from 'react-redux'
import * as wallet from '../../util/wallet';
// import ReactSpeedometer from 'react-d3-speedometer';
import { Link } from 'react-router-dom';
import {
  showModal,
  hideModal,
} from '../../actions/Modal';
import ItemButtons from './buttons'; //TODo: ofc
import {history} from '../../store';
import * as moment from 'moment';
import {Decimal} from 'decimal.js';
import * as config from '../../constants/config';
import ItemVisualProps from 'components/ItemVisualProps';

const bloks = config.bloks.host;

class Item extends React.Component {

  constructor(props) {
    super(props);

    this.redirectToAsset = this.redirectToAsset.bind(this);
  }

  redirectToAsset(asset) {
    history.push(`/nft/${asset.assetid || asset.id}`);
  }

  correctName(name) {
    name = name.replace('(Minimal Wear)', '');
    name = name.replace('(Battle-Scarred)', '');
    name = name.replace('(Well-Worn)', '');
    name = name.replace('(Field-Tested)', '');
    name = name.replace('(Factory New)', '');
    name = name.trim();
    if (name.length > 35) {
      name = name.substr(0, 32) + '...';
    }
    return name;
  }

  render() {
    let { item } = this.props;
    let {  asset, ...offer } = item;
    // let {asset, ...offer} = item;

    // if (!asset) {
    //   asset = item;
    // }
    // if (!offer) {
    //   offer = {};
    // }

    if (!asset) {
        asset = offer;
        offer = {};
    }
    if (!asset.mdata) {
      asset.mdata = {};
    }
    if (!asset.idata) {
      asset.idata = {};
    }
// console.log('ASSET', asset);
    asset.mdata = typeof asset.mdata != 'object' ? JSON.parse(asset.mdata) : asset.mdata
    asset.idata = typeof asset.idata != 'object' ? JSON.parse(asset.idata) : asset.idata

    let high_bid_only = false;
    if (offer.buy_now) {
      const buy_now = new Decimal(offer.buy_now.split(' ')[0]);
      const high_bid = new Decimal(offer.high_bid.split(' ')[0]);
      high_bid_only = buy_now.lte(high_bid)
    } else if (offer.high_bid) {
      high_bid_only = true;
    }

    let name = this.correctName(asset.name || asset.mdata.name || asset.idata.name);

    let image = asset.img || asset.mdata.img || asset.idata.img;
    if (image.length === 46 && image.substr(0,1) === 'Q') {
        image = 'https://ipfs.io/ipfs/' + image;
    } else if (image && -1 === image.indexOf('http') && image.substr(0,1) === 'Q') {
        image = 'https://ipfs.io/ipfs/' + image;
    }

    return (
      <li key={this.props.key} className={`${offer.expires_at > 0 ? 'item_extra_card' : 'items_card'}`}>
        <div className="items_background">
          <Link to={`/nft/${asset.assetid || asset.id}`}>
            <div
              className="items_image"
              style={{backgroundImage: `url(${image})`}}
              // onClick={() => this.redirectToAsset(asset)}
            />
          </Link>
          {/* {asset.unique && asset.unique.wear &&
            <ReactSpeedometer
              minValue={0}
              maxValue={1}
              value={0.5}
              width={200}
              height={200}
            />
          } */}
          <ItemVisualProps asset={asset} />
        </div>
        <div
          className="items_info"
          // onClick={() => this.redirectToAsset(asset)}
        >
          <div className="name_box">
            <h3>
                {name}
            </h3>
          </div>

          {offer.price &&
            <div className="price auction_price">
                <div className="left_price">
                </div>
                <div className="right_price">
                  {wallet.formatPrice(offer.price)}&nbsp;<span>WAX</span>
                  <br/>
                  <i>$&nbsp;{wallet.formatUsdPrice(wallet.formatPrice(offer.price), this.props.usdticker)}</i>
                </div>
            </div>
          }
          {offer.buy_now && !high_bid_only && (
            <div className="price auction_price">
              <div className="left_price">
                {wallet.formatPrice(offer.high_bid)}&nbsp;<span>WAX</span>
                <br/>
                <i>$&nbsp;{wallet.formatUsdPrice(wallet.formatPrice(offer.high_bid), this.props.usdticker)}</i>
              </div>
              <div className="right_price">
                {wallet.formatPrice(offer.buy_now)}&nbsp;<span>WAX</span>
                <br/>
                <i>$&nbsp;{wallet.formatUsdPrice(wallet.formatPrice(offer.buy_now), this.props.usdticker)}</i>
              </div>
            </div>
          )}
          {offer.high_bid && high_bid_only &&
            <div className='price auction_price'>
              <div className="left_price">
                {wallet.formatPrice(offer.high_bid)}&nbsp;<span>WAX</span>
                <br/>
                <i>$&nbsp;{wallet.formatUsdPrice(wallet.formatPrice(offer.high_bid), this.props.usdticker)}</i>
              </div>
              <div className="right_price">
                N / A
              </div>
            </div>
          }

        </div>
        <ItemButtons item={item} high_bid_only={high_bid_only} />
        { offer.expires_at &&
          <div className="item_extra_info_mobile">
            <div className="text">
              Auction end:&nbsp;
              {moment(offer.expires_at).utc().format('DD-MM-YYYY h:mm a')}&nbsp;
              ({moment(offer.expires_at).utc().fromNow()})
              {/* {moment.unix(offer.expires_at).utc().format('DD-MM-YYYY h:mm a')}&nbsp;
              ({moment.unix(offer.expires_at).utc().fromNow()}) */}
              </div>
          </div>
        }
      </li>

    )
  }
}

const mapStateToProps = ({ market }) => {
  const {usdticker} = market;
  return {
    usdticker,
  }
};

export default connect(mapStateToProps, {
  showModal,
  hideModal,
})(Item);


