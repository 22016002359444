import Modal from 'react-modal';
import React from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';

import {
    showFiltersModal,
    hideFiltersModal,
    loadFiltersData,
    resetFilters,
} from '../../actions/Modal';

import {
    setInventoryActionFilters,
    setInventoryItemFilters,
} from '../../actions/Inventory';

import {
    setMarketFilters,
} from '../../actions/Market';


Modal.setAppElement('#app-site');

const initState = {
    // value: {
    //     min: 0,
    //     max: 100,
    // },
    value: {},
    author: '',
    category: '',
    sort_order: 'age_desc',
};

class ItemModal extends React.Component {
    constructor (props) {
        super(props);
        this.state = initState;

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleCallToAction = this.handleCallToAction.bind(this);
        this.onChangePrice = this.onChangePrice.bind(this);
        this.getFilterData = this.getFilterData.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    componentDidMount() {
        this.props.loadFiltersData(this.props.type);
    }

    categoryName(id) {
        switch(id) {
            case '............i':
                return 'WAX Digital Art';
            case '............1':
                return 'VGO';
            case '............g':
                return 'WAX Stickers';
            case '...........2':
                return 'Collectible Cards';
            default:
                return id;
        }
    }

    getFilterData(type) {
        let data = null;
        switch(type) {
            case 'market':
                data = this.props.filter_data;
                break;
            case 'inventory_action':
                data = this.props.filter_action_data;
                break;
            case 'inventory_items':
                data = this.props.filter_items_data;
                break;
        }
        if (!data) {
            return {
                authors: [],
                categories: [],
            }
        }
        return data;
    }

    handleCloseModal () {
        this.props.hideFiltersModal();
    }

    handleCallToAction (action) {
        switch(action) {
            case 'market':
                this.props.setMarketFilters(this.state);
                break;
            case 'inventory_action':
                this.props.setInventoryActionFilters(this.state);
                break;
            case 'inventory_items':
                this.props.setInventoryItemFilters(this.state);
                break;
            default:
                return;
        }
        this.handleCloseModal();
    }

    resetFilters() {
        this.setState(initState);
        this.setState({value: {}});
        this.props.resetFilters();
        this.handleCloseModal();
    }

    onChangePrice(event) {
        let value = this.state.value;
        value[event.target.name] = Number(event.target.value);
        this.setState({ value });
    }

    onChange(event) {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        this.setState({[fieldName]: fieldValue});
    }

    render () {
        const { type } = this.props;
        const filter_data = this.getFilterData(type);
        const sort_options = [
            {_id: 'age_desc', name: 'Age: Newest -> Oldest'},
            {_id: 'age_asc', name: 'Age: Oldest -> Newest'},
            {_id: 'price_asc', name: 'Price: Low -> High'},
            {_id: 'price_desc', name: 'Price: Hight -> Low'},
        ]

        let value = {};
        if (this.state.value && this.state.value.min) {
            value = this.state.value;
        } else if (filter_data && filter_data.value) {
            value = filter_data.value;
        } else {
            value = {
                min: 0,
                max: 1000,
            };
        }

        return (
            <Modal
                isOpen={this.props.isFilterModalOpen}
                contentLabel="Item Modal"
                onRequestClose={this.handleCloseModal}
                className="modal"
                overlayClassName="modal_overlay"
                center={true}
            >
                <div className="modal_container modal_filters">
                    <div className="modal_filters_header">
                        <h3>Filters</h3>
                    </div>
                    <div className="modal_fields">
                        {type == 'market' &&
                            <div className="price_input margin10-0">
                                <select
                                    name="sort_order"
                                    onChange={(e)=>this.onChange(e)}
                                    defaultValue={this.state.sort_order}
                                    // className="any-christ"
                                >
                                    {sort_options.map(option => {
                                            return <option
                                                    key={option._id}
                                                    value={option._id}
                                                >
                                                    {option.name}
                                                </option>;
                                    })}
                                </select>
                                <div className="author_input_label">
                                    <span>Sort Order</span>
                                </div>
                            </div>
                        }
                        {type == 'market' &&
                            <div className="price_range width-100p">
                                <div className="price_input margin10-0">
                                    <input
                                        placeholder="Minimal Price"
                                        name="min"
                                        onChange={(e) => this.onChangePrice(e)}
                                        autoComplete="off"
                                        defaultValue={this.state.value.min}
                                    />
                                    <div className="author_input_label">
                                        <span>WAX</span>
                                    </div>
                                </div>

                                <div className="price_input margin10-0">
                                    <input
                                        placeholder="Maximal Price"
                                        name="max"
                                        onChange={(e) => this.onChangePrice(e)}
                                        autoComplete="off"
                                        defaultValue={this.state.value.max}
                                    />
                                    <div className="author_input_label">
                                        <span>WAX</span>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="price_input margin10-0">
                            <select
                                name="author"
                                onChange={(e)=>this.onChange(e)}
                                defaultValue={this.state.author}
                                // className="any-christ"
                            >
                                <option value="">Select Author</option>
                                {filter_data.authors.map(author => {
                                        return <option
                                                key={author._id}
                                                value={author._id}
                                            >
                                                {author._id} ({author.count})
                                            </option>;
                                })}
                            </select>
                            <div className="author_input_label">
                                <span>Author</span>
                            </div>
                        </div>

                        <div className="price_input margin10-0">
                            <select
                                name="category"
                                onChange={(e)=>this.onChange(e)}
                                defaultValue={this.state.category}
                                // className="any-christ"
                            >
                                <option value="">Select Category</option>
                                {filter_data.categories.map(category => {
                                    return <option
                                                key={category._id}
                                                value={category._id}
                                            >
                                                {this.categoryName(category._id)} ({category.count})
                                            </option>;
                                })}
                            </select>
                            <div className="author_input_label">
                                <span>Category</span>
                            </div>
                        </div>

                        <div className="price_input margin10-0">
                            <select
                                name="limit"
                                onChange={(e)=>this.onChange(e)}
                                defaultValue={this.state.limit}
                                // className="any-christ"
                            >
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                })}
                            </select>
                            <div className="author_input_label">
                                <span>Per Page</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="modal_actions">
                    <button
                        className="btn btn_secondary btn_lrg_noradius"
                        onClick={() => this.resetFilters()}
                    >
                        RESET
                    </button>
                    <button
                        className="btn btn_primary btn_lrg_noradius"
                        onClick={() => this.handleCallToAction(type)}
                    >
                        SAVE
                    </button>
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = ({ modal, market, inventory }) => {
    const { isFilterModalOpen } = modal;
    const { filter_data } = market;
    const { filter_action_data, filter_items_data } = inventory;
    return {
        isFilterModalOpen,
        filter_data,
        filter_action_data,
        filter_items_data,
    }
};

export default connect(mapStateToProps, {
    hideFiltersModal,
    showFiltersModal,
    setInventoryActionFilters,
    setInventoryItemFilters,
    setMarketFilters,
    loadFiltersData,
    resetFilters,
})(ItemModal);
