import Modal from 'react-modal';
import React from 'react';
import { connect } from 'react-redux';
import { Input } from '@material-ui/core';

import {
    showModal,
    hideModal,
    setItemData,
} from '../../actions/Modal';


class Transfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onChange(event) {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        this.props.onChange(fieldName, fieldValue);
    }

    render() {
        return (
            <div className="modal_fields">
                <label htmlFor="to">
                    Name:
                </label>

                <div className="text_input">
                <input
                    id="to"
                    placeholder=""
                    name="to"
                    className="text"
                    onChange={(e) => this.onChange(e)}
                />
                </div>
                <label htmlFor="memo">
                    Memo:
                </label>

                <div className="text_input">
                <input
                    id="memo"
                    placeholder=""
                    name="memo"
                    className="text"
                    onChange={(e) => this.onChange(e)}
                />
                </div>
            </div>
        );
    }
}

const props = {};

const mapStateToProps = ({ modal }) => {
    const { isModalOpen, item, action, data } = modal;
    return {
        isModalOpen,
        item,
        action,
        data,
    }
};

export default connect(mapStateToProps, {
    setItemData,
})(Transfer);
