import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Items from '../../../components/Items';
import { Link } from 'react-router-dom';
import FilterModal from '../../../components/FilterModal';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollUpButton from 'react-scroll-up-button';
import {
    loadUserInAction,
} from '../../../actions/Inventory';
import {
    showFiltersModal,
    hideFiltersModal,
} from '../../../actions/Modal';

class InAction extends React.Component
{
    constructor(props) {
        super(props);

        this.loadMore = this.loadMore.bind(this);
        this.getHeaders = this.getHeaders.bind(this);
    }

    componentDidMount() {
        if (this.props.auth_status || this.props.autologin) {
            this.props.loadUserInAction();
        }
    }

    loadMore(page) {
        this.props.loadUserInAction({more: true});
    }

    getHeaders = () => {
        return (
            <Helmet>
                <title>Items In Action</title>
                <meta name="description" content="Check and manage your currently active offers on WAX inventory - WAX Marketplace."/>
            </Helmet>
        );
    }

    render() {
        const items = this.props.in_action;
        if (!this.props.auth_status && !this.props.autologin || !this.props.auth_result) {
            return (
                <div className="page-placeholder">
                    {this.getHeaders()}
                    Please <Link to="/login">Log In</Link>
                </div>
            );
        }

        if (!this.props.auth_status && this.props.loader) {
            return (
                <div key={1} className="loader-view loader-margin" style={{clear: 'both'}}>
                    <CircularProgress/>
                </div>
            );
        }

        if (!items || items.length === 0) {
            return (
                <div className="page-placeholder">
                    {this.getHeaders()}
                    No items in action found.
                </div>
            );
        }
        return (
            <section>
                {this.getHeaders()}
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMore}
                    hasMore={this.props.in_action_more}
                    loader={
                        <div key={1} className="loader-view" style={{clear: 'both'}}>
                            <CircularProgress/>
                        </div>
                    }
                    useWindow={true}
                >
                    <Items items={items}/>
                </InfiniteScroll>
                <ScrollUpButton />
                <FilterModal isOpen={this.props.isFilterModalOpen} type="inventory_action" />
            </section>
        );
    }
}


const mapStateToProps = ({ auth, inventory, modal }) => {
    const { in_action, in_action_more } = inventory;
    const { isFilterModalOpen } = modal;
    const { auth_status, autologin, loader, auth_result } = auth;
    return {
        in_action,
        in_action_more,
        isFilterModalOpen,
        auth_status,
        autologin,
        loader,
        auth_result,
    }
};

export default connect(mapStateToProps, {
    loadUserInAction,
    showFiltersModal,
    hideFiltersModal,
})(InAction);
