import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import marketSagas from './Market';
import itemSagas from './Item';
import inventorySagas from './Inventory';
import searchSagas from './Search';
import modalSagas from './Modal';
import contactSaga from './Contact';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        marketSagas(),
        itemSagas(),
        inventorySagas(),
        searchSagas(),
        modalSagas(),
        contactSaga(),
    ]);
}
