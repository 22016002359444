import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Modal from './Modal';
import Market from './Market';
import Item from './Item';
import Inventory from './Inventory';
import Search from './Search';


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  modal: Modal,
  market: Market,
  item: Item,
  inventory: Inventory,
  search: Search,
});
