
// MARKET
export const LOAD_MARKET_OFFERS = 'LOAD_MARKET_OFFERS';
export const LOAD_MARKET_OFFERS_SUCCESS = 'LOAD_MARKET_OFFERS_SUCCESS';
export const LOAD_MARKET_AUCTIONS = 'LOAD_MARKET_AUCTIONS';
export const LOAD_MARKET_AUCTIONS_SUCCESS = 'LOAD_MARKET_AUCTIONS_SUCCESS';
export const LOAD_MARKET_ALL = 'LOAD_MARKET_ALL';
export const LOAD_MARKET_ALL_SUCCESS = 'LOAD_MARKET_ALL_SUCCESS';
export const LOAD_AUCTIONS_MORE = 'LOAD_AUCTIONS_MORE';
export const LOAD_MARKET_MORE = 'LOAD_MARKET_MORE';
export const LOAD_MARKET_ALL_MORE = 'LOAD_MARKET_ALL_MORE';
export const LOAD_USER_ASSETS_MORE = 'LOAD_USER_ASSETS_MORE';
export const LOAD_USER_OFFERS_MORE = 'LOAD_USER_OFFERS_MORE';
export const LOAD_USER_TRANSFERS_MORE = 'LOAD_USER_TRANSFERS_MORE';
export const LOAD_USER_AUCTIONS = 'LOAD_USER_AUCTIONS';
export const LOAD_USER_AUCTIONS_SUCCESS = 'LOAD_USER_AUCTIONS_SUCCESS';
export const LOAD_USER_AUCTIONS_MORE = 'LOAD_USER_AUCTIONS_MORE';
export const CANCEL_MARKET_AUCTION = 'CANCEL_MARKET_AUCTION';
export const LOAD_USER_TRANSACTIONS = 'LOAD_USER_TRANSACTIONS';
export const LOAD_USER_TRANSACTIONS_SUCCESS = 'LOAD_USER_TRANSACTIONS_SUCCESS';
export const LOAD_USER_TRANSACTIONS_MORE = 'LOAD_USER_TRANSACTIONS_MORE';
export const LOAD_USER_INACTION = 'LOAD_USER_INACTION';
export const LOAD_USER_INACTION_SUCCESS = 'LOAD_USER_INACTION_SUCCESS';
export const LOAD_USER_INACTION_MORE = 'LOAD_USER_INACTION_MORE';
export const LOAD_NFT_LOGS_SUCCESS = 'LOAD_NFT_LOGS_SUCCESS';
export const REMOVE_MARKET_ITEM = 'REMOVE_MARKET_ITEM';
export const REMOVE_INVENTORY_ITEM = 'REMOVE_INVENTORY_ITEM';
export const LOAD_USD_TICKER = 'LOAD_USD_TICKER';
export const LOAD_USD_TICKER_SUCCESS = 'LOAD_USD_TICKER_SUCCESS';
export const LOAD_USD_TICKER_RUNNING = 'LOAD_USD_TICKER_RUNNING';
export const LOAD_HISTORY_LOG = 'LOAD_HISTORY_LOG';
export const LOAD_HISTORY_LOG_SUCCESS = 'LOAD_HISTORY_LOG_SUCCESS';
export const LOAD_HISTORY_LOG_MORE = 'LOAD_HISTORY_LOG_MORE';

export const SET_MARKET_FILTERS = 'SET_MARKET_FILTERS';
export const SET_MARKET_FILTERS_DATA = 'SET_MARKET_FILTERS_DATA';
export const SET_MARKET_FILTERS_SUCCESS = 'SET_MARKET_FILTERS_SUCCESS';
export const SET_INVENTORY_ACTION_FILTERS = 'SET_INVENTORY_ACTION_FILTERS';
export const SET_INVENTORY_ACTION_FILTERS_DATA = 'SET_INVENTORY_ACTION_FILTERS_DATA';
export const SET_INVENTORY_ACTION_FILTERS_SUCCESS = 'SET_INVENTORY_ACTION_FILTERS_SUCCESS';
export const SET_INVENTORY_ITEM_FILTERS = 'SET_INVENTORY_ITEM_FILTERS';
export const SET_INVENTORY_ITEM_FILTERS_DATA = 'SET_INVENTORY_ITEM_FILTERS_DATA';
export const SET_INVENTORY_ITEM_FILTERS_SUCCESS = 'SET_INVENTORY_ITEM_FILTERS_SUCCESS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_FILTERS_SUCCESS = 'RESET_FILTERS_SUCCESS';

export const SELECT_INVENTORY_ITEM = 'SELECT_INVENTORY_ITEM';
export const SET_ITEMS_PRICE = 'SET_ITEMS_PRICE';
export const RESET_SELECTED_INVENTORY_ITEMS = 'RESET_SELECTED_INVENTORY_ITEMS';

// SEARCH
export const SET_SEARCH_PHRASE = 'SET_SEARCH_PHRASE';
export const FETCH_SEARCH_SUGGESTIONS = 'FETCH_SEARCH_SUGGESTIONS';
export const CLEAR_SEARCH_SUGGESTIONS = 'CLEAR_SEARCH_SUGGESTIONS';
export const SUCCESS_SEARCH_SUGGESTIONS = 'SUCCESS_SEARCH_SUGGESTIONS';
export const MORE_SEARCH_SUGGESTIONS = 'MORE_SEARCH_SUGGESTIONS';

// CONTACT
export const SEND_FEEDBACK = 'SEND_FEEDBACK';

// ======================================

// MODAL
export const SHOW_MODAL = 'show_modal';
export const HIDE_MODAL = 'hide_modal';
export const SET_ITEM_MODAL = 'set_item_modal';
export const SET_ITEM_ACTION = 'set_item_action';
export const SET_ITEM_DATA = 'set_item_data';
export const SHOW_FILTER_MODAL = 'SHOW_FILTER_MODAL';
export const HIDE_FILTER_MODAL = 'HIDE_FILTER_MODAL';
export const LOAD_FILTER_DATA = 'LOAD_FILTER_DATA';


// ITEMS
export const SELL_ITEM = 'sell_item';
export const SELL_MULTIPLE_ITEMS = 'SELL_MULTIPLE_ITEMS';
export const TRANSFER_ITEM = 'transfer_item';
export const CANCEL_ITEM = 'cancel_item';
export const BUY_ITEM = 'buy_item';
export const BUY_NOW_AUCTION = 'BUY_NOW_AUCTION';
export const BID_AUCTION = 'BID_AUCTION';
export const LOAD_NFT_ASSET = 'LOAD_NFT_ASSET';
export const LOAD_NFT_ASSET_SUCCESS = 'LOAD_NFT_ASSET_SUCCESS';
export const SHOW_LOADING = 'SHOW_LOADING';
export const LOAD_NFT_MARKET_SUCCESS = 'LOAD_NFT_MARKET_SUCCESS';
export const CANCEL_MARKET_OFFER = 'CANCEL_MARKET_OFFER';
export const LOAD_USER_TRANSFERS = 'LOAD_USER_TRANSFERS';
export const LOAD_USER_TRANSFERS_SUCCESS = 'LOAD_USER_TRANSFERS_SUCCESS';
export const CLAIM_TRANSFER = 'CLAIM_TRANSFER';
export const CLAIM_TRANSFERS = 'CLAIM_TRANSFERS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const PURCHASE_CART_ITEMS = 'PURCHASE_CART_ITEMS';
export const CLEAR_CART = 'CLEAR_CART';

//WALLET
export const LOAD_ASSETS = 'load_assets';
export const LOAD_ASSETS_SUCCESS = 'load_assets_success';
export const SET_MENU = 'set_menu';
export const LOAD_USER_OFFERS = 'load_user_offers';
export const LOAD_USER_OFFERS_SUCCESS = 'load_user_offers_success';
export const SET_AUCTION = 'set_auction';
export const LOAD_AUCTIONS = 'load_auctions';
export const LOAD_AUCTIONS_SUCCESS = 'load_auctions_success';
export const CLOSE_AUCTION = 'CLOSE_AUCTION';

// Offers
export const LOAD_OFFERS = 'load_offers';
export const LOAD_OFFERS_SUCCESS = 'load_offers_success';

// Inventory
export const LOAD_INVENTORY = 'LOAD_INVENTORY';
export const LOAD_INVENTORY_SUCCESS = 'LOAD_INVENTORY_SUCCESS';
export const LOAD_VAULT = 'LOAD_VAULT';
export const LOAD_VAULT_SUCCESS = 'LOAD_VAULT_SUCCESS';
export const TRASNFER_TO_INVENTORY = 'TRASNFER_TO_INVENTORY';
export const CHANGE_INVENTORY_TAB = 'CHANGE_INVENTORY_TAB';

//Auth const
export const INIT_ACCESS_CONTEXT_PAYLOAD = 'INIT_ACCESS_CONTEXT_PAYLOAD';
export const INIT_ACCESS_CONTEXT = 'INIT_ACCESS_CONTEXT';
export const ACCESS_CONTEXT = 'ACCESS_CONTEXT'; // accessContext
export const SIGNIN_TRANSIT = 'SIGNIN_TRANSIT';
export const SAVE_AUTH_USER = 'SAVE_AUTH_USER';
export const ENABLE_AUTH_STATUS = 'ENABLE_AUTH_STATUS';
export const SET_AUTO_LOGIN = 'SET_AUTO_LOGIN';
export const SET_LOGIN_RESULT = 'SET_LOGIN_RESULT';
export const SAVE_WALLET = 'SAVE_WALLET'
export const SIGNIN_WAX = 'signin_wax';
export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_TICKER = 'LOAD_USER_TICKER';
export const SET_USER_TICKER_RUNNING = 'SET_USER_TICKER_RUNNING';
// export const SET_USER_TICKER_RUNNING_SUCCESS = 'SET_USER_TICKER_RUNNING_SUCCESS';

export const LOADER_STATUS = 'LOADER_STATUS';
// export const SIGNOUT_USER = 'SIGNOUT_USER'

// GLOBAL
export const SHOW_SUCCESS_MESSAGE = 'show_success_message';
export const SHOW_ERROR_MESSAGE = 'show_error_message';
// export const HIDE_MESSAGE

// //Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
// export const SIGNIN_GOOGLE_USER = 'signin_google_user';
// export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
// export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
// export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
// export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
// export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
// export const SIGNIN_GITHUB_USER = 'signin_github_user';
// export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';



/// ============== NOT MINE ========


// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';


// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

