import Modal from 'react-modal';
import React from 'react';
import { connect } from 'react-redux';
import { Input } from '@material-ui/core';
import calendar from '../../assets/images/item/calendar@3x.png';
import {
    setItemData,
} from '../../actions/Modal';


class Auction extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      buy_now: false,
    }

    this.boxOnChange = this.boxOnChange.bind(this);
  }

  onChange(event) {
      // TODO: validation: check if its proper number !
      const fieldName = event.target.name;
      const fieldValue = event.target.value;

      this.props.onChange(fieldName, fieldValue);
  }

  boxOnChange(event) {
    this.setState({buy_now : event.target.checked });
  }

  render () {
    return (
      <div className="modal_fields">
        <label htmlFor="price">
            Starting price:
        </label>

        <div className="price_input">
          <input
            placeholder="0.00"
            // value={price}
            name="high_bid"
            onChange={(e) => this.onChange(e)}
            autoComplete="off"
          />
          <div className="wax_input_label">
            <span>WAX</span>
          </div>
        </div>

        <div className="modal_checkbox">
          <label htmlFor="buy_now_box" className="checkbox_label">
            <input
                id="buy_now_box"
                type="checkbox"
                name="buy_now_box"
                className="checkbox"
                checked={this.state.buy_now}
                onChange={this.boxOnChange}
            />
            <span className="slider"></span>
            Set buy now price
          </label>
        </div>

        <label htmlFor="price" style={this.state.buy_now ? {} : {display: 'none'}}>
            Buy now price:
        </label>

        <div className="price_input" style={this.state.buy_now ? {} : {display: 'none'}}>
          <input
            placeholder="0.00"
            name="buy_now"
            onChange={(e) => this.onChange(e)}
            autoComplete="off"
          />
          <div className="wax_input_label">
            <span>WAX</span>
          </div>
        </div>

        <label htmlFor="expires_at">
            {/* End date: */}
            Ending in:
        </label>

        <div className="price_input">
          <select
            name="expires_at"
            onChange={(e)=>this.onChange(e)}
            defaultValue={14}
          >
            <option value="1">1 day</option>
            <option value="3">3 days</option>
            <option value="5">5 days</option>
            <option value="7">7 days</option>
            <option value="14">14 days</option>
            <option value="30">30 days</option>
          </select>
          <div className="wax_input_label">
            <span><img className="input_icon" src={calendar}/></span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ modal }) => {

    const { isModalOpen, item, action, data} = modal;
    return {
        isModalOpen,
        item,
        action,
        data,
    }
};

export default connect(mapStateToProps, {
    setItemData,
})(Auction);
