import {all, call, select, fork, put, takeEvery, takeLatest, debounce, throttle} from 'redux-saga/effects';
import {
    setMarketFiltersData,
    setMarketFiltersSuccess,
    loadMarketAll,
    loadMarketOffers,
    loadMarketAuctions,
} from '../actions/Market';
import {
    resetFiltersSuccess
} from '../actions/Modal';
import {
    loadUserAssets,
    loadUserInAction,
    setInventoryItemFiltersData,
    setInventoryItemFiltersSuccess,
    setInventoryActionFiltersData,
    setInventoryActionFiltersSuccess,
} from '../actions/Inventory';
import {
    LOAD_FILTER_DATA,
    SET_MARKET_FILTERS,
    SET_INVENTORY_ACTION_FILTERS,
    SET_INVENTORY_ITEM_FILTERS,
    RESET_FILTERS,
} from 'constants/ActionTypes';


import * as config from '../constants/config';
// import * as wallet from '../util/wallet';
import * as api from '../util/api';
import { toast } from 'react-toastify';
import { authorize } from './Auth';



// =======================
// =======================
// ======================= LOAD
// =======================
// =======================

function* loadFilterData({payload}) {
    try {
        let account = null;
        if (payload == 'inventory_action' || payload == 'inventory_items') {
            const auth = yield call(authorize, {});
            if (!auth) {
                yield call(toast.error, 'Please log in');
                return;
            }
            account = auth.user.account_name;
        }
        const data = yield call(api.getFiltersData, payload, account);

        switch(payload) {
            case 'market':
                yield put(setMarketFiltersData(data));
                // FUCK !
                // yield put(loadMarketAll());
                // yield put(loadMarketOffers());
                // yield put(loadMarketAuctions());
                break;
            case 'inventory_action':
                yield put(setInventoryActionFiltersData(data));
                // yield put(loadUserInAction());
                break;
            case 'inventory_items':
                yield put(setInventoryItemFiltersData(data));
                // yield put(loadUserAssets());
                break;
        }
    } catch (e) {
        if (config.debug){
            console.error('get market auctions', e);
        }
        yield call(toast.error, e.message);
    }
}

export function* loadFilterDataSaga() {
    yield takeLatest(LOAD_FILTER_DATA, loadFilterData);
}

// =======================
// =======================
// ======================= ACTION
// =======================
// =======================

function* setMarketFilter({payload}) {
    try {
        const filters = yield select(state => state.market.filters);
        const filter = payload;

        const data = yield call(api.prepareFilters, filters, filter);

        yield put(setMarketFiltersSuccess(data));

        const path = window.location.pathname;
        if (path === '/' || path === '/marketplace' || path === '/marketplace/all') {
            yield put(loadMarketAll());
        } else if (path === '/marketplace/offers') {
            yield put(loadMarketOffers());
        } else if (path === '/marketplace/auctions') {
            yield put(loadMarketAuctions());
        } else {
            yield put(loadMarketAll());
            yield put(loadMarketOffers());
            yield put(loadMarketAuctions());
        }
    } catch (e) {
        if (config.debug) {
            console.error('set market filters', e);
        }
        yield call(toast.error, e.message);
    }
}

function* setInventoryActionFilters({payload}) {
    try {
        const filters = yield select(state => state.inventory.filters_action);
        const filter = payload;

        const data = yield call(api.prepareFilters, filters, filter);

        yield put(setInventoryActionFiltersSuccess(data));
        yield put(loadUserInAction());
    } catch (e) {
        if (config.debug) {
            console.error('set inventory action filters', e);
        }
        yield call(toast.error, e.message);
    }
}

function* setInventoryItemFilters({payload}) {
    try {
        const filters = yield select(state => state.inventory.filters_items);
        const filter = payload;

        const data = yield call(api.prepareFilters, filters, filter);

        yield put(setInventoryItemFiltersSuccess(data));
        yield put(loadUserAssets());

    } catch (e) {
        if (config.debug) {
            console.error('set inventory item filters', e);
        }
        yield call(toast.error, e.message);
    }
}

function* resetFilters() {
    try {
        yield put(resetFiltersSuccess());

        // OH GOD
        yield put(loadMarketAll());
        yield put(loadMarketOffers());
        yield put(loadMarketAuctions());
        yield put(loadUserInAction());
        yield put(loadUserAssets());

    } catch (e) {
        if (config.debug) {
            console.error('reset filters', e);
        }
        yield call(toast.error, e.message);
    }
}

export function* setMarketFilterSaga() {
    yield takeLatest(SET_MARKET_FILTERS, setMarketFilter);
}
export function* setInventoryActionFiltersSaga() {
    yield takeLatest(SET_INVENTORY_ACTION_FILTERS, setInventoryActionFilters);
}
export function* setInventoryItemFiltersSaga() {
    yield takeLatest(SET_INVENTORY_ITEM_FILTERS, setInventoryItemFilters);
}
export function* resetFiltersSaga() {
    yield takeLatest(RESET_FILTERS, resetFilters);
}

// =======================
// =======================
// ======================= SAGA
// =======================
// =======================




export default function* rootSaga() {
    yield all([
        fork(resetFiltersSaga),
        fork(setMarketFilterSaga),
        fork(loadFilterDataSaga),
        fork(setInventoryActionFiltersSaga),
        fork(setInventoryItemFiltersSaga),
    ]);
}