import React from 'react';
import {Route, Switch, withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
// import Tour from '../components/Tour/index';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import {
  getUsdTicker,
  loadUserTicker,
} from 'actions';
import {isIOS, isMobile} from 'react-device-detect';
import {history} from '../store';
import TopNav from 'components/TopNav';
import Marketplace from './routes/marketplace';
import Trade from './routes/trade';
import Inventory from './routes/inventory';
import Contact from './routes/contact';
import Cart from './routes/cart';
import NFT from './routes/nft';
import Transfers from './routes/transfers';
import Error404 from 'components/Error404';
import shopping_cart_ico from '../assets/images/item/cart@3x.png';

import Search from 'components/Search';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.getPageHeader = this.getPageHeader.bind(this);
    this.goToCart = this.goToCart.bind(this);
  }

  componentDidMount() {
    // if (!this.props.ticker_running) {
      this.props.getUsdTicker();
    // }
    this.props.loadUserTicker();
    // if (this.props.autologin) {

    // }
  }

  getPageHeader() {
    // console.log('GET PAGE HEADER', window.location.pathname, this.props.match, this.props.match.url === '/app' && this.props.match.isExact);
    if (-1 != window.location.pathname.indexOf('/marketplace')) {
      return 'Marketplace';
    } else if (window.location.pathname === '/') {
      return 'Marketplace';
    } else if (-1 != window.location.pathname.indexOf('/inventory')) {
      return 'Inventory';
    }
    // else if (-1 != window.location.pathname.indexOf('/transfers')) {
    //   return 'Transfers';
    // }
    return;
    // switch() {
    //   case '/app'
    // }
    // console.log();
  }

  goToCart = (e) => {
    history.push('/cart');
  }

  render() {
    const {match, drawerType, navigationStyle, horizontalNavPosition, cart_items} = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour/> */}

        <Sidebar/>
        <div className="app-main-container">
          {/* <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
            <TopNav styleName="app-top-header"/>} */}
            <Header/>
            {/* {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
            <TopNav/>}
          </div> */}
          {/* console.log(this.getPageHeader()) */}
          {/* {console.log('getPageHeader', this.getPageHeader())} */}

          {/* {this.getPageHeader() != null &&
            <div className="search_wrapper">
              <h1 className="page_header">{this.getPageHeader()}</h1>
              <Search />
            </div>
          } */}

          <main className="app-main-content-wrapper">
            <Link to="/cart">
              <img
                className="shopping_cart_ico cart_main_page"
                src={shopping_cart_ico}
                alt="Shopping Cart"
                // onClick={(e) => this.goToCart()}
              />
            </Link>
            <div className="cart_items_counter cart_main_page">
              {cart_items.length ? cart_items.length : 0}
            </div>

            {this.getPageHeader() != null &&
              <div className="search_wrapper">
                <h1 className="page_header">{this.getPageHeader()}</h1>
                <Search />
              </div>
            }

            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}marketplace`} component={Marketplace}/>
                <Route path={`${match.url}trade`} component={Trade}/>
                <Route path={`${match.url}inventory`} component={Inventory}/>
                <Route path={`${match.url}cart`} component={Cart}/>
                <Route path={`${match.url}contact`} component={Contact}/>
                <Route path={`${match.url}nft/:id`} component={NFT}/>
                <Route path={`${match.url}transfers`} component={Transfers}/>
                <Route exact path={`${match.url}`} component={Marketplace}/>
                <Route component={Error404}/>
              </Switch>
            </div>
            <Footer/>
          </main>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({settings, market, auth}) => {
  const {drawerType, navigationStyle, horizontalNavPosition} = settings;
  const { cart_items, ticker_running } = market;
  const {autologin} = auth;
  return {drawerType, navigationStyle, horizontalNavPosition, cart_items, ticker_running, autologin }
};
export default withRouter(connect(mapStateToProps, {
    getUsdTicker,
    loadUserTicker,
})(App));