import React from 'react';
import { connect } from 'react-redux';
import dots from '../../assets/images/item/dots@3x.png';
import add_cart_ico from '../../assets/images/item/add_cart@3x.png';
import {
    showModal,
    hideModal,
    setItem,
    setAction,
} from '../../actions/Modal';

import {
    buyItem,
    buyAuction,
    bidAuction,
    cancelMarketOffer,
    cancelMarketAuction,
    closeAuction,
    addToCart,
} from '../../actions/Market';


class ItemButtons extends React.Component
{
    handleOpenModal() {
        this.props.showModal();
    }

    handleCloseModal() {
        this.props.hideModal();
    }

    transfer(item) {
        this.props.showModal({action: 'transfer', item});
    }

    sell(item) {
        this.props.showModal({action: 'sell', item: item});
    }

    bid(item) {
        this.props.showModal({action: 'bid', item: item});
    }

    // closeAuction(item) {
    //     this.
    // }

    cancel(item) {
        this.props.cancelMarketOffer(item);
    }

    // addToCart = (item) => {

    // }

    getActions(item) {
        // console.log(item);
        let {asset, ...offer} = item;

        // console.log('BUTTONS asset', asset);
        // console.log('BUTTONS offer', offer);

        // let asset = item;
        // let offer = item.market ? item.market : {};
        if (!asset) {
            asset = offer;
            offer = {};
        }
        // if (item.offer && item.offer.id) {
        //     offer = item.offer;
        // } else if (!item.offer || !item.market) {
        //     offer = {};
        // }

        // if (item.id) {
        //     asset = item
        // }
        // console.log('BUTTONS', item);
        // console.log('BUTTONS 2 asset', asset);
        // console.log('BUTTONS 2 offer', offer);


        // MARKET CLOSED AUCTIONS
        if (offer && offer.high_bid && new Date(offer.expires_at).getTime() < new Date().getTime()) {
            return (
                <div className="items_actions">
                    <button className="btn btn_cancel btn_lrg btn_font_sm" onClick={() => this.props.closeAuction(item)}>
                        CLOSE AUCTION
                    </button>
                </div>
            );
        }
        // USER MARKET AUCTION
        if (offer && offer.high_bid && this.props.user && offer.owner == this.props.user.account_name) {
            return (
                <div className="items_actions">
                    <button
                        className={`btn btn_cancel btn_lrg btn_font_sm ${offer.high_bid && offer.bidder ? 'not-allowed' : ''}`}
                        onClick={() => this.props.cancelMarketAuction(item)}
                        disabled={offer.high_bid && offer.bidder }
                    >
                        CANCEL AUCTION
                    </button>
                </div>
            );
        }

        // USER MARKET OFFER
        if (offer && this.props.user && offer.owner == this.props.user.account_name) {
            return (
                <div className="items_actions">
                    <button
                        className={`btn btn_cancel btn_lrg btn_font_sm ${offer.high_bid && offer.bidder  ? 'not-allowed' : ''}`}
                        onClick={() => this.cancel(item)}
                        disabled={offer.high_bid && offer.bidder }
                    >
                        CANCEL OFFER
                    </button>
                </div>
            );
        }

        // MARKET OFFERS
        if (offer && offer.price) {
            return (
                <div className="items_actions">
                    <button className="btn btn_secondary" onClick={() => this.props.addToCart(item)}>
                        <img className="cart_icon" src={add_cart_ico}/>
                    </button>
                    <button className="btn btn_primary btn_font_sm" onClick={() => this.props.buyItem(item)}>
                        BUY NOW
                    </button>
                </div>
            );
        }

        // MARKET AUCTIONS
        if (offer && offer.high_bid) {
            return (
                <div className="items_actions">
                    <button className="btn btn_secondary btn_even btn_font_sm" onClick={() => this.bid(item)}>
                        PLACE A BID
                    </button>
                    <button
                        className={`btn btn_primary btn_even btn_font_sm ${this.props.high_bid_only ? 'not-allowed' : ''}`}
                        onClick={() => this.props.buyAuction(item)}
                        disabled={this.props.high_bid_only || false}
                    >
                        BUY NOW
                    </button>
                </div>
            );
        }
        // USER ASSET
        if (!offer || !offer.id && this.props.user && asset.owner == this.props.user.account_name) {
            return (
                <div className="items_actions">
                    {/* <button className="btn btn_secondary" onClick={() => this.sell(item)}>
                        <ShoppingBasket />
                    </button> */}

                    <button className="btn btn_secondary btn_font_sm" onClick={() => this.transfer(item)}>
                        <img className="icon" src={dots}/>
                    </button>
                    <button className="btn btn_primary btn_font_sm" onClick={() => this.sell(item)}>SELL</button>
                </div>
            );
        }

        return (
            <div></div>
        )
    }

    render() {
        const { item } = this.props
        // let { asset, offer } = item;

        return this.getActions(item);
    }
}


const mapStateToProps = ({ auth, vault }) => {
    const { user } = auth;
    return {
        user,
    }
};

export default connect(mapStateToProps, {
    showModal,
    hideModal,
    setItem,
    setAction,
    buyItem,
    buyAuction,
    bidAuction,
    cancelMarketOffer,
    cancelMarketAuction,
    closeAuction,
    addToCart,
})(ItemButtons);


