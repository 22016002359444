import Modal from 'react-modal';
import React from 'react';
import { connect } from 'react-redux';
import { Input } from '@material-ui/core';
import {
    setItemData,
} from '../../actions/Modal';
import {Decimal} from 'decimal.js'

class BidAuction extends React.Component {
  constructor (props) {
    super(props);
  }

  onChange(event) {
      // TODO: validation: check if its proper number !

      const fieldName = event.target.name;
      const fieldValue = event.target.value;
      this.props.onChange(fieldName, fieldValue);
  }

  setInitialBid(bid) {
    this.props.onChange('bid', bid);
  }

  render () {
    let high_bid = null;
    try {
      high_bid = new Decimal(this.props.item.offer.high_bid.split(' ')[0]);
      high_bid = high_bid.add(high_bid.div(10));
      this.setInitialBid(high_bid);
    } catch(e) {
      high_bid = null;
    }

    return (
      <div className="modal_fields">
        <label htmlFor="price">
            Bid Price:
        </label>

        <div className="price_input">
          <input
            placeholder={high_bid ? high_bid : '0.00'}
            name="bid"
            onChange={(e) => this.onChange(e)}
            autoComplete="off"
          />
          <div className="wax_input_label">
            <span>WAX</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ modal }) => {
    const { isModalOpen, action, data} = modal;
    return {
        isModalOpen,
        action,
        data,
    }
};

export default connect(mapStateToProps, {
    setItemData,
})(BidAuction);
