import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Items from '../../../components/Items';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollUpButton from 'react-scroll-up-button';
import {
    loadMarketAuctions
} from '../../../actions/Market.js';


class Auctions extends React.Component
{
    constructor(props) {
        super(props);

        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
        this.props.loadMarketAuctions();
    }

    loadMore = () => {
        this.props.loadMarketAuctions({more: true});
    }

    render() {
        const items = this.props.auctions;
        return (
            <section>
                <Helmet>
                    <title>Auctions</title>
                    <meta name="description" content="Check out what WAX inventory - WAX Marketplace has to offer and choose something for yourself!"/>
                </Helmet>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMore}
                    hasMore={this.props.auctions_more}
                    loader={
                        <div key={1} className="loader-view" style={{clear: 'both'}}>
                            <CircularProgress/>
                        </div>
                    }
                    useWindow={true}
                >
                    <Items items={items}/>
                </InfiniteScroll>
                <ScrollUpButton />
            </section>
        );
    }
}


const mapStateToProps = ({ market }) => {
    const { auctions, auctions_more } = market;
    return {
        auctions,
        auctions_more,
    }
};

export default connect(mapStateToProps, {
    loadMarketAuctions,
})(Auctions);
