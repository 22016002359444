import {all, call, select, fork, put, takeEvery, takeLatest, debounce, throttle} from 'redux-saga/effects';
import {
    SEND_FEEDBACK
} from 'constants/ActionTypes';


// import * as config from '../constants/config';
// import * as wallet from '../util/wallet';
import * as api from '../util/api';
import { toast } from 'react-toastify';
// import { authorize } from './Auth';



// =======================
// =======================
// ======================= LOAD
// =======================
// =======================


// =======================
// =======================
// ======================= ACTION
// =======================
// =======================

function* sendFeedback({payload}) {
    console.log(payload);
    if (!payload || !payload.email || !payload.name || !payload.message || !payload.subject) {
        yield call(toast.error, 'Invalid Form.');
        return;
    }
    try {
        yield call(api.sendFeedback, payload);
        yield call(toast.success, 'Thank you for yor feedback!');
    } catch (e) {
        yield call(toast.error, e.response ? e.response.data : e.message);
    }
}

// =======================
// =======================
// ======================= SAGA
// =======================
// =======================

export function* sendFeedbackSaga() {
    yield takeLatest(SEND_FEEDBACK, sendFeedback);
}



export default function* rootSaga() {
    yield all([
        fork(sendFeedbackSaga),
    ]);
}