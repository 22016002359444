import React from 'react'
import { connect } from 'react-redux'
// import * as wallet from '../../util/wallet';
// // import ReactSpeedometer from 'react-d3-speedometer';
// import {
//   showModal,
//   hideModal,
// } from '../../actions/Modal';
// import ItemButtons from './buttons'; //TODo: ofc
// import {history} from '../../store';
// import * as moment from 'moment';
// import {Decimal} from 'decimal.js';
// import * as config from '../../constants/config';
// import ItemVisualProps from 'components/ItemVisualProps';

import History from './history';
// const bloks = config.bloks.host;

class HistoryLog extends React.Component {

  constructor(props) {
    super(props);

    // this.redirectToAsset = this.redirectToAsset.bind(this);
  }

//   redirectToAsset(asset) {
//     history.push(`/nft/${asset.assetid || asset.id}`);
//   }

//   correctName(name) {
//     name = name.replace('(Minimal Wear)', '');
//     name = name.replace('(Battle-Scarred)', '');
//     name = name.replace('(Well-Worn)', '');
//     name = name.replace('(Field-Tested)', '');
//     name = name.replace('(Factory New)', '');
//     name = name.trim();
//     if (name.length > 35) {
//       name = name.substr(0, 32) + '...';
//     }
//     return name;
//   }

  render() {
      const history = this.props.history;

      return (
        <div className="history_logs transactions">
            <ul>
                {history && history.map((item, idx) => (
                    <History item={item} key={idx} />
                ))}
            </ul>
        </div>
      );
  }
}

const mapStateToProps = ({ market }) => {
    const {history} = market;
  return {
      history,
  }
};

export default connect(mapStateToProps, {

})(HistoryLog);


