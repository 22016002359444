import React from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { Helmet } from 'react-helmet';
import {
    loadUserTransfers,
    claimTransfer,
    claimTransfers,
} from '../../../actions/Inventory.js';

class Transfers extends React.Component
{
    componentDidMount() {
        this.props.loadUserTransfers();
    }

    render() {
        const { transfers, transfers_more } = this.props;

        return (
            <div className="app-wrapper transfers">
                <Helmet>
                    <title>My Transfers</title>
                    <meta name="description" content="Accept the pending item transfers sent to you on WAX inventory - Wax Marketplace"/>
                </Helmet>
                <div className="container">
                    <h1>Transfers</h1>
                    { transfers && Array.isArray(transfers) && transfers.length > 0 &&
                    <div className="claim_transfers_all">
                        <button
                            className="btn btn_primary btn_claim btn_claim_wider"
                            onClick={() => this.props.claimTransfers(transfers.map(ele => ele.asset.id))}
                        >
                            CLAIM {transfers ? transfers.length : 0} items
                        </button>
                    </div>
                    }
                    { transfers && Array.isArray(transfers) && transfers.length > 0 &&
                        transfers.map((ele, idx) => {
                            const { asset, transfer } = ele;
                            if (!asset.mdata) {
                                asset.mdata = {};
                            }
                            if (!asset.idata) {
                                asset.idata = {};
                            }
                            asset.mdata = typeof asset.mdata != 'object' ? JSON.parse(asset.mdata) : asset.mdata
                            asset.idata = typeof asset.idata != 'object' ? JSON.parse(asset.idata) : asset.idata
                            let image = asset.img || asset.idata.img || asset.mdata.img;
                            if (image && image.length === 46 && image.substr(0,1) === 'Q') {
                                image = 'https://ipfs.io/ipfs/' + image;
                            } else if (image && -1 === image.indexOf('http') && image.substr(0,1) === 'Q') {
                                image = 'https://ipfs.io/ipfs/' + image;
                            }
                            return (
                                <div key={idx} className="item_transfer">
                                    <div className="item_background">
                                        <div className="item_image" style={{backgroundImage: `url(${image})`}} />
                                    </div>
                                    <div className="item_desc">
                                        <h2>
                                            {asset.idata.name || asset.mdata.name || 'missing name'}
                                        </h2>
                                        <h4><span>Author:</span>&nbsp;<a>{asset.author}</a></h4>
                                        <h4>
                                            {moment.unix(transfer.cdate).utc().format('DD-MM-YYYY h:mm a')}
                                        </h4>
                                    </div>
                                    <div className="item_action">
                                        <button
                                            className="btn btn_primary btn_claim"
                                            onClick={() => this.props.claimTransfer(ele)}
                                        >
                                            CLAIM
                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    }
                    { transfers_more &&
                        <div className="loadMore">
                            <button className="btn btn_primary" onClick={(e) => this.props.loadUserTransfers({more: true})}>LOAD MORE</button>
                        </div>
                    }
                    { (!transfers || transfers.length == 0) &&
                        <div className="page-placeholder">No incoming transfers.</div>
                    }
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({ inventory }) => {
    // const { isModalOpen } = modal;
    const { transfers, transfers_more } = inventory;
    return {
        transfers,
        transfers_more
    }
};

export default connect(mapStateToProps, {
    // hideModal,
    loadUserTransfers,
    claimTransfer,
    claimTransfers,
})(Transfers);
