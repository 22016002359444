import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as wallet from '../../../util/wallet';
import {Decimal} from 'decimal.js';
import add_cart_ico from '../../../assets/images/item/add_cart@3x.png';
import {
    showModal,
    hideModal,
} from '../../../actions/Modal';

import {
    buyItem,
    buyAuction,
    bidAuction,
    cancelMarketOffer,
    cancelMarketAuction,
    closeAuction,
    addToCart,
} from '../../../actions/Market.js';


class MarketItemButtons extends React.Component
{
    handleOpenModal() {
        this.props.showModal();
    }

    handleCloseModal() {
        this.props.hideModal();
    }

    bidAuction(item) {
        this.props.showModal({action: 'bid', item: item});
    }

    getActions(item) {
        const asset = this.props.asset;
        const offer = asset ? asset.market : null;
        // const offer = this.props.offer;

        let high_bid_only = false;
        if (offer && offer.buy_now) {
            const buy_now = new Decimal(offer.buy_now.split(' ')[0]);
            const high_bid = new Decimal(offer.high_bid.split(' ')[0]);
            high_bid_only = buy_now.lte(high_bid)
        } else if (offer && offer.high_bid) {
            high_bid_only = true;
        }

        // USER MARKET OFFER
        if (offer && offer.price && this.props.user && offer.owner == this.props.user.account_name) {
            return (
                <div className="item_info_action">
                    <div className="item_info_price">
                        <label>Price</label>
                        <p>
                            {wallet.formatPrice(offer.price)}&nbsp;<span>WAX</span>
                        </p>
                    </div>
                    <div className="item_info_buttons">
                        <button
                            className="btn btn_cancel btn_left_noradius btn_item_page_bg"
                            onClick={() => this.props.cancelMarketOffer({asset: asset, ...offer})}
                        >
                            CANCEL OFFER
                        </button>
                    </div>
                </div>
            );
        }

        // EXPIRED AUCTION
        if (offer && offer.high_bid && new Date(offer.expires_at).getTime() < new Date().getTime()) {
            return (
                <div className="item_info_action">
                    <div className="item_info_price">
                        <label>Best offer</label>
                        <p>
                            {wallet.formatPrice(offer.high_bid)}&nbsp;<span>WAX</span>
                        </p>
                    </div>
                    <div className="item_info_buttons">
                        <button
                            className="btn btn_cancel btn_left_noradius btn_item_page_bg"
                            onClick={() => this.props.closeAuction({asset: asset, ...offer})}
                        >
                            CLOSE AUCTION
                        </button>
                    </div>
                </div>
            );
        }

        // USER AUCTION OFFER
        if (offer && offer.high_bid && this.props.user && offer.owner == this.props.user.account_name) {
            return (
                <div className="item_info_action">
                    <div className="item_info_price">
                        <label>Best offer</label>
                        <p>
                            {wallet.formatPrice(offer.high_bid)}&nbsp;<span>WAX</span>
                        </p>
                    </div>
                    { offer.buy_now && !high_bid_only &&
                        <div className="item_info_price">
                            <label>Buy now</label>
                            <p>
                                {wallet.formatPrice(offer.buy_now)}&nbsp;<span>WAX</span>
                            </p>
                        </div>
                    }
                    <div className="item_info_buttons">
                        <button
                            className={`btn btn_cancel btn_left_noradius btn_item_page_bg ${offer.bidder ? 'not-allowed' : ''}`}
                            onClick={() => this.props.cancelMarketAuction({asset: asset, ...offer})}
                            disabled={offer.bidder}
                        >
                            CANCEL AUCTION
                        </button>
                    </div>
                </div>
            );
        }

        // MARKET OFFERS
        if (offer && offer.price) {
            return (
                <div className="item_info_action">
                    <div className="item_info_price">
                        <label>Price</label>
                        <p>
                            {wallet.formatPrice(offer.price)}&nbsp;<span>WAX</span>
                        </p>
                    </div>
                    <div className="item_info_buttons">
                        <button className="btn btn_secondary btn_left_noradius" onClick={() => this.props.addToCart({asset: asset, ...offer})}>
                            <img className="cart_icon" src={add_cart_ico}/>
                        </button>
                        <button
                            className="btn btn_primary"
                            onClick={() => this.props.buyItem({asset: asset, ...offer})}
                        >
                            BUY
                        </button>
                    </div>
                </div>
            );
        }

        // MARKET AUCTIONS
        if (offer && offer.high_bid) {
            return (
                <div className="item_info_action">
                    <div className="item_info_price">
                        <label>Best offer</label>
                        <p>
                            {wallet.formatPrice(offer.high_bid)}&nbsp;<span>WAX</span>
                        </p>
                    </div>
                    { offer.buy_now && !high_bid_only &&
                        <div className="item_info_price">
                            <label>Buy now</label>
                            <p>
                                {wallet.formatPrice(offer.buy_now)}&nbsp;<span>WAX</span>
                            </p>
                        </div>
                    }
                    <div className="item_info_buttons">
                        <button
                            className="btn btn_secondary btn_even btn_left_noradius"
                            onClick={() => this.props.showModal({action: 'bid', item: {asset: asset, offer: offer}})}
                        >
                            PLACE A BID
                        </button>
                        <button
                            className={`btn btn_primary btn_even  ${high_bid_only ? 'not-allowed' : ''}`}
                            onClick={() => this.props.buyAuction({asset: asset, ...offer})}
                            disabled={high_bid_only}
                        >
                            BUY NOW
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className="loader-view">
                Out of Stock
                {/* <CircularProgress/> */}
            </div>
        )
    }

    render() {
        const { asset } = this.props
        const item = {asset, offer: asset};

        return this.getActions(item);
    }
}


const mapStateToProps = ({ auth, vault }) => {
    const { user } = auth;
    return {
        user,
    }
};

export default connect(mapStateToProps, {
    showModal,
    hideModal,
    cancelMarketOffer,
    buyItem,
    buyAuction,
    bidAuction,
    closeAuction,
    cancelMarketAuction,
    addToCart,
})(MarketItemButtons);


