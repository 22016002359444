import React from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import * as wallet from '../../util/wallet';
import { history } from '../../store';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollUpButton from 'react-scroll-up-button';
import ItemVisualProps from 'components/ItemVisualProps';
// import {
//     loadUserTransactions,
// } from '../../../actions/Inventory.js';

// TODO: GET THIS DONE !
class HistoryItem extends React.Component
{
    constructor(props) {
        super(props);

        // this.loadMore = this.loadMore.bind(this);
    }
    componentDidMount() {
        // this.props.loadUserTransactions();
    }

    redirectToBloks(transaction) {
        //TODO: make redirect to bloks with transaction id !
    }

    loadMore(page) {
        // this.props.loadUserTransactions({more: true});
    }

    redirectToItemPage(asset) {
        if (asset && asset.type == 'NFT') {
            history.push(`/nft/${asset.assetid}`);
        } else if (asset && asset.type == 'FT'){
            history.push(`/ft/${asset.assetid}`);
        }
    }

    render() {
        if (!this.props.item) {
            return (null);
        }

        const { item } = this.props;
        const { asset } = item;
        if (!asset) {
            return (null);
        }
        let image = asset.img || asset.idata.img || asset.mdata.img;
        if (image.length === 46 && image.substr(0,1) === 'Q') {
            image = 'https://ipfs.io/ipfs/' + image;
        } else if (image && -1 === image.indexOf('http') && image.substr(0,1) === 'Q') {
            image = 'https://ipfs.io/ipfs/' + image;
        }

        return (
            <div className="item_transfer">
                <div className="item_left pointer">
                    <div
                        className="item_background"
                        onClick={() => this.redirectToItemPage(asset)}
                    >
                        <div className="item_image" style={{backgroundImage: `url(${image})`}} />
                        <ItemVisualProps asset={asset} isList={true} />
                    </div>
                    <div className="item_desc">
                        <h2>
                            {asset.name || asset.idata.name || asset.mdata.name || 'missing name'}
                        </h2>
                        <h4><span>Author:</span>&nbsp;<a>{asset.author}</a></h4>
                        <h4><span>From:</span>&nbsp;<a>{item.from}</a></h4>
                        {item.memo && item.memo.type && <h4><span>Type:</span>&nbsp;{item.memo.type}</h4>}
                        <h4>
                            {moment(item.created_at).utc().format('DD-MM-YYYY h:mm a')}
                        </h4>
                    </div>
                </div>
                <div className="item_right">
                    <div className="item_price">
                        <h3
                            className='green_price'
                        >
                            {wallet.formatPrice(item.quantity)}&nbsp;WAX&nbsp;($&nbsp;{wallet.formatUsdPrice(wallet.formatPrice(item.quantity), this.props.usdticker)})
                        </h3>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({ market }) => {
    const { usdticker } = market;
    return {
        usdticker,
    }
};

export default connect(mapStateToProps, {

})(HistoryItem);
