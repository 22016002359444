
import {
    SHOW_MODAL,
    HIDE_MODAL,
    SET_ITEM_MODAL,
    SET_ITEM_ACTION,
    SET_ITEM_DATA,

    SHOW_FILTER_MODAL,
    HIDE_FILTER_MODAL,
    LOAD_FILTER_DATA,
    RESET_FILTERS,
    RESET_FILTERS_SUCCESS,
} from 'constants/ActionTypes';


export const showModal = (options) => {
    return {
        type: SHOW_MODAL,
        payload: options,
    }
}

export const hideModal = () => {
    return {
        type: HIDE_MODAL,
    }
}

export const setItem = (item) => {
    return {
        type: SET_ITEM_MODAL,
        payload: item,
    }
}

export const setAction = (action) => {
    return {
        type: SET_ITEM_ACTION,
        payload: action,
    }
}

export const setItemData = (data) => {
    return {
        type: SET_ITEM_DATA,
        payload: data,
    }
}

export const showFiltersModal = () => {
    return {
        type: SHOW_FILTER_MODAL,
    }
}

export const hideFiltersModal = () => {
    return {
        type: HIDE_FILTER_MODAL,
    }
}

export const loadFiltersData = (type) => {
    return {
        type: LOAD_FILTER_DATA,
        payload: type,
    }
}

export const resetFilters = () => {
    return {
        type: RESET_FILTERS,
    }
}
export const resetFiltersSuccess = () => {
    return {
        type: RESET_FILTERS_SUCCESS,
    }
}