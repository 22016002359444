import * as waxjs from '@waxio/waxjs/dist';
import accessContext from './accessContext';
import { toast } from 'react-toastify';
import * as config from '../constants/config';
import { rpc } from './eosjs';
import {Decimal} from 'decimal.js';



// ===========================
// ===========================
// =========================== LOGIN
// ===========================
// ===========================


export const getAuth = async (user, eosio, payload) => {
    // console.log('GET AUTH PARAMS', user, eosio, payload);
    if (user && user.method == 'cloudwallet' || payload == 'wax') {
        return getWaxWalletAndLogin(user);
    } else if (user && user.method == 'transit' || !isNaN(payload)) {
        return getTransitWalletAndLogin(user ? user.providerIndex : payload, eosio);
    }
}

export const getWaxWalletAndLogin = async (user, eosio) => {
    let name = user && user.account_name ? user.account_name : null;
    // let keys = user && user.permissions ? user.permissions[0].required_auth.keys.map( ele => ele.key ) : [];
    // if (!name || !keys.length) {
    //     console.log('NO NAME, NO KEYS');
    //     eosio = new waxjs.WaxJS(config.rpc.wax);
    // } else {
    //     console.log('WITH NAME, WITH KEYS');
    //     eosio = new waxjs.WaxJS(config.rpc.wax, name, keys);
    // }
    if (!eosio) {
        eosio = new waxjs.WaxJS(config.rpc.wax);
    }
    if (!eosio.api) {
        name = await eosio.login();
        user = await eosio.rpc.get_account(name);
        user.method = 'cloudwallet';
    }

    return {user, eosio}
}

export const getTransitWalletAndLogin = async (index, eosio) => {
    const provider = accessContext.getWalletProviders()[index];
    eosio = accessContext.initWallet(provider);
    await eosio.connect();
    // TODO: do discovery for ledger !
    // const discovery = await eosio.discover({ pathIndexList: [ 0,1,2 ] });
    // console.log('DISCOVERY', discovery);
    const user = await eosio.login();
    user.providerIndex = index;
    user.method = 'transit';
    user.permission = eosio.auth.permission;
    user.publicKey = eosio.auth.publicKey;

    return {user, eosio};
};

export const getAccount = async (auth) => {
    let eosio = auth.eosio.eosApi || auth.eosio.api;
    if (!eosio) {
        eosio = new waxjs.WaxJS(config.rpc.wax);
    }
    return eosio.rpc.get_account(auth.user.account_name);
}


// ===========================
// ===========================
// =========================== GETTERS
// ===========================
// ===========================


export const getTable = async ({code, scope, table, limit = 20, lowerBound, upperBound, indexPosition = null, reverse = true, keyType = null}) => {
    const query = {
        code,
        scope: scope,
        table: table,
        limit: limit,
        reverse: reverse,
    };
    if (indexPosition) {
        query.index_position = indexPosition;
    }
    if (keyType) {
        query.key_type = keyType;
    }
    if (lowerBound) {
        query.lower_bound = lowerBound;
    }
    if (upperBound) {
        query.upper_bound = upperBound;
    }

    return rpc.get_table_rows(query);
}

export const getMarketTable = async ({limit = 20, type = 'offer', lastId = null, accountName = null, reverse = true}) => {
    const query = {
        code: config.inv_c,
        scope: config.inv_c,
        table:  type == 'offer' ? config.offer_t : config.auction_t,
        limit: limit,
        reverse,
    };

    if (lastId && reverse) {
        query.upperBound = lastId;
    }
    if (lastId && !reverse) {
        query.lowerBound = lastId;
    }
    if (accountName) {
        query.keyType = 'i64';
        query.indexPosition = 2;
        query.lowerBound = accountName;
        query.upperBound = accountName;
        query.reverse = false;
        query.show_payer = false;
        query.limit = 10000;
    }

    let offers = await getTable(query);
    const promises = offers.rows.map(async offer => {
        const itemQuery = {
            code: config.asset_c,
            scope: config.inv_c,
            table: config.asset_t,
            limit: 1,
            lowerBound: offer.id || offer.data.id,
            reverse: false,
        };
        const items = await getTable(itemQuery);

        return {
            offer,
            asset: items.rows[0],
        }
    });
    offers.rows = await Promise.all(promises);

    return offers;
}

export const getAssetsTable = async ({name, lastId = null, limit = 20, reverse = true, author = null}) => {
    // limit = 20000;
    const query = {
        code: config.asset_c,
        scope: name,
        table: config.asset_t,
        reverse,
        limit,
    };
    // if (lastId && reverse) {
    //     query.upper_bound = lastId;
    // }
    // if (lastId && !reverse) {
    //     query.lower_bound = lastId;
    // }

    // if (lastId && reverse) {
    //     query.lower_bound = lastId;
    // }
    // if (lastId && !reverse) {
    //     query.upper_bound = lastId;
    // }
    query.lower_bound = lastId;

    if (author) {
        query.key_type = 'i64';
        query.index_position = 2;
        query.lower_bound = author;
        query.upper_bound = author;
        query.show_payer = false;
        // query.limit = limit;
        query.limit = 20000;
    }
// console.log('get assets table query', query);
    return rpc.get_table_rows(query);
}

export const getNftAsset = async ({id, name = null}) => {
    const query = {
        code: config.asset_c,
        scope: name ? name : config.inv_c,
        table: config.asset_t,
        upper_bound: id,
        lower_bound: id,
    }
    return rpc.get_table_rows(query);
}

export const getAssetAuction = async ({id}) => {
    const query = {
        code: config.inv_c,
        scope: config.inv_c,
        table: config.auction_t,
        upper_bound: id,
        lower_bound: id,
    }
    return rpc.get_table_rows(query);
}

export const getAssetOffer = async ({id}) => {
    const query = {
        code: config.inv_c,
        scope: config.inv_c,
        table: config.offer_t,
        upper_bound: id,
        lower_bound: id,
    }
    return rpc.get_table_rows(query);
}

export const getTransfersTable = async (auth, {reverse = true, to = null}) => {
    const query = {
        code: config.asset_c,
        scope: config.asset_c,
        table: config.transfer_t,
        reverse,
    };
    if (to) {
        query.key_type = 'i64';
        query.index_position = 3;
        query.upper_bound = to;
        query.lower_bound = to;
    }
    let transfers = await rpc.get_table_rows(query);

    const promises = transfers.rows.map(async transfer => {
        const assetQuery = {
            code: config.asset_c,
            scope: config.inv_c,
            table: config.asset_t,
            limit: 1,
            lowerBound: transfer.assetid,
            upperBound: transfer.assetid,
            reverse: false,
        };
        let assets = await getTable(assetQuery);
        if (!assets.rows[0]) {
            assetQuery.scope= 'simplemarket';
            assets = await getTable(assetQuery);
        }

        if (!assets.rows[0]) {
            assets.rows[0] = {
                id: transfer.assetid,
                owner: 'unknown',
                author: 'unknown',
                category: 'unknown',
                idata: {},
                mdata: {name: 'unknown'},
                container: [],
                containerf: [],
            }
        }

        return {
            transfer,
            asset: assets.rows[0],
        }
    });
    transfers.rows = await Promise.all(promises);

    return transfers;
}


export const getMarketOffers = async ({lastId = null, limit = 20}) => {
    return getMarketTable({limit, type: 'offer'});
}

export const getMarketAuctions = async ({lastId = null, limit = 20}) => {
    return getMarketTable({limit, type: 'auction'});
}

export const getUserAssets = async ({name, lastId = null, limit = 20, author = null}) => {
    return getAssetsTable({name, lastId, limit, author});
}

export const getUserOffers = async (auth) => {
    return getMarketTable({accountName: auth.user.account_name});
}
export const getUserAuctions = async (auth) => {
    return getMarketTable({accountName: auth.user.account_name, type: 'auction'});
}

// ===========================
// ===========================
// =========================== TRANSACTIONS
// ===========================
// ===========================

export const prepareTokenTransferAction = (auth, {to = 'waxinventory', price, memo = ''}) => {
    return {
        account: 'eosio.token',
        name: 'transfer',
        authorization: [{
            actor: auth.user.account_name,
            permission: auth.user.permission ? auth.user.permission : 'active',
        }],
        data: {
            from: auth.user.account_name,
            to: to,
            quantity: price,
            memo: memo,
        }
    };
}
export const eosioTokenTransfer = async (auth, {to = 'waxinventory', price, memo = ''}) => {
    const transaction = {
        actions: [
            prepareTokenTransferAction(auth, {to, price, memo})
        ]
    };
    const options = {
        blocksBehind: 3,
        expireSeconds: 1200,
    };
    let api = auth.eosio.eosApi || auth.eosio.api;
// console.log('EOSIO TOKEN TRANSFER', transaction);
    return api.transact(transaction, options);
}

export const assetTransfer = async (auth, {to = 'waxinventory', assetids, memo}) => {
    const transaction = {
        actions: [{
            account: config.asset_c,
            name: 'transfer',
            authorization: [{
                actor: auth.user.account_name,
                permission: auth.user.permission ? auth.user.permission : 'active',
            }],
            data: {
                from: auth.user.account_name,
                to: to,
                assetids: Array.isArray(assetids) ? assetids : [assetids],
                memo: JSON.stringify(memo)
            }
        }],
    };
    const options = {
        blocksBehind: 3,
        expireSeconds: 1200,
    };
    let api = auth.eosio.eosApi || auth.eosio.api;
// console.log('ASSET TRANSFER', transaction);
    return api.transact(transaction, options);
}

export const sellMarket = async (auth, {price, assetids}) => {
    const memo = {
        price: price,
        type: 'market',
    }
    return assetTransfer(auth, {assetids, memo});
}

export const setAuction = async (auth, {assetids, high_bid, buy_now = null, expires_at = null}) => {
    const memo = {
        type: 'auction',
        price: `${Number(high_bid).toFixed(8)} WAX`,
        expires_at: expires_at ? expires_at : Math.round(Date.now()/1000), // TODO: make expiration time !
    };
    if (buy_now) {
        memo.buy_now = `${Number(buy_now).toFixed(8)} WAX`;
    }
    return assetTransfer(auth, {assetids, memo});
}

export const buyMarket = async (auth, { id, price, type = 'market'}) => {
    id = String(id);
    const memo = JSON.stringify({id, type});

    return eosioTokenTransfer(auth, {price, memo});
}

export const buyMarketBulk = async (auth, {to = 'waxinventory', assets}) => {
    let transaction = {
        actions: [],
    }
    assets.forEach(asset => {
        let id = String(asset.id);
        const memo = JSON.stringify({id, type: asset.type});
        transaction.actions.push(prepareTokenTransferAction(auth, {to, price: asset.price, memo}));
    });

    const options = {
        blocksBehind: 3,
        expireSeconds: 1200,
    };
    let api = auth.eosio.eosApi || auth.eosio.api;

    return api.transact(transaction, options);
}

export const closeAuction = async (auth, {assetids}) => {
        const transaction = {
        actions: [{
            account: config.inv_c,
            name: 'closea',
            authorization: [{
                actor: auth.user.account_name,
                permission: auth.user.permission ? auth.user.permission : 'active',
            }],
            data: {
                assetids: Array.isArray(assetids) ? assetids : [assetids],
            }
        }],
    };
    const options = {
        blocksBehind: 3,
        expireSeconds: 1200,
    };
    let api = auth.eosio.eosApi || auth.eosio.api;
// console.log('CLOSE AUCTION', transaction);
    return api.transact(transaction, options);
}

export const claimTransfer = async (auth, {asset}) => {
    const transaction = {
        actions: [{
            account: config.asset_c,
            name: 'claim',
            authorization: [{
                actor: auth.user.account_name,
                permission: auth.user.permission ? auth.user.permission : 'active',
            }],
            data: {
                claimer: auth.user.account_name,
                assetids: [
                    asset.id,
                ]
            }
        }],
    };
    const options = {
        blocksBehind: 3,
        expireSeconds: 1200,
    };
    let api = auth.eosio.eosApi || auth.eosio.api;
// console.log('CLOSE AUCTION', transaction);
    return api.transact(transaction, options);
}

export const claimTransfers = async (auth, {assetids}) => {
    const transaction = {
        actions: [{
            account: config.asset_c,
            name: 'claim',
            authorization: [{
                actor: auth.user.account_name,
                permission: auth.user.permission ? auth.user.permission : 'active',
            }],
            data: {
                claimer: auth.user.account_name,
                assetids: assetids
            }
        }],
    };
    const options = {
        blocksBehind: 3,
        expireSeconds: 1200,
    };
    let api = auth.eosio.eosApi || auth.eosio.api;
// console.log('CLOSE AUCTION', transaction);
    return api.transact(transaction, options);
}

export const cancelMarketOffer = async (auth, {assetids}) => {
    const transaction = {
        actions: [{
            account: config.inv_c,
            name: 'cancel',
            authorization: [{
                actor: auth.user.account_name,
                permission: auth.user.permission ? auth.user.permission : 'active',
            }],
            data: {
                owner: auth.user.account_name,
                assetids: assetids,
            },
        }],
    };
    const options = {
        blocksBehind: 3,
        expireSeconds: 1200,
    };
    let api = auth.eosio.eosApi || auth.eosio.api;
// console.log('CANCEL MARKET OFFER', transaction);
    return api.transact(transaction, options);
}

export const cancelMarketAuction = async (auth, {assetids}) => {
    const transaction = {
        actions: [{
            account: config.inv_c,
            name: 'cancela',
            authorization: [{
                actor: auth.user.account_name,
                permission: auth.user.permission ? auth.user.permission : 'active',
            }],
            data: {
                owner: auth.user.account_name,
                assetids: assetids,
            },
        }],
    };
    const options = {
        blocksBehind: 3,
        expireSeconds: 1200,
    };
    let api = auth.eosio.eosApi || auth.eosio.api;
// console.log('CANCEL MARKET AUCTION', transaction);
    return api.transact(transaction, options);
}

// ===========================
// ===========================
// =========================== UTIL
// ===========================
// ===========================

export const formatPrice = priceStr => {
    return Number(priceStr.split(' ')[0]).toString();
}

export const formatUsdPrice = (priceStr, usdTicker) => {
    let price = new Decimal(priceStr);
    let usd = new Decimal(usdTicker);

    return price.times(usd).toFixed(2);
}