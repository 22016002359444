import {
    INIT_URL,
    SIGNIN_TRANSIT,
    INIT_ACCESS_CONTEXT_PAYLOAD,
    INIT_ACCESS_CONTEXT,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SHOW_MESSAGE,
    ON_SHOW_LOADER,
    HIDE_MESSAGE,
    ON_HIDE_LOADER,
    SAVE_AUTH_USER,
    SAVE_WALLET,
    SIGNIN_WAX,
    LOAD_USER,
    LOAD_USER_TICKER,
    SET_USER_TICKER_RUNNING,
    ENABLE_AUTH_STATUS,
    LOADER_STATUS,
    SET_AUTO_LOGIN,
    SET_LOGIN_RESULT,
} from 'constants/ActionTypes';

export const saveAuthData = (user) => {
    return {
        type: SAVE_AUTH_USER,
        payload: user
    }
}

export const enableAuthStatus = (status = true) => {
    return {
        type: ENABLE_AUTH_STATUS,
        payload: status,
    }
}

export const changeLoaderStatus = (status) => {
    return {
        type: LOADER_STATUS,
        payload: status,
    }
}

export const setAutoLogin = (enabled) => {
    return {
        type: SET_AUTO_LOGIN,
        payload: enabled,
    }
}

export const setLoginResult = (status) => {
    return {
        type: SET_LOGIN_RESULT,
        payload: status,
    }
}

export const signInTransit = (providerIndex) => {
    return {
        type: SIGNIN_TRANSIT,
        payload: providerIndex,
    }
}

export const saveEosio = (eosio) => {
    return {
        type: SAVE_WALLET,
        payload: eosio,
    }
}

// export const user

export const initAccessContext1 = () => {
    return {
        type: INIT_ACCESS_CONTEXT,
    }
}
export const initAccessContext = (accessContext) => {
    return {
        type: INIT_ACCESS_CONTEXT_PAYLOAD,
        payload: accessContext,
    }
}

export const userSignOut = () => {
    return {
        type: SIGNOUT_USER,
    };
};

export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};

export const signInWax = () => {
    return {
        type: SIGNIN_WAX,
    }
}

export const loadUser = () => {
    return {
        type: LOAD_USER,
    }
}

export const loadUserTicker = () => {
    return {
        type: LOAD_USER_TICKER
    }
}

export const setUserTickerRunning = () => {
    return {
        type: SET_USER_TICKER_RUNNING
    }
}