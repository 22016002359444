
import {
    SET_SEARCH_PHRASE,
    CLEAR_SEARCH_SUGGESTIONS,
    SUCCESS_SEARCH_SUGGESTIONS,
    MORE_SEARCH_SUGGESTIONS,
}  from '../constants/ActionTypes.js';

const INIT_STATE = {
    string: '',
    suggestions: [],
    suggestions_more: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SEARCH_PHRASE: {
            return {
                ...state,
                string: action.payload,
            };
        }
        case CLEAR_SEARCH_SUGGESTIONS: {
            return {
                ...state,
                suggestions: [],
            };
        }
        case SUCCESS_SEARCH_SUGGESTIONS: {
            return {
                ...state,
                suggestions: action.payload,
            };
        }
        case MORE_SEARCH_SUGGESTIONS: {
            return {
                ...state,
                suggestions_more: action.payload,
            };
        }

        default:
            return state;
    }
}
