import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { userSignOut } from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import {history} from '../../store';
import * as wallet from '../../util/wallet';

class UserInfo extends React.Component {

  constructor(props) {
    super(props)
  }

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleSignOut = () => {
    this.props.userSignOut()
    // this.props.history.push('/');
    // history.push('/');
  };

  handleLoginButton = () => {
    history.push('/login');
    // this.props.history.push('/signin');
  }

  render() {
    const user = this.props.user;
    // console.log('user bar', user)
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        {/* <div> */}
        {user ?
        (
            <div>
              <div className="user-detail">
                {user && user.account_name ?
                  <h4 className="user-name">
                    {user.account_name}
                  </h4>
                  :
                  <h4 className="login">
                    Login
                  </h4>
                }
              </div>
              {user.core_liquid_balance &&
                <h4 className="user-balance">
                  {wallet.formatPrice(user.core_liquid_balance)}&nbsp;<span>WAX</span>
                </h4>
              }
              <Menu className="user-info"
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                open={this.state.open}
                onClose={() => this.handleRequestClose()}
                PaperProps={{
                  style: {
                    minWidth: 120,
                    paddingTop: 0,
                    paddingBottom: 0
                  }
                }}
              >
                <MenuItem onClick={() => {
                  this.handleSignOut();
                }}>
                  <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

                  <IntlMessages id="popup.logout" />
                </MenuItem>
              </Menu>
            </div>
        ) : (
          <div>
          <div className="user-detail">
            <h4 className="login" onClick={() => this.handleLoginButton()}>Login
              {/* <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" /> */}
            </h4>
          </div>
        </div>
        )
      }

      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  const { user } = auth;
  return { locale, user }
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);

