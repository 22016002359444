import {
    LOAD_NFT_ASSET,
    LOAD_NFT_ASSET_SUCCESS,
    LOAD_NFT_MARKET_SUCCESS,
    LOAD_NFT_LOGS_SUCCESS,
    SHOW_LOADING,
} from 'constants/ActionTypes';


export const loadNftAsset = (itemId) => {
    return {
        type: LOAD_NFT_ASSET,
        payload: itemId,
    }
}
export const loadNftAssetSuccess = (item) => {
    return {
        type: LOAD_NFT_ASSET_SUCCESS,
        payload: item,
    }
}

export const loadNftMarketSuccess = (offer) => {
    return {
        type: LOAD_NFT_MARKET_SUCCESS,
        payload: offer,
    }
}
export const loadNftAssetLogSuccess = (logs) => {
    return {
        type: LOAD_NFT_LOGS_SUCCESS,
        payload: logs,
    }
}

export const itemPageLoading = () => {
    return {
        type: SHOW_LOADING,
    }
}



