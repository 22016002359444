import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  signInWax,
  signInTransit,
  setAutoLogin,
} from 'actions/Auth';

import accessContext from '../util/accessContext'
import Logo from '../components/Logo';
import * as config from '../constants/config';


class Login extends React.Component {
  constructor() {
    super();
    this.setAutoLogin = this.setAutoLogin.bind(this);
  }

  componentDidUpdate() {
    if (this.props.user !== null) {
      this.props.history.push('/');
    }
  }

  login(providerIndex) {
    this.props.signInTransit(providerIndex);
  }

  loginWax() {
    this.props.signInWax();
  }

  setAutoLogin = (event) => {
    let value = event.target.checked;
    this.props.setAutoLogin(value);
  }

  render() {
    const {loader} = this.props;

    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="login-border"></div>
          <Logo />
          <h1>Login</h1>
          <span>Choose option to access WAX inventory</span>
          <div className="app-login-content">
            {/* {
              loader &&
              <div className="loader-view">
                <CircularProgress/>
              </div>
            } */}
            {config.cloudWalletEnabled &&
              <div
                className="login-option"
                onClick={() => {
                  // TODO: make this div covered by loader or some smooth loading
                  this.loginWax();
                }}
              >
                WAX Cloud Wallet
              </div>
            }
            {accessContext.getWalletProviders().map((provider, index) => {
              return (
                <div
                  key={index}
                  className="login-option"
                  onClick={() => {
                    this.login(index)
                  }}
                >
                    {provider.meta.name}
                </div>
              )
            })}
            <div className="auto_login_container">
              <label htmlFor="auto_login" className="checkbox_label">
                <input
                    id="auto_login"
                    type="checkbox"
                    name="auto_login"
                    className="checkbox"
                    // checked={this.props.auto_login}
                    defaultChecked={false}
                    onChange={this.setAutoLogin}
                />
                <span className="slider"></span>
                <span>Enable automatic login.</span>
              </label>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, user, accessContext} = auth;
  return {loader, user, accessContext}
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  signInTransit,
  signInWax,
  setAutoLogin,
})(Login);
