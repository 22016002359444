import React from 'react';
import Item from '../Item';

class Items extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    const { items } = this.props
    return (
      <div className="items">
          <ul>
            {items && items.map((item, idx) => (
              <Item item={item} key={idx} />
              ))}
          </ul>
      </div>
    )
  }
}

export default Items

