import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Items from '../../../components/Items';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollUpButton from 'react-scroll-up-button';
import {
    loadMarketAll,
} from '../../../actions/Market.js';


class All extends React.Component
{
    constructor(props) {
        super(props);

        this.loadMore = this.loadMore.bind(this);
    }
    componentDidMount() {
        this.props.loadMarketAll();
    }

    loadMore(page) {
        this.props.loadMarketAll({more: true});
    }

    render() {
        const items = this.props.all;

        return (
            <section id="market_section">
                <Helmet>
                    <title>WAX inventory | WAX Marketplace</title>
                    <meta name="description" content="Join WAX inventory! Buy, sell, and trade virtual items through WAX inventory - WAX Marketplace. Powered by Worldwide Asset eXchange smart contract. Use your WAX token to trade and earn crypto assets."/>
                </Helmet>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMore}
                    hasMore={this.props.all_more}
                    loader={
                        <div key={1} className="loader-view" style={{clear: 'both'}}>
                            <CircularProgress/>
                        </div>
                    }
                    useWindow={true}
                >
                    <Items items={items}/>
                </InfiniteScroll>
                <ScrollUpButton />
            </section>
        );
    }
}


const mapStateToProps = ({ wallet, offer, market }) => {
    const {all, all_more} = market;
    return {
        all,
        all_more,
    }
};

export default connect(mapStateToProps, {
    loadMarketAll,
})(All);
