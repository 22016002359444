import * as config from '../constants/config';
import axios from 'axios';

const host = config.api.host;

export const getWaxUsdTicker = async () => {
    const response = await axios.get(`${host}/api/v1/market/waxusd`);
    return response.data;
}

export const getHistoryLog = async (filters) => {
    let search = new URLSearchParams(filters);
    const response = await axios.get(`${host}/api/v1/market/history?${search.toString()}`);
    return response.data;
}

export const getMarketTable = async (filters, type) => {
    // Defaults
    let query = {
        skip: 0,
        limit: 20,
        // type: null,
        price_min: null,
        price_max: null,
        direction: 'desc',
        author: null,
        category: null
    };
    query = Object.assign(query, filters);
    Object.keys(query).forEach((key) => (query[key] === null) && delete query[key]);
    query.type = type;
    // query.limit = 100;
// console.log(query);
    let search = new URLSearchParams(query);
// console.log('axios get', `${host}/api/v1/market?${search.toString()}`);
    const response = await axios.get(`${host}/api/v1/market?${search.toString()}`);
// console.log(response.data);
    return response.data;
}

export const getTransactions = async (account, filters) => {
    let query = {
        skip: 0,
        limit: 20,
    };
    query = Object.assign(query, filters);
    Object.keys(query).forEach((key) => (query[key] === null) && delete query[key]);
    let search = new URLSearchParams(query);
    const response = await axios.get(`${host}/api/v1/transactions/${account}?${search.toString()}`);

    return response.data;
}

export const getAsset = async (id, type = 'NFT') => {
    const response = await axios.get(`${host}/api/v1/asset/${id}?type=${type}`);

    return response.data;
}

export const getAssetLogs = async (id, type = 'NFT') => {
    const response = await axios.get(`${host}/api/v1/asset/${id}/logs?type=${type}`);

    return response.data;
}

export const search = async (filters) => {
    let query = {
        skip: 0,
        limit: 20,
    };
    query = Object.assign(query, filters);
    Object.keys(query).forEach((key) => (query[key] === null) && delete query[key]);
    let search = new URLSearchParams(query);
// console.log('search', search, `${host}/api/v1/search?${search.toString()}`);
    const response = await axios.get(`${host}/api/v1/search?${search.toString()}`);

    return response.data;
}

export const getInventoryTable = async (account_name, filters) => {
    let query = {
        skip: 0,
        limit: 20,
    };
    query = Object.assign(query, filters);
    Object.keys(query).forEach((key) => (query[key] === null) && delete query[key]);
    let search = new URLSearchParams(query);
// console.log('inventory table search', search, `${host}/api/v1/account/${account_name}/assets?${search.toString()}`);
    const response = await axios.get(`${host}/api/v1/account/${account_name}/assets?${search.toString()}`);
// console.log('inventory table data', response.data);
    return response.data;
}

export const sendFeedback = async (data) => {
    const response = await axios.post(`${host}/api/v1/feedback`, data);

    return response.data;
}

export const getFiltersData = async (type, account_name = null) => {
    let price_req, cat_req, authors_req;
    let def = {
        value: {min:0, max: 999999},
        authors: [],
        categories: [],
    };
    switch (type) {
        case 'market':
            price_req = axios.get(`${host}/api/v1/market/prices`);
            cat_req = axios.get(`${host}/api/v1/market/categories`);
            authors_req = axios.get(`${host}/api/v1/market/authors`);
            break;
        case 'inventory_action':
            if (!account_name) {
                return def;
            }
            price_req = axios.get(`${host}/api/v1/market/prices?owner=${account_name}`);
            cat_req = axios.get(`${host}/api/v1/market/categories?owner=${account_name}`);
            authors_req = axios.get(`${host}/api/v1/market/authors?owner=${account_name}`);
            break;
        case 'inventory_items':
            if (!account_name) {
                return def;
            }
            price_req = Promise.resolve();
            cat_req = axios.get(`${host}/api/v1/account/${account_name}/categories`);
            authors_req = axios.get(`${host}/api/v1/account/${account_name}/authors`);
            break;
        default:
            return def;
    }
    let [price, categories, authors] = await Promise.all([price_req, cat_req, authors_req]);

    if (price && price.data && price.data.min && price.data.max) {
        def.value = {
            min: price.data.min,
            max: price.data.max,
        };
    }
    if (categories && Array.isArray(categories.data)) {
        def.categories = categories.data
    }
    if (authors && Array.isArray(authors.data)) {
        def.authors = authors.data
    }

    return def;
}

export const prepareFilters = (filters, filter) => {
    const data = {
        ...filters,
    };
    if (filter && filter.author && filter.author !== '') {
        data.author = filter.author;
    } else {
        data.author = null;
    }
    if (filter && filter.category && filter.category !== '') {
        data.category = filter.category;
    } else {
        data.category = null;
    }
    if (filter && filter.value && filter.value.min) {
        data.price_min = filter.value.min;
    } else {
        data.price_min = null;
    }
    if (filter && filter.value && filter.value.max) {
        data.price_max = filter.value.max;
    } else {
        data.price_max = null;
    }
    if (filter && filter.sort_order) {
        data.direction = filter.sort_order.indexOf('_asc') !== -1 ? 'asc' : 'desc';
        if (filter.sort_order.indexOf('age_') !== -1) {
            data.sort_type = 'created_at';
        } else if (filter.sort_order.indexOf('price_') !== -1) {
            data.sort_type = 'price';
        } else {
            data.sort_type = 'created_at';
        }
    }
    if (filter && filter.limit) {
        data.limit = filter.limit;
    }
    if (!data.skip) {
        data.skip = 0;
    }
    if (!data.limit) {
        data.limit = 20;
    }

    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);

    return data;
}