import Modal from 'react-modal';
import React from 'react';
import { connect } from 'react-redux';
import Sell from './sell';
import Bid from './bid';
import Transfer from './transfer';
import Auction from './auction';
import * as moment from 'moment';
import {
    showModal,
    hideModal,
    setAction,
} from '../../actions/Modal';

import {
    transferItem,
} from '../../actions/Inventory';

import {
    sellItem,
    setAuction,
    bidAuction,
} from '../../actions/Market';


Modal.setAppElement('#app-site');

const initState = {
    to: '',
    price: null,
    bid: null,
    high_bid: null,
    buy_now: null,
    expires_at: null,
    memo: '',
};

class ItemModal extends React.Component {
    constructor (props) {
        super(props);
        this.state = initState;

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.onChange = this.onChange.bind(this);
        this.radioOnChange = this.radioOnChange.bind(this);
        this.sellRadioButtons = this.sellRadioButtons.bind(this);
    }

    onChange(field, value) {
        this.setState({[field]: value});
    }

    handleOpenModal () {
        this.props.showModal();
    }

    handleCloseModal () {
        this.setState({});
        this.props.hideModal();
    }

    getItem() {
        return this.props.item.item ? this.props.item.item : this.props.item.asset ? this.props.item.asset : this.props.item
    }

    radioOnChange(event) {
        this.props.setAction(event.target.value);
    }

    sell() {
        // TODO: loader
        // TODO: toast error / success
        const payload = {
            price: this.state.price,
            asset: this.getItem(),
        };
        this.props.sellItem(payload);
        this.setState(initState);
        this.props.hideModal();
    }

    transfer() {
        const payload = {
            to: this.state.to,
            memo: this.state.memo,
            asset: this.getItem(),
        };
        this.props.transferItem(payload);
        this.setState(initState);
        this.props.hideModal();
    }

    auction() {
        const payload = {
            high_bid: this.state.high_bid,
            buy_now: this.state.buy_now ? this.state.buy_now : null,
            expires_at: moment().utc().add(this.state.expires_at ? this.state.expires_at : 14, 'days').unix(),
            asset: this.getItem(),
        };
        this.props.setAuction(payload);
        this.setState(initState);
        this.props.hideModal();
    }

    bid() {
        const payload = {
            bid: this.state.bid,
            asset: this.getItem(),
        };
        this.props.bidAuction(payload);
        this.setState(initState);
        this.props.hideModal();
    }

    fields(action) {
        switch(action) {
            case 'bid':
                return <Bid item={this.props.item} onChange={this.onChange}/>;
            case 'sell':
                return <Sell item={this.props.item} onChange={this.onChange}/>;
            case 'auction':
                return <Auction item={this.props.item} onChange={this.onChange}/>;
            case 'transfer':
                return <Transfer item={this.props.item} onChange={this.onChange}/>;
            default:
                return <Sell item={this.props.item} onChange={this.onChange}/>;
        }
    }

    callToAction (action) {
        switch(action) {
            case 'bid':
                return 'PLACE A BID';
            case 'sell':
            case 'auction':
                return 'SELL';
            case 'transfer':
                return 'TRANSFER';
            default:
                return 'Placeholder';
        }
    }

    handleCallToAction (action) {
        switch(action) {
            case 'sell':
                return this.sell();
            case 'bid':
                return this.bid();
            case 'auction':
                return this.auction();
            case 'transfer':
                return this.transfer();
            default:
                return;
        }
    }

    sellRadioButtons(action) {
        if (action === 'sell' || action === 'auction') {
            return (
                <div className="modal_radio">
                    <input
                        id="radio_sell"
                        type="radio"
                        name="action"
                        value="sell"
                        checked={action === 'sell'}
                        onChange={this.radioOnChange}
                    />
                    <label htmlFor="radio_sell">
                        Buy now
                    </label>
                    <input
                        id="radio_auction"
                        type="radio"
                        value="auction"
                        name="action"
                        checked={action === 'auction'}
                        onChange={this.radioOnChange }
                    />
                    <label htmlFor="radio_auction">
                        Auction
                    </label>
                </div>
            );
        }
    }

    render () {
        let item = this.props.item;
        let image = item ? item.img : null;

        if (item) {
            let {asset, ...offer} = item;
            if (!asset) {
                item = offer;
            } else {
                item = asset;
            }

            try {
                item.idata = typeof item.idata === 'object' ? item.idata : JSON.parse(item.idata);
            } catch (e) {
                item.idata = {};
            }
            try {
                item.mdata = typeof item.mdata === 'object' ? item.mdata : JSON.parse(item.mdata);
            } catch (e) {
                item.mdata = {};
            }
            item.data = Object.assign(item.mdata, item.idata);

            // IMAGE
            image = item.img || item.idata.img || item.mdata.img;
            if (image && image.length === 46 && image.substr(0,1) === 'Q') {
                image = 'https://ipfs.io/ipfs/' + image;
            } else if (image && -1 === image.indexOf('http') && image.substr(0,1) === 'Q') {
                image = 'https://ipfs.io/ipfs/' + image;
            }
        }


        return (
            <Modal
                isOpen={this.props.isModalOpen}
                contentLabel="Item Modal"
                onRequestClose={this.handleCloseModal}
                className="modal"
                overlayClassName="modal_overlay"
                center={true}
            >
                {item ? (
                        <div className="modal_container">
                            <section className="modal_header items_info">
                                <div className="modal_item_bg">
                                    <div className="modal_item_img" style={{backgroundImage: `url(${image})`}} />
                                </div>
                                <div className="modal_header_name">
                                    <h3>{item.data.name}</h3>
                                    <h4><span>Author:</span>&nbsp;<a>{item.author}</a></h4>
                                </div>
                            </section>
                            {this.sellRadioButtons(this.props.action)}
                            {this.fields(this.props.action)}
                        </div>
                    ): (
                        <div>
                            <p>Modal text!</p>
                            <button onClick={this.handleCloseModal}>Close Modal</button>
                        </div>
                )}
                <div className="modal_actions">
                    <button
                        className="btn btn_primary btn_lrg"
                        onClick={() => this.handleCallToAction(this.props.action)}
                    >
                        {this.callToAction(this.props.action)}
                    </button>
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = ({ modal }) => {
    const { isModalOpen, item, action } = modal;
    return {
        isModalOpen,
        item,
        action,
    }
};

export default connect(mapStateToProps, {
    showModal,
    hideModal,
    sellItem,
    transferItem,
    setAuction,
    setAction,
    bidAuction,
})(ItemModal);
