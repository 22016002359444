import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import { userSignOut } from "../../actions/Auth.js";
import * as config from "../../constants/config";
import twitter_ico from "../../assets/images/twitter_ico_l.png";
import telegram_ico from "../../assets/images/telegram_ico_l.png";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {
      // if (config.debug){
      //     console.error('sidenav error', error);
      // }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {
      // if (config.debug){
      //   console.error('sidenav will recive props error', error);
      // }
    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {
      if (config.debug) {
        console.error("sidenav closest", e);
      }
    }

    return null;
  }

  render() {
    return (
      <CustomScrollbars className="scrollbar">
        <ul className="nav-menu">
          <li className="menu no-arrow">
            <NavLink to="/marketplace">
              <span className="nav-text">Marketplace</span>
            </NavLink>
          </li>

          {/* <li className="menu no-arrow">
            <a href="#">
              <span className="nav-text">
                Trade (Comming Soon!)
              </span>
              </a>
          </li> */}

          {/* <li className="menu no-arrow">
            <NavLink to="/trade">
              <span className="nav-text">
                Trade
              </span>
            </NavLink>
          </li> */}
          <li className="menu no-arrow">
            {/* <NavLink to={["/app/inventory", "/app/inventory/items"]}> */}
            <NavLink to="/inventory">
              {/* <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/> */}
              <span className="nav-text">
                {/* <IntlMessages id="pages.samplePage"/>  */}
                Inventory
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            {/* <NavLink to={["/app/inventory", "/app/inventory/items"]}> */}
            <NavLink to="/transfers">
              {/* <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/> */}
              <span className="nav-text space-between">
                {/* <IntlMessages id="pages.samplePage"/>  */}
                Transfers
                {this.props.transfersCount > 0 && (
                  <i className="transfers-count">{this.props.transfersCount}</i>
                )}
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/contact">
              <span className="nav-text space-between">Contact Us</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink
              to="/"
              className="logout-sidenav"
              onClick={(e) => this.props.userSignOut()}
            >
              <span className="nav-text">Logout</span>
            </NavLink>
          </li>
        </ul>
        <div className="scrollbar_bottom">
          <a
            href="https://twitter.com/waxinventory"
            target="_blank"
            rel="noopener"
            alt="WAXinventory Twitter Account"
          >
            <img
              src={twitter_ico}
              className="social_ico"
              alt="WAXinventory Twitter Account"
            />
          </a>
          {/* <a href="https://t.me/waxinventory" target="_blank" rel="noopener noreferrer" alt="WAXinventory Telegram Group"> */}
          <a
            href="https://t.me/waxinventory"
            target="_blank"
            rel="noopener"
            alt="WAXinventory Telegram Group"
          >
            <img
              src={telegram_ico}
              className="social_ico"
              alt="WAXinventory Telegram Group"
            />
          </a>
        </div>
      </CustomScrollbars>
    );
  }
}

// export default withRouter(SidenavContent);

const mapStateToProps = ({ inventory }) => {
  const { transfersCount } = inventory;
  return {
    transfersCount,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    // loadUserOffers,
    userSignOut,
  })(SidenavContent)
);
