import React from 'react';
import {Route, Switch, withRouter, Link} from 'react-router-dom';
import { connect } from 'react-redux';
import ItemModal from '../../../components/ItemModal';
// import FilterModal from '../../../components/FilterModal';
import MyItems from './items';
// import Auctions from './auctions';
import Transactions from './transactions';
// import Offers from './offers';
import InAction from './in_action';
import asyncComponent from '../../../util/asyncComponent';
import {history} from '../../../store';
import filter_ico from '../../../assets/images/item/filter@3x.png';

import {
    hideModal,
    showFiltersModal,
    hideFiltersModal,
    setItemsPrice,
    resetSelectedInventoryItems,
    sellMultipleItems,
} from '../../../actions';


class Inventory extends React.Component {
    constructor() {
        super();
        this.isRouteActive = this.isRouteActive.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.multiSelectBar = this.multiSelectBar.bind(this);
        this.onChangePrice = this.onChangePrice.bind(this);
        this.sellSelectedItems = this.sellSelectedItems.bind(this);
    }

    componentDidMount() {
        this.props.hideModal();
    }

    isRouteActive(type) {
        const isItems = this.props.location.pathname === `${this.props.match.url}`
                    || this.props.location.pathname === `${this.props.match.url}/items`;
        // const isInAction = this.props.location.pathname === `${this.props.match.url}/inaction`;
        // const offers = this.props.location.pathname === `${this.props.match.url}/offers`;
        const transactions = this.props.location.pathname === `${this.props.match.url}/transactions`;
        // const auctions = this.props.location.pathname === `${this.props.match.url}/auctions`;
        const inAction = this.props.location.pathname === `${this.props.match.url}/in_action`;
        if (type === 'items' && isItems) {
            return 'active';
        }
        if (type === 'transactions' && transactions) {
            return 'active';
        }
        // if (type === 'offers' && offers) {
        //     return 'active';
        // }
        if (type === 'in_action' && inAction) {
            return 'active';
        }
        // if (type === 'auctions' && auctions) {
        //     return 'active';
        // }
        return '';
    }

    onChangePrice = (e) => {
        this.props.setItemsPrice(e.target.value);
    }

    sellSelectedItems = () => {
        this.props.sellMultipleItems({assetids: this.props.items_checked, price: this.props.items_price})
    }

    multiSelectBar = () => {
        const isItems = this.props.location.pathname === `${this.props.match.url}`
                    || this.props.location.pathname === `${this.props.match.url}/items`;

        if (!isItems) {
            return (null);
        }
        if (!this.props.items_checked || !this.props.items_checked.length) {
            return (null);
        }

        return (
            <section className="topSubNav topSubNav_multiselect">
                    <div className="topSubNav_links">
                        Items selected: {this.props.items_checked.length}
                    </div>
                    <div
                        className="price_container"
                    >
                        <div className="price_input">
                            <input
                                placeholder="0.00"
                                name="price"
                                onChange={(e) => this.onChangePrice(e)}
                                autoComplete="off"
                            />
                            <div className="wax_input_label">
                                <span>WAX</span>
                            </div>
                        </div>
                        <button
                            className="btn btn_primary btn_s_round"
                            onClick={(e) => this.sellSelectedItems()}
                        >
                            SELL
                        </button>
                        <button
                            className="btn btn_s_round"
                            onClick={(e) => this.props.resetSelectedInventoryItems()}
                        >
                            RESET
                        </button>
                    </div>
                </section>
        );
    }

    onTabChange(event) {
        history.push(`/inventory/${event.target.getAttribute('name')}`);
    }

    render() {
        const {match} = this.props;
        return (
            <div className="app-wrapper marketplace">
                {/* // TODO: create top nav bar! */}
                {/* <h1 className="page_header">Inventory</h1> */}
                <section className="topSubNav">
                    <div className="topSubNav_links">
                        <Link to="/inventory/items">
                            <span
                                className={this.isRouteActive('items')}
                                name="items"
                                // onClick={this.onTabChange}
                            >
                                My Items
                            </span>
                        </Link>
                        <Link to="/inventory/in_action">
                            <span
                                className={this.isRouteActive('in_action')}
                                name="in_action"
                                // onClick={this.onTabChange}
                            >
                                In Action
                            </span>
                        </Link>
                        <Link to="/inventory/transactions">
                            <span
                                className={this.isRouteActive('transactions')}
                                name="transactions"
                                // onClick={this.onTabChange}
                            >
                                Transactions
                            </span>
                        </Link>
                    </div>
                    <div
                        className="topSubNav_filters"
                        onClick={() => this.props.showFiltersModal()}
                    >
                        <span>Filters</span>
                        <img
                            src={filter_ico}
                            alt="Filter assets"
                        />
                    </div>
                </section>
                {this.multiSelectBar()}
                <Switch>
                    {/* <Route path={`${match.url}/inaction`}
                        component={ItemsInAction}/> */}
                    {/* <Route path={`${match.url}/offers`} component={Offers}/>
                    <Route path={`${match.url}/auctions`} component={Auctions}/> */}
                    <Route path={`${match.url}/in_action`} component={InAction} />
                    <Route path={`${match.url}/transactions`} component={Transactions}/>
                    <Route path={`${match.url}/items`} component={MyItems}/>
                    <Route exact path={`${match.url}`} component={MyItems}/>
                    {/* <Route component={asyncComponent(() => import('components/Error404'))}/> */}
                </Switch>
                <ItemModal isOpen={this.props.isModalOpen}/>
                {/* <FilterModal isOpen={this.props.isFilterModalOpen} type="inventory" /> */}
            </div>
        )
    }
}

const mapStateToProps = ({ modal, inventory }) => {
    const { isModalOpen } = modal;
    const { items_checked, items_price } = inventory;
    return {
        isModalOpen,
        items_checked,
        items_price,
        // isFilterModalOpen,
    }
};

export default withRouter(connect(mapStateToProps, {
    hideModal,
    showFiltersModal,
    hideFiltersModal,
    setItemsPrice,
    resetSelectedInventoryItems,
    sellMultipleItems,
})(Inventory));
