import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import HistoryLog from 'components/HistoryLog'
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollUpButton from 'react-scroll-up-button';

import {
    // loadMarketOffers,
    loadHistoryLog,
} from '../../../actions';

class History extends React.Component
{
    constructor(props) {
        super(props);

        // this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
        this.props.loadHistoryLog();
    }

    loadMore = () => {
        this.props.loadHistoryLog({more: true});
    }

    render() {
        const history = this.props.history;
        return (
            <section>
                <Helmet>
                    <title>History Log</title>
                    <meta name="description" content="Buy NFT's in bulk with shopping cart on WAX inventory - WAX Marketplace."/>
                </Helmet>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadMore}
                    hasMore={this.props.history_more}
                    loader={
                        <div key={1} className="loader-view" style={{clear: 'both'}}>
                            <CircularProgress/>
                        </div>
                    }
                    useWindow={true}
                >
                    <HistoryLog items={history} />
                </InfiniteScroll>
                <ScrollUpButton />
            </section>
        );
    }
}


const mapStateToProps = ({ market }) => {
    const {history, history_more} = market;
    return {
        history,
        history_more
    }
};

export default connect(mapStateToProps, {
    loadHistoryLog,
})(History);
