import React from 'react';
import { connect } from 'react-redux';

import ItemModal from '../../../components/ItemModal';
import FilterModal from '../../../components/FilterModal';
import { Route, Switch, withRouter, Link } from 'react-router-dom';

import All from './all';
import Offers from './offers';
import Auctions from './auctions';
import History from './history';
import Error404 from 'components/Error404';
// import asyncComponent from '../../../util/asyncComponent';
import { history } from '../../../store';
import filter_ico from '../../../assets/images/item/filter@3x.png';

import {
    hideModal,
    showFiltersModal,
    hideFiltersModal,
} from '../../../actions/Modal';


class Marketplace extends React.Component {
    constructor() {
        super();
        this.isRouteActive = this.isRouteActive.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
    }

    componentDidMount() {
        this.props.hideModal();
        this.props.hideFiltersModal();
    }

    isRouteActive(type) {
        const isAll = this.props.location.pathname === `${this.props.match.url}`
            || this.props.location.pathname === `${this.props.match.url}/all`;
        const isItems = this.props.location.pathname === `${this.props.match.url}/offers`;
        const isInAction = this.props.location.pathname === `${this.props.match.url}/auctions`;
        const isHistory = this.props.location.pathname === `${this.props.match.url}/history`;
        if (type === 'offers' && isItems) {
            return 'active';
        }
        if (type === 'auctions' && isInAction) {
            return 'active';
        }
        if (type === 'all' && isAll) {
            return 'active';
        }
        if (type === 'history' && isHistory) {
            return 'active';
        }
        return '';
    }

    onTabChange(event) {
        history.push(`/marketplace/${event.target.getAttribute('name')}`);
    }

    render() {
        const { match } = this.props;
        return (
            <div className="app-wrapper marketplace">
                {/* <h1 className="page_header">Marketplace</h1> */}
                <section className="topSubNav">
                    <div className="topSubNav_links">
                        <Link to="/marketplace">
                            <span
                                className={this.isRouteActive('all')}
                                name="all"
                                onClick={this.onTabChange}
                            >
                                All
                            </span>
                        </Link>
                        <Link to="/marketplace/offers">
                            <span
                                className={this.isRouteActive('offers')}
                                name="offers"
                                onClick={this.onTabChange}
                            >
                                Offers
                            </span>
                        </Link>
                        <Link to="/marketplace/auctions">
                            <span
                                className={this.isRouteActive('auctions')}
                                name="auctions"
                                onClick={this.onTabChange}
                            >
                                Auctions
                            </span>
                        </Link>
                        <Link to="/marketplace/history">
                            <span
                                className={this.isRouteActive('history')}
                                name="history"
                                onClick={this.onTabChange}
                            >
                                History
                            </span>
                        </Link>
                    </div>
                    <div
                        className="topSubNav_filters"
                        onClick={() => this.props.showFiltersModal()}
                    >
                        <span>Filters</span>
                        <img
                            src={filter_ico}
                            alt="Filter assets"
                        />
                    </div>
                </section>
                <Switch>
                    <Route path={`${match.url}/auctions`} component={Auctions} />
                    <Route path={`${match.url}/offers`} component={Offers} />
                    <Route path={`${match.url}/history`} component={History} />
                    <Route path={`${match.url}/all`} component={All} />
                    <Route exact path={`${match.url}`} component={All} />
                    <Route component={Error404} />
                </Switch>
                <ItemModal isOpen={this.props.isModalOpen} />
                <FilterModal isOpen={this.props.isFilterModalOpen} type="market" />
            </div>
        )
    }
}


const mapStateToProps = ({ auth, wallet, modal, offer }) => {
    const { isModalOpen, isFilterModalOpen } = modal;
    return {
        isModalOpen,
        isFilterModalOpen,
    }
};

export default withRouter(connect(mapStateToProps, {
    hideModal,
    showFiltersModal,
    hideFiltersModal,
})(Marketplace));
