import React from 'react'
import { connect } from 'react-redux'
// import CircularProgress from '@material-ui/core/CircularProgress';
import * as wallet from '../../../util/wallet';
import * as config from '../../../constants/config';
import viewico from '../../../assets/images/item/view@3x.png';
import arrowto from '../../../assets/images/item/arrow_to@3x.png';

const bloks = config.bloks.host;

class NFTAssetLog extends React.Component
{
    constructor() {
        super();

        this.parseDataActors = this.parseDataActors.bind(this);
        this.parseDataMemo = this.parseDataMemo.bind(this);
        this.parseName = this.parseName.bind(this);
    }

    parseName(name) {
        if (name === 'createlog' || name === 'createnttlog') {
            return 'create'.toUpperCase();
        }
        return name.toUpperCase();
    }

    parseDataActors(data) {
        if (data.from && data.to) {
            return (
                <div>
                    <a className="history_account_name" rel="noopener" href={`${bloks}/account/${data.from}`}>
                        {data.from}
                    </a>
                    <img className="history_arrow_icon" src={arrowto} alt="History direction icon"/>
                    <a className="history_account_name" rel="noopener" href={`${bloks}/account/${data.to}`}>
                        {data.to}
                    </a>
                </div>
            )
        }
        if (data.owner && !data.newowner) {
            return (
                <div>
                    <a className="history_account_name" rel="noopener" href={`${bloks}/account/${data.owner}`}>
                        {data.owner}
                    </a>
                </div>
            )
        }
        if (data.owner && data.newowner) {
            return (
                <div>
                    <a className="history_account_name" rel="noopener" href={`${bloks}/account/${data.owner}`}>
                        {data.owner}
                    </a>
                    <img className="history_arrow_icon" alt="History direction icon" src={arrowto}/>
                    <a className="history_account_name" rel="noopener" href={`${bloks}/account/${data.newowner}`}>
                        {data.newowner}
                    </a>
                    {/* {data.owner} -> {data.newowner} */}
                </div>
            )
        }
        if (data.claimer) {
            return (
                <div>
                    <a className="history_account_name" rel="noopener" href={`${bloks}/account/${data.claimer}`}>
                        {data.claimer}
                    </a>
                </div>
            )
        }
    }

    parseDataMemo(data) {
        // console.log(data);
        let obj
        if (data.memo) {
            try {
                obj = JSON.parse(data.memo);
                // console.log(obj);
            } catch (e) {
                return <div className="flex_container">
                    <div className="key">memo:</div>
                    <div className="val">{data.memo}</div>
                </div>;
            }
        } else if (data.mdata) {
            try {
                obj = JSON.parse(data.mdata);
                // console.log(obj);
            } catch (e) {
                // console.log('not and obj');
            }
        }

        if (typeof obj == 'object') {
            let array = Object.keys(obj).map((item, idx) =>{
                return {key: item, value: obj[item]};
            });
            return (
                <ul>
                    {array.map((item, idx) =>
                        <li key={idx}>
                            <div className="key">{item.key}:</div>
                            <div className="val">{item.value}</div>
                        </li>
                    )}
                </ul>
            );
        }
        // return data.memo
        return '';
    }


    render() {
        const { nft_log } = this.props;
        // console.log('wtf', nft_log);
        // console.log('wtf', nft_log, nft_log.length);
        if (!nft_log || !nft_log.length) {
            return <div></div>;
        }

        return (
            <table className="history_table">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Actors</th>
                        <th>Data</th>
                        <th>Date</th>
                        <th>TX</th>
                    </tr>
                </thead>
                <tbody>
                    {nft_log.map((log, idx) => {
                        return (
                            <tr key={idx}>
                                <td>
                                    <div>
                                        {log.name ? this.parseName(log.name): ''}
                                    </div>
                                </td>
                                <td className="history_actors">
                                    {/* <div> */}
                                        {log.data ? this.parseDataActors(log.data) : ''}
                                    {/* </div> */}
                                </td>
                                <td>
                                    <div>
                                        {/* {log.data ? log.data.memo : ''} */}
                                        {log.data ? this.parseDataMemo(log.data) : ''}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {log.block_time}
                                    </div>
                                </td>
                                <td>
                                    {/* <div> */}
                                        <a href={`${bloks}/transaction/${log.trx_id}`} rel="noopener" target="blank">
                                            <img className="history_tx_icon" alt="History Transaction reference on bloks.io" src={viewico}/>
                                        </a>
                                    {/* </div> */}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
}


const mapStateToProps = ({ item }) => {
    const { nft_log } = item;
    return {
        nft_log,
    }
};

export default connect(mapStateToProps, {
    // showModal,
    // hideModal,
    // cancelMarketOffer,
    // buyItem,
    // buyAuction,
    // bidAuction,
    // closeAuction,
    // cancelMarketAuction,
})(NFTAssetLog);
