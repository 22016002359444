import { all, call, select, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    LOAD_ASSETS,
    SELL_ITEM,
    TRANSFER_ITEM,
    CANCEL_ITEM,
    BUY_ITEM,
    LOAD_USER_OFFERS,
    SET_AUCTION,
    LOAD_USER_TRANSFERS,
    CLAIM_TRANSFER,
    CLAIM_TRANSFERS,
    LOAD_USER_AUCTIONS,
    LOAD_USER_TRANSACTIONS,
    LOAD_USER_INACTION,
} from 'constants/ActionTypes';


import {
    loadUserTransfers,
    loadUserTransfersSuccess,
    loadUserAssets,
    loadUserAssetsSuccess,
    loadUserOffersSuccess,
    loadUserAssetsMore,
    loadUserOffersMore,
    loadUserTransfersMore,
    loadUserAuctionsSuccess,
    loadUserAuctionsMore,
    loadUserTransactionsSuccess,
    loadUserTransactionsMore,
    loadUserInActionSuccess,
    loadUserInActionMore,
} from '../actions/Inventory.js';

import accessContext from '../util/accessContext';
import { toast } from 'react-toastify';

import { authorize } from './Auth';
import * as wallet from '../util/wallet';
import * as config from '../constants/config';
import * as api from '../util/api';

// ==================
// ==================
// ================== GETTERS
// ==================
// ==================


// function* getUserBlockAssets ({payload}) {
//     try {
//         const auth = yield call(authorize, {});
//         if (!auth) {
//             return;
//         }
//         let assets;
//         const filter = yield select(state => state.inventory.filters_items);
// // console.log('block assets filters', filter);
//         if (payload && payload.more) {
//             const stateAssets = yield select(state => state.inventory.assets);
// // console.log('state assets', stateAssets, stateAssets.length, stateAssets[stateAssets.length - 1]);
//             let lastId;
//             if (stateAssets.length) {
//                 let last = stateAssets[stateAssets.length - 1];
//                 lastId = last.asset ? last.asset.id : last.id;
//             }
//             // const lastId = stateAssets.length ? stateAssets[stateAssets.length - 1].asset.id : null;
//             assets = yield call(wallet.getUserAssets, {name: auth.user.account_name, lastId: lastId, author: filter.author ? filter.author : null});
//             assets = stateAssets.concat(assets);
//         } else {
//             assets = yield call(wallet.getUserAssets, {name: auth.user.account_name, author: filter.author ? filter.author : null});
//         }
//         // assets = assets.rows;
//         if (assets.rows && assets.rows.length > 0) {
//             yield put(loadUserAssetsSuccess(assets.rows));
//             yield put(loadUserAssetsMore(assets.more));
//         } else {
//             yield put(loadUserAssetsMore(false));
//         }
//     } catch (e) {
//         if (config.debug){
//             console.error('get user assets', e);
//         }
//         yield call(toast.error, e.message)
//     }
// }

function* getUserAssets ({payload}) {
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            return;
        }
        let assets, more;
        const filter = yield select(state => state.inventory.filters_items);
        if (payload && payload.more) {
            const stateAssets = yield select(state => state.inventory.assets);
            const filters = {
                ...filter,
                skip: stateAssets && stateAssets.length ? stateAssets.length : 0,
                // limit: 20,
            };
            assets = yield call(api.getInventoryTable, auth.user.account_name, filters);
            more = assets && assets.length === filter.limit;
            if (assets && assets.length > 0) {
                assets = stateAssets.concat(assets);
            }
        } else {
            const filters = {
                ...filter,
                skip: 0,
                // limit: 20,
            };
            assets = yield call(api.getInventoryTable, auth.user.account_name, filters);
            more = assets && assets.length === filter.limit;
        }

        if (assets && assets.length > 0) {
            yield put(loadUserAssetsSuccess(assets));
            yield put(loadUserAssetsMore(more));
        } else {
            yield put(loadUserAssetsMore(false));
        }
    } catch (e) {
        if (config.debug){
            console.error('get user assets', e);
        }
        yield call(toast.error, e.message)
    }
}

function* getUserOffers ({payload}) {
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            return;
        }
        let offers;
        if (payload && payload.more) {
            const stateOffers = yield select(state => state.inventory.offers);
            const lastId = stateOffers.length ? stateOffers[stateOffers.length - 1].asset.id : null;
            offers = yield call(wallet.getUserOffers, auth, {lastId: lastId});
            offers.rows = stateOffers.concat(offers.rows);
        } else {
            offers = yield call(wallet.getUserOffers, auth);
        }
        // offers = Array.isArray(offers) ? offers : offers.rows;
        if (offers.rows && offers.rows.length > 0) {
            yield put(loadUserOffersSuccess(offers.rows));
            yield put(loadUserOffersMore(offers.more));
        } else {
            yield put(loadUserOffersMore(false));
        }
    } catch (e) {
        if (config.debug){
            console.error('get user offers', e);
        }
        yield call(toast.error, e.message);
    }
}

function* getUserAuctions ({payload}) {
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            return;
        }
        let auctions, more;
        if (payload && payload.more) {
            const stateAuctions = yield select(state => state.inventory.auctions);
            const lastId = stateAuctions.length ? stateAuctions[stateAuctions.length - 1].asset.id : null;
            auctions = yield call(wallet.getUserAuctions, auth, {lastId: lastId});
            // more = auction && auctions.length ===
            auctions = stateAuctions.concat(auctions);
        } else {
            auctions = yield call(wallet.getUserAuctions, auth);
        }
        // console.log('GET USER AUCTIONS', auctions);
        // offers = Array.isArray(offers) ? offers : offers.rows;
        if (auctions.rows && auctions.rows.length > 0) {
            yield put(loadUserAuctionsSuccess(auctions.rows));
            yield put(loadUserAuctionsMore(auctions.more));
        } else {
            yield put(loadUserAuctionsMore(false));
        }
    } catch (e) {
        if (config.debug) {
            console.error('get user auctions', e);
        }
        yield call(toast.error, e.message);
    }
}

function* getUserInAction ({payload}) {
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            return;
        }
        let in_action, more;
        const filter = yield select(state => state.inventory.filters_items);
        if (payload && payload.more) {
            const stateInAction = yield select(state => state.inventory.in_action);
            // const lastId = stateAuctions.length ? stateAuctions[stateAuctions.length - 1].asset.id : null;
            const filters = {
                ...filter,
                skip: stateInAction && stateInAction.length ? stateInAction.length : 0,
                owner: auth.user.account_name,
            };
            in_action = yield call(api.getMarketTable, filters);
            more = in_action && in_action.length === filter.limit;
            if (in_action && in_action.length > 0) {
                in_action = stateInAction.concat(in_action);
            }
        } else {
            const filters = {
                ...filter,
                skip: 0,
                owner: auth.user.account_name,
            }
            in_action = yield call(api.getMarketTable, filters);
            more = in_action && in_action.length === filter.limit;
        }

        if (in_action && in_action.length) {
            yield put(loadUserInActionSuccess(in_action));
            yield put(loadUserInActionMore(more));
        } else {
            yield put(loadUserInActionMore(false));
        }
    } catch (e) {
        if (config.debug) {
            console.error('get user auctions', e);
        }
        yield call(toast.error, e.message);
    }
}

function* loadClaimTransfers({payload}) {
    try {
        const auth = yield call(authorize, payload && payload.user ? payload : {});
        if (!auth) {
            return;
        }
        const transfers = yield call(wallet.getTransfersTable, auth, {to: auth.user.account_name});
        if (transfers.rows && transfers.rows.length) {
            yield put(loadUserTransfersSuccess(transfers.rows));
            yield put(loadUserTransfersMore(transfers.more));
        } else {
            yield put(loadUserTransfersSuccess([]));
            yield put(loadUserTransfersMore(false));
        }
    } catch(e) {
        if (config.debug){
            console.error('get user transfers', e);
        }
        yield call(toast.error, e.message);
    }
}

function* loadTransactions({payload})
{
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            return;
        }
        let transactions, filters, more;
        if (payload && payload.more) {
            const stateTxs = yield select(state => state.inventory.transactions);
            filters = {
                skip: stateTxs && stateTxs.length ? stateTxs.length : 0,
            };

            transactions = yield call(api.getTransactions, auth.user.account_name, filters);
            more = transactions && transactions.length ? transactions.length === (filters.limit || 20 + filters.skip) : false
            if (transactions && transactions.length > 0) {
                transactions = stateTxs.concat(transactions);
            }
        } else {
            filters = {
                skip: 0,
            };
            transactions = yield call(api.getTransactions, auth.user.account_name, filters);
            more = transactions && transactions.length ? transactions.length === (filters.limit || 20 + filters.skip) : false
        }

        if (transactions && transactions.length) {
            yield put(loadUserTransactionsSuccess(transactions));
            yield put(loadUserTransactionsMore(more)); // todo: make it variable
        } else {
            yield put(loadUserTransactionsMore(false)); // todo: make it variable
        }
    } catch(e) {
        if (config.debug){
            console.error('get user transfers', e);
        }
        yield call(toast.error, e.message);
    }
}

// ==================
// ==================
// ================== ACTIONS
// ==================
// ==================

function* transferItem({ payload }) {
    const { to, memo, asset } = payload;
    try {
        const auth = yield call(authorize, {});
        if (!auth) {
            return;
        }
        const data = {
            assetids: [asset.id],
            to,
            memo,
        }
        const result = yield call(wallet.assetTransfer, auth, data);
        yield call(toast.success, 'Item transfer success!');
        yield put(loadUserAssets());
    } catch (e) {
        if (config.debug){
            console.error('transfer item', e);
        }
        yield call(toast.error, e.message);
    }
}

function* claimTransfer({payload}) {
    try {
        const { asset, transfer } = payload;
        const auth = yield call(authorize, {});
        if (!auth) {
            return;
        }
        yield call(wallet.claimTransfer, auth, { asset });
        yield call(toast.success, 'Item claimed successfully!');
        yield put(loadUserAssets());
        yield put(loadUserTransfers());
    } catch(e) {
        if (config.debug){
            console.error('claim item', e);
        }
        yield call(toast.error, e.message);
    }
}

function* claimTransfers({payload}) {
    try {
        const assetids = payload;
        console.log('assetids', assetids, payload);
        const auth = yield call(authorize, {});
        if (!auth) {
            return;
        }
        yield call(wallet.claimTransfers, auth, { assetids });
        yield call(toast.success, 'Item claimed successfully!');
        yield put(loadUserAssets());
        yield put(loadUserTransfers());
    } catch(e) {
        if (config.debug){
            console.error('claim items', e);
        }
        yield call(toast.error, e.message);
    }
}

// ==================
// ==================
// ================== SAGAS
// ==================
// ==================

export function* loadUserTransactionsSaga() {
    yield takeLatest(LOAD_USER_TRANSACTIONS, loadTransactions);
}

export function* loadUserTransfersSaga() {
    yield takeLatest(LOAD_USER_TRANSFERS, loadClaimTransfers);
}

export function* claimTransferSaga() {
    yield takeLatest(CLAIM_TRANSFER, claimTransfer);
}

export function* claimTransfersSaga() {
    yield takeLatest(CLAIM_TRANSFERS, claimTransfers);
}

export function* transferItemSaga() {
    yield takeLatest(TRANSFER_ITEM, transferItem);
}

export function* loadUserAssetsSaga() {
    // yield takeLatest(LOAD_ASSETS, getUserBlockAssets);
    yield takeLatest(LOAD_ASSETS, getUserAssets);
}

export function* loadUserOffersSaga() {
    yield takeLatest(LOAD_USER_OFFERS, getUserOffers);
}

export function* loadUserInActionSaga() {
    yield takeLatest(LOAD_USER_INACTION, getUserInAction);
}

export function* loadUserAuctionsSaga() {
    yield takeLatest(LOAD_USER_AUCTIONS, getUserAuctions);
}

export default function* rootSaga() {
    yield all([
        fork(loadUserInActionSaga),
        fork(loadUserTransactionsSaga),
        fork(loadUserTransfersSaga),
        fork(claimTransferSaga),
        fork(claimTransfersSaga),
        fork(transferItemSaga),
        fork(loadUserAssetsSaga),
        fork(loadUserOffersSaga),
        fork(loadUserAuctionsSaga),
    ]);
}