import {
    LOAD_USER_TRANSFERS_SUCCESS,
    LOAD_ASSETS_SUCCESS,
    LOAD_USER_OFFERS_SUCCESS,
    LOAD_USER_ASSETS_MORE,
    LOAD_USER_OFFERS_MORE,
    LOAD_USER_TRANSFERS_MORE,
    LOAD_USER_AUCTIONS_MORE,
    LOAD_USER_AUCTIONS_SUCCESS,
    LOAD_USER_TRANSACTIONS_SUCCESS,
    LOAD_USER_TRANSACTIONS_MORE,
    LOAD_USER_INACTION_SUCCESS,
    LOAD_USER_INACTION_MORE,
    REMOVE_MARKET_ITEM,
    REMOVE_INVENTORY_ITEM,
    // SET_INVENTORY_ITEM_FILTERS,
    SET_INVENTORY_ITEM_FILTERS_DATA,
    SET_INVENTORY_ITEM_FILTERS_SUCCESS,
    // SET_INVENTORY_ACTION_FILTERS,
    SET_INVENTORY_ACTION_FILTERS_DATA,
    SET_INVENTORY_ACTION_FILTERS_SUCCESS,
    RESET_FILTERS_SUCCESS,
    SELECT_INVENTORY_ITEM,
    SET_ITEMS_PRICE,
    RESET_SELECTED_INVENTORY_ITEMS,
} from '../constants/ActionTypes.js';


const INIT_STATE = {
    transfers: [],
    transactions: [],
    assets: [],
    offers: [],
    auctions: [],
    in_action: [],
    assets_more: false,
    offers_more: false,
    transfers_more: false,
    auctions_more: false,
    transactions_more: false,
    in_action_more: false,
    items_checked: [],
    filters_action: {
        skip: 0,
        limit: 20,
        // authors: [],
        // categories: [],
    },
    filter_action_data: {
        authors: [],
        categories: [],
    },
    filters_items: {
        skip: 0,
        limit: 20,
    },
    filter_items_data: {
        authors: [],
        categories: [],
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_USER_TRANSFERS_SUCCESS: {
            return {
                ...state,
                transfers: action.payload,
                transfersCount: Array.isArray(action.payload) ? action.payload.length : 0,
            };
        }
        case LOAD_USER_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                transactions: action.payload,
            };
        }
        case LOAD_USER_TRANSACTIONS_MORE: {
            return {
                ...state,
                transactions_more: action.payload,
            };
        }
        case LOAD_USER_INACTION_SUCCESS: {
            return {
                ...state,
                in_action: action.payload,
            };
        }
        case LOAD_USER_INACTION_MORE: {
            return {
                ...state,
                in_action_more: action.payload,
            };
        }
        case LOAD_ASSETS_SUCCESS: {
            return {
                ...state,
                assets: action.payload,
            }
        }
        case LOAD_USER_OFFERS_SUCCESS: {
            return {
                ...state,
                offers: action.payload,
            }
        }
        case LOAD_USER_ASSETS_MORE: {
            return {
                ...state,
                assets_more: action.payload,
            }
        }
        case LOAD_USER_OFFERS_MORE: {
            return {
                ...state,
                offers_more: action.payload,
            }
        }
        case LOAD_USER_TRANSFERS_MORE: {
            return {
                ...state,
                transfers_more: action.payload,
            }
        }
        case LOAD_USER_AUCTIONS_SUCCESS: {
            return {
                ...state,
                auctions: action.payload,
            }
        }
        case LOAD_USER_AUCTIONS_MORE: {
            return {
                ...state,
                auctions_more: action.payload,
            }
        }

        case REMOVE_MARKET_ITEM: {
            return {
                ...state,
                in_action: state.in_action.filter(item => item.assetid != action.payload.assetid),
            }
        }

        case REMOVE_INVENTORY_ITEM: {
            let assets;
            if (Array.isArray(action.payload)) {
                assets = state.assets.filter(item => {
                    return action.payload.indexOf(String(item.assetid)) === -1
                });
            } else {
                assets = state.assets.filter(item => item.assetid != action.payload.assetid);
            }

            return {
                ...state,
                assets: assets,
            }
        }

        case SET_INVENTORY_ACTION_FILTERS_DATA: {
            return {
                ...state,
                filter_action_data: action.payload,
            }
        }
        case SET_INVENTORY_ITEM_FILTERS_DATA: {
            return {
                ...state,
                filter_items_data: action.payload,
            }
        }

        case SET_INVENTORY_ACTION_FILTERS_SUCCESS: {
            return {
                ...state,
                filters_action: action.payload,
            }
        }
        case SET_INVENTORY_ITEM_FILTERS_SUCCESS: {
            return {
                ...state,
                filters_items: action.payload,
            }
        }

        case RESET_FILTERS_SUCCESS: {
            return {
                ...state,
                filters_action: INIT_STATE.filters_action,
                filters_items: INIT_STATE.filters_items,
            }
        }

        case SELECT_INVENTORY_ITEM: {
            const newValue = [...state.items_checked];
            if (action.payload.value && newValue.indexOf(action.payload.id) === -1) {
                newValue.push(action.payload.id);
            } else if(!action.payload.value) {
                newValue.splice(newValue.indexOf(action.payload.id), 1);
            }

            return {
                ...state,
                items_checked: newValue,
            }
        }
        case RESET_SELECTED_INVENTORY_ITEMS: {
            return {
                ...state,
                items_checked: [],
                items_price: null,
            }
        }
        case SET_ITEMS_PRICE: {
            return {
                ...state,
                items_price: action.payload,
            }
        }

        default:
            return state;
    }
}
