import React from 'react';
import { connect } from 'react-redux';
import vgo_ico from '../../assets/images/vgo_large.png';
import virl_ico from '../../assets/images/virl_large.png';
import no_photo_ico from '../../assets/images/no_photos.png';
import topps_logo from '../../assets/images/topps_logo.png';
import immortals_ico from '../../assets/images/immortals.png';
import darkcountry_ico from '../../assets/images/darkcountry.png';

class ItemVisualProps extends React.Component {

  constructor(props) {
    super(props);

  }
  authorIcon(author) {
    switch(author) {
        case 'vgo':
          return <img className="author_icon" src={vgo_ico} alt="VGO"/>;
        case 'immortals':
          return <img className="author_icon" src={immortals_ico} alt="immortals"/>;
        case 'darkcountry1':
        case 'darkcountrya':
          return <img className="author_icon" src={darkcountry_ico} alt="darkcountry"/>;
        case 'virl':
          return <img className="author_icon" src={virl_ico} alt="vIRL"/>;
        case 'gpk.topps':
          return <img className="author_icon" src={topps_logo} alt="Topps GPK"/>;
        default:
          return ;
          // return <img className="author_icon" style={{background: '#fff'}} src={no_photo_ico} alt="No author photo available"/>;
    }
  }
  getVgoMark(name) {
    if (name.indexOf('(Factory New)') !== -1) return 'FN';
    if (name.indexOf('(Minimal Wear)') !== -1) return 'MW';
    if (name.indexOf('(Well-Worn)') !== -1) return 'WW';
    if (name.indexOf('(Field-Tested)') !== -1) return 'FT';
    if (name.indexOf('(Battle-Scarred)') !== -1) return 'BS';
    return;
  }
  getVgoFloatColor(name) {
    if (name.indexOf('(Factory New)') !== -1) return 'green';
    if (name.indexOf('(Minimal Wear)') !== -1) return '#5cb85c';
    if (name.indexOf('(Well-Worn)') !== -1) return '#d9534f';
    if (name.indexOf('(Field-Tested)') !== -1) return '#f0ad4e';
    if (name.indexOf('(Battle-Scarred)') !== -1) return '#993a38';
    return '#fff';
  }

  getFloat(asset) {
    if (asset && asset.unique && asset.unique.wear) {
      return Number(asset.unique.wear);
    }
    return (null);
  }

  render() {
    const { asset } = this.props;


    // if (!asset || !asset.unique) {
    //     return (
    //         <div className="item_props">
    //         </div>
    //     );
    // }
    let left;
    if (asset && asset.unique && asset.unique.wear) {
      left = (Number(asset.unique.wear) * 100) - 1.5
    }
    let vgo_mark = this.getVgoMark(asset.name || asset.mdata.name || asset.idata.name);

    return (
        <div className="item_props">
            {asset && asset.unique && asset.unique.wear &&
                <div className="wear">
                    <div className="pointer" style={{left: `${left}%`}}>
                        |
                    </div>
                    <div className="progress">
                        <div className="bar new" title="Factory New"></div>
                        <div className="bar minimal" title="Minimal Wear"></div>
                        <div className="bar tested"  title="Field-Tested"></div>
                        <div className="bar worn" title="Well-Worn"></div>
                        <div className="bar scared"  title="Battle-Scarred"></div></div>
                </div>
            }
            {/* {asset.unique.serial_sku_wear && asset.unique.serial_sku &&
                <div className="serial">
                    <div className="number">{asset.unique.serial_sku}</div>
                    <div className="unboxed">{asset.unique.serial_sku_wear}</div>
                </div>
            } */}
            { asset.author &&
              <div className={`author ${this.props.isList ? 'author_list' : ''}`}>
                {this.authorIcon(asset.author)}
              </div>
            }
            {vgo_mark &&
              <div className="vgo_mark" style={{color: this.getVgoFloatColor(asset.name || asset.mdata.name || asset.idata.name)}}>
                <div className="float" >
                  {/* {Number(asset.unique.wear).toFixed(5)} */}
                  {this.getFloat(asset)}
                </div>
                <div>
                  {vgo_mark}
                </div>
              </div>
            }
        </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
//   const { authUser } = auth;
  return {
    // authUser,
  }
};

export default connect(mapStateToProps, {
//   showModal,
//   hideModal,
})(ItemVisualProps);


