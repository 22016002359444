import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
    sendFeedback,
} from '../../../actions';

const initState = {
    name: null,
    email: null,
    subject: null,
    message: null,
}

class Contact extends React.Component
{
    constructor(props) {
        super(props);

        this.state = initState;

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = (event) => {
        let field = event.target.name;
        let value = event.target.value;

        this.setState({[field]: value});
    }

    onSubmit = (event) => {
        this.props.sendFeedback(this.state);
    }

    render() {
        return (
            <div className="app-wrapper contact">
                <Helmet>
                    <title>Contact Us</title>
                    <meta name="description" content="Got a question or feedback? We would love to hear from you! Send us a message and we will respond as soon as possible."/>
                </Helmet>
                <div className="container">
                    <h1>Contact Us</h1>
                    <div className="contact_form">
                        <h3>Got a question or feedback? We would love to hear from you! Send us a message and we will respond as soon as possible.</h3>
                        <div className="form_input">
                            <input
                                placeholder="Your name"
                                name="name"
                                type="text"
                                onChange={this.onChange}
                                autoComplete="off"
                            />
                            <div className="author_input_label">
                                <span>Name</span>
                            </div>
                        </div>
                        <div className="form_input">
                            <input
                                placeholder="Your email address"
                                name="email"
                                type="email"
                                onChange={this.onChange}
                                autoComplete="off"
                            />
                            <div className="author_input_label">
                                <span>Email</span>
                            </div>
                        </div>
                        <div className="form_input">
                            <input
                                placeholder="Subject"
                                name="subject"
                                type="text"
                                onChange={this.onChange}
                                autoComplete="off"
                            />
                            <div className="author_input_label">
                                <span>Subject</span>
                            </div>
                        </div>

                        <div className="form_input">
                            <textarea
                                placeholder="Your message"
                                name="message"
                                onChange={this.onChange}
                                autoComplete="off"
                            />
                        </div>
                        <button
                            className="btn btn_standalone_primary"
                            onClick={this.onSubmit}
                        >
                            SEND
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({ market }) => {
    return {
    }
};

export default connect(mapStateToProps, {
    sendFeedback
})(Contact);
