
import {
    SEND_FEEDBACK,
} from 'constants/ActionTypes';

export const sendFeedback = (data) => {
    return {
        type: SEND_FEEDBACK,
        payload: data,
    }
}