import {all, call, select, fork, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {
    loadNftMarketSuccess,
    loadNftAssetSuccess,
    itemPageLoading,
    loadNftAssetLogSuccess,
} from '../actions/Item';
import {
    LOAD_NFT_ASSET,
    LOAD_NFT_ASSET_SUCCESS,
} from 'constants/ActionTypes';


import * as wallet from '../util/wallet';
import * as api from '../util/api';
import { toast } from 'react-toastify';
import { authorize } from './Auth';



// =======================
// =======================
// ======================= LOAD
// =======================
// =======================


function* getNftAsset({payload}) {
    try {
        yield put(itemPageLoading());
        yield put(loadNftAssetLogSuccess([]));
        const asset = yield call(api.getAsset, payload); // payload is asset id
        yield put(loadNftAssetSuccess(asset));
        if (asset.market) {
            yield put(loadNftMarketSuccess(asset.market));
        }
        const assetLog = yield call(api.getAssetLogs, payload);
        yield put(loadNftAssetLogSuccess(assetLog));
    } catch (e) {
        // console.error('Item fetch error', e);
        yield call(toast.error, 'Item not found');
    }
}


export function* loadNftAssetSaga() {
    yield takeLatest(LOAD_NFT_ASSET, getNftAsset);
}



// =======================
// =======================
// ======================= ACTION
// =======================
// =======================



export default function* rootSaga() {
    yield all([
        fork(loadNftAssetSaga),
    ]);
}