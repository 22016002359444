import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';
import ItemModal from '../../../components/ItemModal';
import NFTAssetLog from './nft_log';
import OwnerButtons from './owner_buttons';
import MarketButtons from './market_buttons';
import video_ico from '../../../assets/images/item/video_large.png';
import td_large_ico from '../../../assets/images/item/td_large.png';
import ModalImage from 'react-modal-image';
import ReactPlayer from 'react-player';
import ScrollUpButton from 'react-scroll-up-button';

import {
    loadNftAsset,
} from '../../../actions/Item';
import {
    hideModal,
} from '../../../actions/Modal';
import * as moment from 'moment';
import * as config from '../../../constants/config';
import ItemVisualProps from 'components/ItemVisualProps';

const bloks = config.bloks.host;

class NftItemPage extends React.Component {
    constructor() {
        super();

        this.isOwner = this.isOwner.bind(this);
        this.isMarket = this.isMarket.bind(this);
        this.getMediaContainer = this.getMediaContainer.bind(this);
        this.categoryName = this.categoryName.bind(this);
    }

    componentDidMount() {
        this.props.hideModal();
        const itemId = this.props.match.params.id;
        if (!itemId) {
            throw new Error('MISSING ITEM ID - Should not happen');
        }
        this.props.loadNftAsset(itemId);
    }

    isOwner() {
        return !this.props.loading && this.props.user && this.props.nft.owner == this.props.user.account_name;
    }

    isMarket() {
        // return !this.props.loadingMarket && this.props.nft && this.props.nft.id;
        return !this.isOwner();
    }

    getMediaContainer(asset) {
        if (!asset || !asset.unique || !asset.unique.wax_preview_urls) {
            return;
        }
        let urls = asset.unique.wax_preview_urls;

        return (
            <div className="media_container">
                <div className="item_section_header">
                    <h3 className="">WAX Media:</h3>
                </div>
                <div className="media_data">
                    {urls.video &&
                        <ReactPlayer
                            url={urls.video}
                            width={315}
                            height={170}
                            light={true}
                            playing={true}
                            loop={true}
                        />
                    }
                    {urls.front_image &&
                        <ModalImage
                            small={urls.front_image}
                            large={urls.front_image}
                            alt={asset.mdata.name || asset.idata.name}
                            className="media_image"
                        />
                    }
                    {urls.back_image &&
                        <ModalImage
                            small={urls.back_image}
                            large={urls.back_image}
                            alt={asset.mdata.name || asset.idata.name}
                            className="media_image"
                        />
                    }
                </div>
            </div>
        );
    }
    categoryName(id) {
        switch(id) {
            case '............i':
                return 'WAX Digital Art';
            case '............1':
                return 'VGO';
            case '............g':
                return 'WAX Stickers';
            case '...........2':
                return 'Collectible Cards';
            default:
                return id;
        }
    }

    render() {
        const asset = this.props.nft;
        let image;
        if (!asset.idata) {
            asset.idata = {};
        }
        if (asset.mdata){
            asset.mdata = asset.mdata && typeof asset.mdata != 'object' ? JSON.parse(asset.mdata) : asset.mdata
            asset.idata = asset.idata && typeof asset.idata != 'object' ? JSON.parse(asset.idata) : asset.idata

            image = asset.idata.img || asset.mdata.img;
            if (image.length === 46 && image.substr(0,1) === 'Q') {
                image = 'https://ipfs.io/ipfs/' + image;
            }else if (image && -1 === image.indexOf('http') && image.substr(0,1) === 'Q') {
                image = 'https://ipfs.io/ipfs/' + image;
            }
        }

        return (
            <div className="app-wrapper item_page">
                <Helmet>
                    <title>WAX NFT Item Page</title>
                    <meta name="description" content="View NFT details and history on WAX inventory - WAX Marketplace"/>
                </Helmet>
                {
                    !asset.assetid &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {
                    asset.assetid &&
                    <div className="item_page_container">
                        <div className="item_header">
                            <div className="item_background">
                                <div className="item_image" style={{backgroundImage: `url(${image})`}} />
                                <ItemVisualProps asset={asset} />
                            </div>
                            <div className="item_desc">
                                <h2>
                                    {asset.mdata.name || asset.idata.name}
                                </h2>
                                <div className="item_info">
                                    <div className="item_info_main">
                                        <div className="item_info_prop">
                                            <label>ID:</label>
                                            <span>{asset.assetid}</span>
                                        </div>
                                        <div className="item_info_prop">
                                            <label>Category:</label>
                                            <span>{this.categoryName(asset.category)}</span>
                                        </div>
                                        <div className="item_info_prop">
                                            <label>Author:</label>
                                            <span>
                                                <a href={`${bloks}/account/${asset.author}`} rel="noopener" target="_blank">
                                                    {asset.author}
                                                </a>
                                            </span>
                                        </div>
                                        <div className="item_info_prop">
                                            <label>Owner:</label>
                                            <span>
                                                <a href={`${bloks}/account/${asset.market ? asset.market.owner : asset.owner}`} rel="noopener" target="_blank">
                                                    {asset.market ? asset.market.owner : asset.owner}
                                                </a>
                                            </span>
                                            {/* <span>{asset.market ? asset.market.owner : asset.owner}</span> */}
                                        </div>
                                        { this.props.offer && this.props.offer.bidder &&
                                            <div className="item_info_prop">
                                                <label>Highest bidder:</label>
                                                <span>
                                                    <a href={`${bloks}/account/${this.props.offer.bidder}`} rel="noopener" target="_blank">
                                                        {this.props.offer.bidder}
                                                    </a>
                                                </span>
                                            </div>
                                        }
                                        { this.props.offer && this.props.offer.expires_at &&
                                            <div className="item_info_prop">
                                                <label>Auction end:</label>
                                                <span>
                                                    {moment(this.props.offer.expires_at).utc().format('DD-MM-YYYY h:mm a')}
                                                </span>
                                            </div>
                                        }
                                    </div>
                                    {
                                        !this.isOwner() && !this.isMarket() &&
                                        <div className="loader-view">
                                            Out of Stock
                                            {/* <CircularProgress/> */}
                                        </div>
                                    }
                                    {
                                        this.isOwner() &&
                                        <OwnerButtons asset={asset} />
                                    }
                                    {
                                        this.isMarket() &&
                                        <MarketButtons asset={asset} offer={this.props.offer} />
                                    }
                                </div>
                            </div>
                        </div>

                        {this.getMediaContainer(asset)}

                        { Object.keys(asset.idata).length > 0 &&
                            <div className="item_section_header">
                                <h3 className="">Immutable asset data:</h3>
                            </div>
                        }
                        { Object.keys(asset.idata).length > 0 &&
                            <div className="item_data">
                                {/* {console.log('ASSET KURWO', asset, asset.idata)} */}
                                {Object.entries(asset.idata).map(([key, val], idx) => {
                                    return (
                                        <div key={idx} className="item_data_row">
                                            <div className="item_data_key">{key}:</div>
                                            <div className="item_data_val">{val}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        { Object.keys(asset.mdata).length > 0 &&
                            <div className="item_section_header">
                                <h3 className="">Mutable asset data:</h3>
                            </div>
                        }
                        { Object.keys(asset.mdata).length > 0 &&
                            <div className="item_data">
                                {Object.entries(asset.mdata).map(([key, val], idx) => {
                                    return (
                                        <div key={idx} className="item_data_row">
                                            <div className="item_data_key">{key}:</div>
                                            <div className="item_data_val">{val}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        { this.props.nft_log && this.props.nft_log.length > 0 &&
                            <div className="item_section_header">
                                <h3 className="">Asset history:</h3>
                            </div>
                        }
                        <NFTAssetLog />
                    </div>
                }
                <ScrollUpButton />
                <ItemModal isOpen={this.props.isModalOpen} />
            </div>
        )
    }
}


const mapStateToProps = ({ auth, modal, item }) => {
    const { isModalOpen } = modal;
    const { nft, nft_log, loading, offer, loadingMarket } = item;
    const { user } = auth;
    return {
        isModalOpen,
        nft,
        nft_log,
        user,
        offer,
    }
};

export default connect(mapStateToProps, {
    hideModal,
    loadNftAsset,
})(NftItemPage);