import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';

import * as wallet from '../../../util/wallet';
import {
    showModal,
    hideModal,
} from '../../../actions/Modal';


class OwnerItemButtons extends React.Component {

    handleOpenModal() {
        this.props.showModal();
    }

    handleCloseModal() {
        this.props.hideModal();
    }

    getActions(item) {
        const asset = this.props.asset;
        const offer = this.props.offer;

        // USER ASSET
        if (this.props.user && asset.owner == this.props.user.account_name) {
            return (
                <div className="item_info_action">
                    <div className="item_info_buttons height100">
                        <button
                            className="btn btn_secondary btn_even btn_left_noradius"
                            onClick={() => this.props.showModal({action: 'transfer', item: {asset: asset, offer: offer}})}
                        >
                            TRANSFER
                        </button>
                        <button
                            className="btn btn_primary btn_even"
                            onClick={() => this.props.showModal({action: 'sell', item: {asset: asset, offer: offer}})}
                        >
                            SELL
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className="loader-view">
                <CircularProgress/>
            </div>
        )
    }

    render() {
        const { asset } = this.props
        const item = {asset, offer: asset};

        return this.getActions(item);
    }
}


const mapStateToProps = ({ auth, vault }) => {
    const { user } = auth;
    return {
        user,
    }
};

export default connect(mapStateToProps, {
    // transferToInventory,
    showModal,
    hideModal,
    // setItem,
    // setAction,
    // cancelItemOffer,
    // buyItem,
    // buyAuction,
    // bidAuction,
})(OwnerItemButtons);


